import ProductionBottomInfo from "../ProductionBottomInfo/ProductionBottomInfo";
import { IonButton, useIonAlert } from "@ionic/react";
import styles from "./ProductionOperationConfirmation.module.scss";
import ProductionService from "../../../../barrel/services/productionService";
import ActionManagerService from "../../../../barrel/services/actionManagerService";
import { withRouter } from "react-router";
import {
  dispatchProductionOrderSelectionStore,
  useProductionOrderSelectionStore,
} from "../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import productionController from "../../../../barrel/controllers/productionController";
import useLocalStorageHandler from "../../../../barrel/hooks/useLocalStorageHandler";
import { useIonToast } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useProductionUIStore } from "../../../FeaturePages/ProductionOrders/Standard/store/productionConfirmationUIStore";
import { stopCircleOutline } from "ionicons/icons";
import { caretForwardCircleOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { dispatchProductionUIStore } from "../../../FeaturePages/ProductionOrders/Standard/store/productionConfirmationUIStore";

import ActionScreensManager from "./ActionScreensManager/ActionScreensManager";
import OperationActionService from "../../../../barrel/services/operationActionService";
import { useCancelToken } from "../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import { useGlobalState } from "../../../../GlobalCustomStateManagement/GlobalStateProvider";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import useHookProductionOperationHandler from "./useHookProductionOperationHandler";
import { dispatchProductionOperationStore, productionOperationSessionStats, useProductionOperationStore } from "./ProductionOperationStore";
import PopupRemoveCancellation from "./Popups/PopupRemoveCancellation/PopupRemoveCancellation";
import usePrevious from "../../../../barrel/hooks/usePrevious";
import HxfOverlayLoading from "../../HxfOverlayLoading/HxfOverlayLoading";
function ProductionOperationConfirmation(props) {
  const { cancelToken, isCancel } = useCancelToken();
  const localStorage = useLocalStorageHandler();
  const productionService = ProductionService();
  const actionManagerService = ActionManagerService();
  const currentProductionOrderSelectionData = useProductionOrderSelectionStore();
  const [presentToast] = useIonToast();
  const [buttonNextEnabled, setButtonNextEnabled] = useState(true);
  const [pauseEnabled, setPauseEnabled] = useState(true);
  const timerExecuteConfirm = useRef(setTimeout(() => {}, 0));
  const [didMount, setDidMount] = useState(false);
  const operationActionService = OperationActionService();
  const feedbackService = useFeedbackService();
  const { appState } = useGlobalState();
  const {t} = useHxfTranslation();
  const operationSessionStats = productionOperationSessionStats();
  const [present] = useIonAlert();
  const [showPopupRemoveCancel, setShowPopupRemoveCancel] = useState(null);
  const productionOperationStore = useProductionOperationStore();

  const prevTriggerRevokeCancelEventState = usePrevious(productionOperationStore?.triggeredShowRevokeCancellation);
  
  const successEndOperationCallback = (res) => {
    //if comming from the multi production menu or from a route that comes from the multi production menu
    if (props?.history?.location?.state?.multiProductionsRouting) {
      let productionsRoutesArray =
        props.history.location.state.multiProductionsRouting.routesArray;
      let currentRouteIndex =
        props.history.location.state.multiProductionsRouting.currentIndex;
      let newRouteIndex = currentRouteIndex + 1;

      let passingStateObj = props.history.location.state;
      passingStateObj.multiProductionsRouting.currentIndex = newRouteIndex;
      if (newRouteIndex > productionsRoutesArray.length - 1) {
        //reached end
        console.log("Finished Multi Production, going back to home");
        presentToast({
          color: "success",
          message: t('multi.production.finished.success'),
          duration: 2000,
        });
        props.history.push("/home");
      } else {
        props.history.push({
          pathname: "/page-redirector",
          state: {
            redirectTo: productionsRoutesArray[newRouteIndex],
            withHistoryState: passingStateObj,
          },
        });
      }
    } else {
      if (currentProductionOrderSelectionData.isCurrentOperationTheLast) {
        console.log("Finished Production, going back to home");

        
        if (res?.redirect) {
          //from hookProductionOperationHandler
          feedbackService.notifyToast(t('operation.finished'), "success");
          props.history.push(res.redirect);
        }else{
          feedbackService.notifyToast(t('production.finished'), "success");
          props.history.push("/feature/production-orders");
        }
       
      } else {
    
        console.log("RESULTADO create operation, next: ", res);
        
        if (res?.redirect) {
          //from hookProductionOperationHandler

          feedbackService.notifyToast(t('operation.finished'), "success");
          props.history.push(res.redirect);
        } else {
          let responseObject = res?.data?.response?.data;
          if(!responseObject){
            throw "unexpected response object";
          }
          let newProductionId = responseObject.Id;
          let productionOrderProductId =
            currentProductionOrderSelectionData.productionRecord
              .Id_ProductionOrder_Product;

          feedbackService.notifyToast(t('operation.finished'), "success");
          enableConfirmationButtons();
          props.history.push(
            "/feature/production-order/" +
              currentProductionOrderSelectionData.productionOrder.Id +
              "/po-product/" +
              productionOrderProductId +
              "/production/" +
              newProductionId
          );
        }
      }
    }
  };

  const getRightConfirmaitonText = () => {

    if(!isLastOperationCheck()){
      //todo check if option replace next with done
   
      if(currentProductionOrderSelectionData?.currentOperation?.shopfloorSetting_operationConfirmation === "END_CURRENT_START_NEW"){
        return (
            <div className={styles.plusOneDone}>{t("plus.one.done")}</div>
        )
      }

      if(currentProductionOrderSelectionData?.currentOperation?.shopfloorSetting_operationConfirmation === "DONE_END_CURRENT"){
        return (
          <>{t("done")}</>
        )
      }
   
      return (
        <>{t("next")}</>
      )
    }

    return (
      <>{t("finish")}</>
    )

  }

  const failedEndOperationCallback = (res) => {
    console.log("Failed create production", res);

    let errorHandled = false;

    let errorsMap = res?.response?.data?.response?.issues?.errorsMap;
    let errorsArr = res?.response?.data?.response?.issues?.errors;
  
    if(errorsMap){
      if (errorsMap["error.attemptUseBusyMachine"]) {
        presentToast({
          color: "warning",
          message: t('machine.you.try.use.already.used'),
          duration: 10000,
        });
  
        let currentUrl = props.history.location.pathname;
        props.history.push({
          pathname: "/page-redirector",
          state: { redirectTo: currentUrl },
        });
        return;
        errorHandled = true;
      }

    }


    if (errorsArr && errorsArr.includes("error.productionRecord.isCanceled")) {
    
      setShowPopupRemoveCancel(true);
      //todo popup ask if would like to remove cancellation or exit
      /*
      let currentUrl = props.history.location.pathname;
      props.history.push({
        pathname: "/page-redirector",
        state: { redirectTo: currentUrl },
      });*/
      return;
      errorHandled = true;
    }

    feedbackService.notifyToast(t('generic.critical.error'), "error");
    let currentUrl = props.history.location.pathname;
    props.history.push({
      pathname: "/page-redirector",
      state: {
        redirectTo: currentUrl
      }
    });
    /*if (!errorHandled) {
      if (currentProductionOrderSelectionData.isCurrentOperationTheLast) {
        //default fallback
        feedbackService.notifyToast("An error occurred. Contact us if the error persists.","error");
        let currentUrl = props.history.location.pathname;
        props.history.push({
          pathname: "/page-redirector",
          state: { redirectTo: currentUrl },
        });
      } else {
        feedbackService.notifyToast("An error occurred. Contact us if the error persists.","error");
        let currentUrl = props.history.location.pathname;
        props.history.push({
          pathname: "/page-redirector",
          state: { redirectTo: currentUrl },
        });
      }
    }*/
    setButtonNextEnabled(true);
  };

  const productionOperationHandler = useHookProductionOperationHandler({
    currentProductionOrderSelectionData:currentProductionOrderSelectionData,
    successEndOperationCallback:successEndOperationCallback,
    failedEndOperationCallback:failedEndOperationCallback
  });

  const isLastOperationCheck = () => {
    if (currentProductionOrderSelectionData.isCurrentOperationTheLast) {
      return true;
    }
    return false;
  };
  const isProductionOrderSelectionDataProperlyLoaded = () => {
    return (
      currentProductionOrderSelectionData !== null &&
      "nextOperation" in currentProductionOrderSelectionData
    );
  };
  const isNextEnabled = () => {
    if (
      buttonNextEnabled &&
      isProductionOrderSelectionDataProperlyLoaded() &&
      currentProductionOrderSelectionData.productionRecord.isWaitingResume === 0
    ) {
      return true;
    }
    return false;
  };

  const isPauseEnabled = () => {
    if (pauseEnabled && isProductionOrderSelectionDataProperlyLoaded()) {
      return true;
    }

    return false;
  };

  const disableConfirmationButtons = () => {
    setButtonNextEnabled(false);
    setPauseEnabled(false);
  };

  const enableConfirmationButtons = () => {
    setButtonNextEnabled(true);
    setPauseEnabled(true);
  };
  //todo detect if in route where it should be visible
  if (!productionService.isProductionRoute(props.match.path)) {
    return <></>;
  }

  useEffect(() => {
    () => {
      //Cleanup
      clearTimeout(timerExecuteConfirm);
    };
  });

  useEffect(() => {
    console.log("CECK ",prevTriggerRevokeCancelEventState);
    if(prevTriggerRevokeCancelEventState !== undefined && prevTriggerRevokeCancelEventState !== productionOperationStore?.triggeredShowRevokeCancellation && productionOperationStore.triggeredShowRevokeCancellation === true){
      
      dispatchProductionOperationStore({type:"CLEAR_SHOW_REVOKE_CANCELLATION"});
      setShowPopupRemoveCancel(true);
      operationActionService.closeOperationActionWindows();
    }

  },[productionOperationStore?.triggeredShowRevokeCancellation]);

  useEffect(() => {
    if (!didMount) {
      console.log(
        "mounted ProdutionOperationConfirmation, clearing previous states"
      );
      operationActionService.closeOperationActionWindows();
      actionManagerService.resetAllScreensState();
      //clean up previous operation states

      setDidMount(true);
    }
  }, [
    didMount,
    operationActionService,
    actionManagerService,
    currentProductionOrderSelectionData,
  ]);
  

  const getConfirmationToolbar = () => {
    let showConfirmationToolbar = currentProductionOrderSelectionData?.currentOperation?.shopfloorSetting_operationConfirmation === "END_CURRENT_START_NEW";

    if(!showConfirmationToolbar){
      return (<></>)
    }

    return (
      <div className={styles.confirmationToolbar}>
        <div className={styles.confirmationToolbarContainer}>

          <div className={styles.conftoolCounterPlusOne}>
            {operationSessionStats.getOperationCount(currentProductionOrderSelectionData.currentOperation.Id)}
          </div>

        </div>
        <div className={styles.toolbarDivider}></div>
      </div>
    )
  }

  if (!didMount) {
    //guarantee remount, clearing field defaultValue with component internal state
    return <>Loading...</>;
  }

  return (
    <div>

      {showPopupRemoveCancel && (
        <PopupRemoveCancellation

        onSuccessRevokedCancellation={() => {
          enableConfirmationButtons();
          setShowPopupRemoveCancel(null);
         
        }}/>
      )}
      {getConfirmationToolbar()}

      <div className={styles.confirmationContainer}>
        
        {/* resume button */}
        {isProductionOrderSelectionDataProperlyLoaded() &&
          currentProductionOrderSelectionData.productionRecord
            .isWaitingResume === 1 && (
            <div className={styles.confirmButtonContainer}>
              <IonButton
                className={styles.pauseButton}
                color="success"
                disabled={!isPauseEnabled()}
                onClick={() => {
                  disableConfirmationButtons();

                  let productionRecordId =
                    currentProductionOrderSelectionData.productionRecord.Id;
                  let currentWorkerCode =
                    localStorage.getWorkerIdentificationCode();

                  productionController()
                    .resumeProduction(
                      currentWorkerCode,
                      productionRecordId,
                      cancelToken
                    )
                    .then((res) => {
                      console.log("resumed production resp: ", res);
                      dispatchProductionUIStore({
                        type: "SET_OPERATION_OVERLAY",
                        payload: false,
                      });

                
                      let resumedProduction = res.data.response.data;
                      let productionOrderProductId =
                        currentProductionOrderSelectionData.productionRecord
                          .Id_ProductionOrder_Product;
                      enableConfirmationButtons();

                      dispatchProductionUIStore({
                        type: "SET_TIMER_ELAPSED_ENABLED",
                        payload: true,
                      });

                      //using page redirector to force a remount since its the same route as current
                      //props.history.push({pathname:"/page-redirector",state:{"redirectTo":"/feature/production-order/" + currentProductionOrderSelectionData.productionOrder.Id + "/po-product/" +productionOrderProductId + "/production/" + currentProductionOrderSelectionData.production.Id}});
                      dispatchProductionOrderSelectionStore({
                        type: "SET_PRODUCTION_RECORD",
                        payload: resumedProduction,
                      });
                    })
                    .catch((res) => {
                      console.log("Failed resume production", res);
                      presentToast(
                        "Failed to properly resume production",
                        3000
                      );
                      enableConfirmationButtons();
                    });
                }}
              >
                <IonIcon
                  slot="icon-only"
                  size="large"
                  icon={caretForwardCircleOutline}
                />
              </IonButton>
            </div>
          )}

        {/* pause button */}

        {isProductionOrderSelectionDataProperlyLoaded() &&
          "shopfloorSetting_allowPause" in
            currentProductionOrderSelectionData.currentOperation &&
          currentProductionOrderSelectionData.currentOperation
            .shopfloorSetting_allowPause === 1 &&
          currentProductionOrderSelectionData.productionRecord
            .isWaitingResume === 0 && (
            <div className={styles.confirmButtonContainer}>
              <IonButton
                className={styles.pauseButton}
                color="danger"
                disabled={!isPauseEnabled()}
                onClick={() => {
                  disableConfirmationButtons();

                  let productionRecordId =
                    currentProductionOrderSelectionData.productionRecord.Id;
                  let currentWorkerCode =
                    localStorage.getWorkerIdentificationCode();

                  dispatchProductionOrderSelectionStore({
                    type: "RESET_CURRENT_OPERATION",
                  });
                  dispatchProductionOrderSelectionStore({
                    type: "RESET_CURRENT_PRODUCTION",
                  });

                  productionController()
                    .pauseProduction(currentWorkerCode, productionRecordId)
                    .then((res) => {
                      console.log("RESULTADO create operation, next: ", res);

                      let responseObject = res.data.response.data;
                      let newProductionRecordId = responseObject.Id;
                      let productionOrderProductId =
                        currentProductionOrderSelectionData.productionRecord
                          .Id_ProductionOrder_Product;
                      enableConfirmationButtons();
                      dispatchProductionUIStore({
                        type: "SET_OPERATION_OVERLAY",
                        payload: true,
                      });

                      props.history.push(
                        "/feature/production-order/" +
                          currentProductionOrderSelectionData.productionOrder
                            .Id +
                          "/po-product/" +
                          productionOrderProductId +
                          "/production/" +
                          newProductionRecordId
                      );
                    })
                    .catch((res) => {
                      console.log("Failed pause production", res);
                      presentToast("Failed to properly pause production", 3000);
                      setPauseEnabled(true);
                    });
                }}
              >
                <IonIcon
                  slot="icon-only"
                  size="large"
                  icon={stopCircleOutline}
                />
              </IonButton>
            </div>
          )}

          {currentProductionOrderSelectionData?.currentOperation?.shopfloorSetting_operationConfirmation === "END_CURRENT_START_NEW" && (
            <div className={styles.confirmButtonContainer}>
              <IonButton
                className={`${styles.confirmButton} ${currentProductionOrderSelectionData?.currentOperation?.shopfloorSetting_operationConfirmation === "END_CURRENT_START_NEW" ? styles.plusOneBtnStyles : ""}`}
                color="danger"
                disabled={!isPauseEnabled()}
                onClick={() => {

                  present({
                    cssClass: "my-css",
                    header: t("production.leave"),
                    message: t("production.leave.confirm"),
                    buttons: [
                      t("cancel"),
                      {
                        text: t("yes"),
                        handler: (d) => {
                          let productionRecordId =
                          currentProductionOrderSelectionData.productionRecord.Id;
                        let currentWorkerCode =
                          localStorage.getWorkerIdentificationCode();
                          productionController().cancelProduction({},currentWorkerCode, productionRecordId)
                          .then((res) => {
                            console.log("RESULTADO CANCEL: ", res);
        
                            props.history.push("/");
                          })
                          .catch((res) => {
                            console.log("Failed cancel production");
                            presentToast(
                              t("production.cancel.failed"),
                              3000
                            );
        
                            props.history.push("/");
                          });
                        },
                      },
                    ],
                    onDidDismiss: (e) => {},
                  });

                }}
              >
                {t('leave')}
              </IonButton>
            </div>
          )}

        {productionOperationHandler?.isProcessingActionOperationEnd && (
          <HxfOverlayLoading/>
        )}
        {/* confirm button */}
              
              <ActionScreensManager
                productionOperationHandler={productionOperationHandler}
                callbackEnableConfirm={() => {
                  enableConfirmationButtons();
                }}
                successEndOperationCallback={successEndOperationCallback}
                failedEndOperationCallback={failedEndOperationCallback}
              />
      

        <div className={styles.confirmButtonContainer}>
          <IonButton
          id="hxfProductionOperationConfirmationBtn"
            className={`${styles.confirmButton} ${currentProductionOrderSelectionData?.currentOperation?.shopfloorSetting_operationConfirmation === "END_CURRENT_START_NEW" ? styles.plusOneBtnStyles : ""}`}
            color={currentProductionOrderSelectionData?.currentOperation?.shopfloorSetting_operationConfirmation === "END_CURRENT_START_NEW" ? "success": "primary"}
            disabled={!isNextEnabled() || !appState.isConnectionAvailable()}
            
            onClick={() => {
              //let nextOperationId = currentProductionOrderSelectionData.nextOperation.Id;
              disableConfirmationButtons();
              //---
             
              productionOperationHandler.finalizeCurrentOperation();
              
              /*let currentOperationId =
                currentProductionOrderSelectionData.currentOperation.Id;
              let productionId =
                currentProductionOrderSelectionData.production.Id;
              let currentWorkerCode =
                localStorage.getWorkerIdentificationCode();
              let productionOrderProductId =
                currentProductionOrderSelectionData.productionRecord
                  .Id_ProductionOrder_Product;
              console.log("The current operation id is: ", currentOperationId);
             
              let actType = productionOperationHandler.handleAction();

              //if it is the only action and its backend type (not counting with END_OPERATION)
              //since it will not trigger the nextAction by the usual flow, we do the check directly here.
              if (
                productionOperationHandler.isActionBackEndType(actType) &&
                currentProductionOrderSelectionData?.currentOperation?.actions
                  .length === 2
              ) {
                productionOperationHandler.nextAction();
              }
              */
            }}
          >
            {getRightConfirmaitonText()}
          </IonButton>
        </div>
      </div>
      <ProductionBottomInfo />
    </div>
  );
}
export default withRouter(ProductionOperationConfirmation);
