import { useEffect, useState } from "react";
import {
  dispatchProductionOrderSelectionStore,
  useProductionOrderSelectionStore,
} from "../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import SimpleContentPopup from "../../SimpleContentPopup/SimpleContentPopup";
import ActionScreenAskBomConsumptions from "../ActionScreens/ActionScreenAskBomConsumptions/ActionScreenAskBomConsumptions";
import ActionScreenAskBomConsumptionsConfirmationButtons from "../ActionScreens/ActionScreenAskBomConsumptions/ActionScreenAskBomConsumptionsConfirmationButtons";
import ActionScreenAskProducedProductIrregularity from "../ActionScreens/ActionScreenAskProducedProductIrregularity/ActionScreenAskProducedProductIrregularity";
import ActionScreenAskUseMachine from "../ActionScreens/ActionScreenAskUseMachine/ActionScreenAskUseMachine";

import ActionScreenDefineProducedProductParameterizedVariableValues from "../ActionScreens/ActionScreenDefineProducedProductParameterizedVariableValues/ActionScreenDefineProducedProductParameterizedVariableValues";
import ActionScreenFillChecklists from "../ActionScreens/ActionScreenFillChecklists/ActionScreenFillChecklists";
import ActionScreenProduceQuantityProduct from "../ActionScreens/ActionScreenProduceQuantityProduct/ActionScreenProduceQuantityProduct";
import { useActionScreenManagerStore } from "./ActionScreenManagerStore";
import styles from "./ActionScreensManager.module.scss";
import { useHistory } from "react-router";
import ActionScreenScaleProductivity from "../ActionScreens/ActionScreenScaleProductivity/ActionScreenScaleProductivity";
import ActionScreenAskPrintLabels from "../ActionScreens/ActionScreenAskPrintLabels/ActionScreenAskPrintLabels";
import ActionRegisterProductSerialization from "../ActionScreens/ActionScreenRegisterProductSerialization/ActionScreenRegisterProductSerialization";
import ActionScreenPrintSerializationLabels from "../ActionScreens/ActionScreenPrintSerializationLabels/ActionScreenPrintSerializationLabels";
import ActionScreenIdentifyProductSerial from "../ActionScreens/ActionScreenIdentifyProductSerial/ActionScreenIdentifyProductSerial";
import ActionScreenAskMachineStop from "../ActionScreens/ActionScreenAskMachineStop/ActionScreenAskMachineStop";
import ActionScreenAskUseMachineCurrent from "../ActionScreens/ActionScreenAskUseMachineCurrent/ActionScreenAskUseMachineCurrentElement";
import ActionScreenMatchFixedVariables from "../ActionScreens/ActionScreenMatchFixedVariables/ActionScreenMatchFixedVariables";
import ActionScreenProduceTransformationFromMachineAdjustment from "../ActionScreens/ActionScreenProduceTransformationFromMachineAdjustment/ActionScreenProduceTransformationFromMachineAdjustment";
import ActionScreenAskPrintLabelsV2 from "../ActionScreens/ActionScreenAskPrintLabelsV2/ActionScreenAskPrintLabelsV2";
import ActionScreenAskLogisticsLocation from "../ActionScreens/ActionScreenAskLogisticsLocation/ActionScreenAskLogisticsLocation";
import useActionScreenTopBar from "../ActionScreens/Components/hooks/useActionScreenTopBar";
import ActionScreenProduceTransformationFromProductStock from "../ActionScreens/ActionScreenProduceTransformationFromProductStock/ActionScreenProduceTransformationFromProductStock";
function ActionScreensManager(props: any) {
  const currentActionScreenManager = useActionScreenManagerStore();
  const currentProductionOrderSelectionData = useProductionOrderSelectionStore();

  const productionOperationHandler = props.productionOperationHandler; // hookProductionOperationHandler(currentProductionOrderSelectionData,props.successEndOperationCallback,props.failedEndOperationCallback);
  const showAskQuantity = true;
  const actTopBar = useActionScreenTopBar();
  const [keyOkButtonEnabled, setkeyOkButtonEnabled] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!didMount) {
      setkeyOkButtonEnabled(true);
      setDidMount(false);
    }
  }, [didMount]);

  const closingActionScreenProduceQtyCallback = () => {
    console.log("Closing...[ActionScreensManager -> ActionScreenProduceQty]");
    //setkeyOkButtonEnabled(true); //related to the keypad of the produced ask qty
    props.callbackEnableConfirm(); //related to the FINISH / NEXT confirm buttons
  };

  const customScaleProductivityQuantityCloseCallback = () => {
    props.callbackEnableConfirm();
  };

  const genericCloseCallback = () => {
    props.callbackEnableConfirm();
    actTopBar.closeActionWindows();
  }

  const actionFinalizer = async (object:any) => {
    productionOperationHandler.returnActionResult(object);
    productionOperationHandler.nextAction();
  }

  const customAskUseMachineCloseCallback = (machinesList: any) => {
    //if all machines busy, reload page
  
   /* let oneMachineFree = false;
    if (machinesList) {
      for (let i = 0; i < machinesList.length; i++) {
        if (machinesList[i].temp_isBusyFull === false) {
          oneMachineFree = true;
          break;
        }
      }

      if (!oneMachineFree) {
        let currentUrl = history.location.pathname;
        history.push({
          pathname: "/page-redirector",
          state: { redirectTo: currentUrl },
        });
      }
    }*/
    //----
    closingActionScreenProduceQtyCallback();
  };

  useEffect(() => {
    if (currentProductionOrderSelectionData.skipBackEndTypeAction) {
      console.log("Backend type action, skipping.");

      dispatchProductionOrderSelectionStore({
        type: "UNSET_SKIP_BACKEND_TYPE_ACTION",
      });
      productionOperationHandler.nextAction();
    }
  }, [
    currentProductionOrderSelectionData.skipBackEndTypeAction,
    productionOperationHandler,
  ]);
  if (
    currentActionScreenManager.showDefineProductParameterizedVariablesValues
  ) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenDefineProducedProductParameterizedVariableValues
              callbackOnClose={closingActionScreenProduceQtyCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={ async (returnObj: any) => {
                console.log("End confirm ", returnObj);

                let object = {
                  DEFINE_PRODUCED_PRODUCT_PARAMETERIZED_VARIABLE_VALUES:
                    returnObj,
                };
                actionFinalizer(object);
              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }


  if (
    currentActionScreenManager.showMatchFixedVariables
  ) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenMatchFixedVariables
              callbackOnClose={closingActionScreenProduceQtyCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);
                let object = {
                  MATCH_FIXED_VARIABLES: {
                    Id_OperationProduct_Action:returnObj.Id_OperationProduct_Action,
                    matchingFixedVariables:returnObj.matchingFixedVariables,
                    createIrregularity:returnObj.createIrregularity
                  }
              
                };
                 actionFinalizer(object);
              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }

  if(currentActionScreenManager.showAskMachineStopScreen){
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenAskMachineStop
              callbackOnClose={customAskUseMachineCloseCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);

                setkeyOkButtonEnabled(false);
                let object = { ASK_MACHINE_STOP_USAGE: returnObj };
                actionFinalizer(object);
              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }

  if(currentActionScreenManager.showIdentifyProductSerial){
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
        
            <ActionScreenIdentifyProductSerial
              callbackOnClose={() => {
                props.callbackEnableConfirm();
                
              }}

              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);
                setkeyOkButtonEnabled(false);
                let object = { IDENTIFY_PRODUCT_SERIAL: returnObj };
                actionFinalizer(object);
              }}
            />
        
        </SimpleContentPopup>
      </>
    );
  }

  if(currentActionScreenManager.showPrintSerializationLabels){
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          
            <ActionScreenPrintSerializationLabels
              callbackOnClose={() => {

                props.callbackEnableConfirm();
              }}

              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);
                setkeyOkButtonEnabled(false);
                productionOperationHandler.nextAction();
              }}
            />
          
        </SimpleContentPopup>
      </>
    );
  }
  

  if (currentActionScreenManager.showProduceTransformationFromMachineAdjustment) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenProduceTransformationFromMachineAdjustment
              callbackOnClose={closingActionScreenProduceQtyCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);

                setkeyOkButtonEnabled(false);
                let object:any = { PRODUCE_TRANSFORMATION_FROM_MACHINE_ADJUSTMENT: returnObj };
                //currentActionScreenManager?.currentActionConfig?.scaleProductivityByProducedQuantity
                /*if(scaleProductivityVal){
                  object = {...object, SCALE_PRODUCTIVITY_QUANTITY: scaleProductivityVal};
                }*/
                actionFinalizer(object);
              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }


  if (currentActionScreenManager.showProduceTransformationFromProductStock) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenProduceTransformationFromProductStock
              callbackOnClose={genericCloseCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);

                setkeyOkButtonEnabled(false);
                let object:any = { PRODUCE_TRANSFORMATION_FROM_STOCK_PRODUCT: returnObj };
                //currentActionScreenManager?.currentActionConfig?.scaleProductivityByProducedQuantity
                /*if(scaleProductivityVal){
                  object = {...object, SCALE_PRODUCTIVITY_QUANTITY: scaleProductivityVal};
                }*/
                actionFinalizer(object);
              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }

  if (currentActionScreenManager.showProductProducedQuantityScreen) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenProduceQuantityProduct
              callbackOnClose={closingActionScreenProduceQtyCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any, scaleProductivityVal:any = null) => {
                console.log("End confirm ", returnObj);

                setkeyOkButtonEnabled(false);
                let object:any = { PRODUCE_PRODUCT_QUANTITY: returnObj };
                //currentActionScreenManager?.currentActionConfig?.scaleProductivityByProducedQuantity
                if(scaleProductivityVal !== null){
                  object = {...object, SCALE_PRODUCTIVITY_QUANTITY: scaleProductivityVal};
                }
                actionFinalizer(object);
              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }


  if(currentActionScreenManager.showRegisterProductSerializationScreen
    ){
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionRegisterProductSerialization callbackOnClose={() => {

              props.callbackEnableConfirm();
            }} callbackSuccessConfirm={async (returnObj:any) => {
 
              setkeyOkButtonEnabled(false);
              let object = { REGISTER_PRODUCT_SERIALIZATION: returnObj };
              productionOperationHandler.returnActionResult(object);
              productionOperationHandler.nextAction();
            }}/>
          </div>
        </SimpleContentPopup>
      </>
    );
  }

  if (currentActionScreenManager.showAskPrintLabelsScreen) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          
            <ActionScreenAskPrintLabelsV2
              callbackOnClose={() => {
                props.callbackEnableConfirm();
              }}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any) => {
                setkeyOkButtonEnabled(false);
                productionOperationHandler.nextAction();
              }}
            />
          
        </SimpleContentPopup>
      </>
    );
  }

  if (currentActionScreenManager.showFillChecklistsScreen) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenFillChecklists
              callbackOnClose={closingActionScreenProduceQtyCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);

                setkeyOkButtonEnabled(false);
                let object = { FILL_CHECKLISTS: returnObj };
                actionFinalizer(object);
              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }

  if (currentActionScreenManager.showAskProducedProductIrregularityScreen) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenAskProducedProductIrregularity
              productionOperationHandler={productionOperationHandler}
              callbackOnClose={closingActionScreenProduceQtyCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);
              
                let actStore = currentActionScreenManager;
                returnObj = {irregularitiesList:returnObj, Id_OperationProduct_Action:actStore.operationActionData.Id};
              
                setkeyOkButtonEnabled(false);
                let object = { ASK_PRODUCED_PRODUCT_IRREGULARITY: returnObj };
                actionFinalizer(object);

              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }

  if(currentActionScreenManager.showAskLogisticsLocationScreen){
    //no longer uses simplecontentpopup
    return (
      <ActionScreenAskLogisticsLocation callbackOnClose={genericCloseCallback}
      callbackEnableConfirm={props.callbackEnableConfirm}
      callbackSuccessConfirm={(returnObj: any) => {
        console.log("End confirm ", returnObj);
        
        //setkeyOkButtonEnabled(false);
        let object = { ASK_LOGISTICS_LOCATION: returnObj };
        actionFinalizer(object);
      }}/>
    )
  }

  if (currentActionScreenManager.showProductivityScaleScreen) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenScaleProductivity
              callbackOnClose={customScaleProductivityQuantityCloseCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);

                setkeyOkButtonEnabled(false);
                let object = { SCALE_PRODUCTIVITY_QUANTITY: returnObj };
                actionFinalizer(object);
              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }


  if (currentActionScreenManager.showAskUseMachineCurrentScreen) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenAskUseMachineCurrent
            skipStoreTempDataIfSelectionUnchanged={true}
              callbackOnClose={customAskUseMachineCloseCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);

                setkeyOkButtonEnabled(false);
                let object = { ASK_USE_MACHINE_CURRENT: returnObj };
                actionFinalizer(object);
              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }

  if (currentActionScreenManager.showAskUseMachineNextScreen) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenAskUseMachine
              callbackOnClose={customAskUseMachineCloseCallback}
              callbackEnableConfirm={props.callbackEnableConfirm}
              callbackSuccessConfirm={(returnObj: any) => {
                console.log("End confirm ", returnObj);

                setkeyOkButtonEnabled(false);
                let object = { ASK_USE_MACHINE_NEXT: returnObj };
                actionFinalizer(object);
              }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }

  if (currentActionScreenManager.showAskBomConsumptionsScreen) {
    return (
      <>
        <SimpleContentPopup className={styles.popupActionScreenProduceQty}>
          <div>
            <ActionScreenAskBomConsumptions
                productionOperationHandler={productionOperationHandler}
                callbackOnClose={closingActionScreenProduceQtyCallback}
                callbackEnableConfirm={props.callbackEnableConfirm}
                callbackSuccessConfirm={async (returnObj: any) => {
                  console.log("End confirm ", returnObj);
                  let object = { ASK_BOM_CONSUMPTIONS: returnObj };
                  productionOperationHandler.returnActionResult(object);
                  productionOperationHandler.nextAction();
                }}
            />
          </div>
        </SimpleContentPopup>
      </>
    );
  }

  return <></>;
}

export default ActionScreensManager;
