import createStore from "../../../../../AppCustomStateManagement/createStore";

// Library interfaces
interface IActionsScreenManager {
  showFillChecklistsScreen: boolean;
  showProductProducedQuantityScreen: boolean;
  showAskProducedProductIrregularityScreen: boolean;
  showDefineProductParameterizedVariablesValues: boolean;
  allowActionCloseOnDocumentClick: boolean;
  showAskBomConsumptionsScreen: boolean;
  showAskLogisticsLocationScreen:boolean;
  showAskUseMachineNextScreen: boolean;
  showAskUseMachineCurrentScreen:boolean;
  showProductivityScaleScreen: boolean;
  showAskPrintLabelsScreen: boolean;
  showRegisterProductSerializationScreen:boolean;
  showPrintSerializationLabels:boolean;
  showIdentifyProductSerial:boolean;
  showAskMachineStopScreen:boolean;
  showMatchFixedVariables:boolean;
  showProduceTransformationFromMachineAdjustment:boolean;
  showProduceTransformationFromProductStock:boolean;
  currentActionConfig?: any;
  operationActionData?:any;
}

interface IActionsScreenManagerAction {
  type: any;
  payload?: any;
}

const initialStatesScreens = {
  showFillChecklistsScreen: false,
  showProductProducedQuantityScreen: false,
  showAskProducedProductIrregularityScreen: false,
  showDefineProductParameterizedVariablesValues: false,
  showAskBomConsumptionsScreen: false,
  showAskUseMachineNextScreen: false,
  showAskUseMachineCurrentScreen:false,
  showProductivityScaleScreen: false,
  showAskPrintLabelsScreen: false,
  showRegisterProductSerializationScreen:false,
  showPrintSerializationLabels:false,
  showIdentifyProductSerial:false,
  showAskMachineStopScreen:false,
  showMatchFixedVariables:false,
  showProduceTransformationFromMachineAdjustment:false,
  showProduceTransformationFromProductStock:false,
  showAskLogisticsLocationScreen:false
};

const initialState = {
  allowActionCloseOnDocumentClick: true,
  operationActionData:null,
  currentActionConfig: {},
  ...initialStatesScreens,
};

const reducer = (
  store: IActionsScreenManager,
  action: IActionsScreenManagerAction
) => {
  switch (action.type) {
    case "RESET_STORE":
      store = initialState;
      return store;
    case "SHOW_ASK_MACHINE_STOP_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showAskMachineStopScreen:true,
      };
      return store;
    case "SHOW_IDENTIFY_PRODUCT_SERIAL_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showIdentifyProductSerial:true,
      };
      return store;
    case "SHOW_REGISTER_PRODUCT_SERIALIZATION_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showRegisterProductSerializationScreen:true,
      };
      return store;
    case "SHOW_PRINT_SERIALIZATION_LABELS":
      store = {
        ...store,
        ...initialStatesScreens,
        showPrintSerializationLabels:true,
      };
      return store;
    case "SHOW_MATCH_FIXED_VARIABLES":
        store = {
          ...store,
          ...initialStatesScreens,
          showMatchFixedVariables: true,
        };
        return store;
    case "SHOW_ASK_BOM_CONSUMPTIONS_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showAskBomConsumptionsScreen: true,
      };
      return store;
    case "SHOW_ASK_LOGISTICS_LOCATION_SCREEN":
        store = {
          ...store,
          ...initialStatesScreens,
          showAskLogisticsLocationScreen: true,
        };
        return store;
    case "SHOW_ASK_PRINT_LABELS_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showAskPrintLabelsScreen: true,
      };
      return store;
    case "SHOW_FILL_CHECKLISTS_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showFillChecklistsScreen: true,
      };
      return store;
    case "SHOW_PRODUCT_PRODUCED_QUANTITY_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showProductProducedQuantityScreen: true,
      };
      return store;
    case "SHOW_ASK_PRODUCED_PRODUCT_IRREGULARITY_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showAskProducedProductIrregularityScreen: true,
      };
      return store;
    case "SHOW_ASK_USE_MACHINE_CURRENT_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showAskUseMachineCurrentScreen: true,
      };
      return store;
    case "SHOW_ASK_USE_MACHINE_NEXT_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showAskUseMachineNextScreen: true,
      };
      return store;
    case "SHOW_DEFINE_PRODUCT_PRODUCED_PARAMETERIZED_VARIABLE_VALUES":
      store = {
        ...store,
        ...initialStatesScreens,
        showDefineProductParameterizedVariablesValues: true,
      };
      return store;
    case "SET_CURRENT_ACTION_CONFIG":
      store.currentActionConfig = action.payload;
      return store;
    case "SET_OPERATION_ACTION_DATA":
      store.operationActionData = action.payload;
      return store;
    case "SHOW_PRODUCE_TRANSFORMATION_FROM_MACHINE_ADJUSTMENT":
      store = {
        ...store,
        ...initialStatesScreens,
        showProduceTransformationFromMachineAdjustment: true
      };
      return store;
    case "SHOW_PRODUCE_TRANSFORMATION_FROM_STOCK_PRODUCT":
        store = {
          ...store,
          ...initialStatesScreens,
          showProduceTransformationFromProductStock: true
        };
        return store;
    case "SHOW_SCALE_PRODUCTIVITY_QUANTITY_SCREEN":
      store = {
        ...store,
        ...initialStatesScreens,
        showProductivityScaleScreen: true,
      };
      return store;
    case "DISABLE_ALLOW_ACTION_CLOSE_ON_DOCUMENT_CLICK":
      store = { ...store, allowActionCloseOnDocumentClick: false };
      return store;
    default:
      return store;
  }
};

export const [useActionScreenManagerStore, dispatchActionScreenManagerStore] =
  createStore(initialState, reducer);
