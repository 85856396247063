import { IonButton, IonIcon } from "@ionic/react";
import { addOutline, checkmarkCircle, checkmarkOutline, removeOutline } from "ionicons/icons";
import { useState } from "react";
import productsController from "../../../../barrel/controllers/productsController";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import HxfSelectorField from "../../HxfSelectorField/HxfSelectorField";
import HxfIonPopup from "../../ProductionUI/HxfIonPopup/HxfIonPopup";
import DefineProductParameterizedVariableValuesPopup from "../defineProductParameterizedVariableValues/DefineProductParameterizedVariableValuesPopup";
import styles from "./DefineProductRemainsPopup.module.scss";
import QueueIcon from '@mui/icons-material/Queue';
import HxfTextfieldNumber from "../../HxfTextfieldNumber/HxfTextfieldNumber";
interface IProps{
    onClose?:any;
    onConfirm?:any;
    baseProduct?:any; //base product so that we only add the "remains"
}
function DefineProductRemainsPopup(props:IProps){

    const {t} = useHxfTranslation();
    const [resultingProductSelected, setResultingProductSelected] = useState<any>(null);
    const [productSelected, setProductSelected] = useState<any>(null);
    const [popupDefinedPVSData, setPopupDefinedPVSData] = useState<any>(null);
    const getSelectedProductValue = () => {
        if(productSelected?.Id){
            return {label:"("+productSelected?.code+")" + productSelected?.name, value: productSelected?.Id};
        }
        return null;
    }

    const [currentRemainsAdded, setCurrentRemainsAdded] = useState<any>([]);
    const checkIfConfirmEnabled = () => {

        return resultingProductSelected !== null;
    }

    const getCurrentRemainsArray = () => {
        return currentRemainsAdded;
    }
    const getRemainsProductQty = (index:any) => {
     
        let arrayRemains = currentRemainsAdded;
        if(arrayRemains?.[index]){
    
          return arrayRemains[index].remainsQuantity;
        }
    
        return "";
    }
    const updateRemainsQty= (index:any, newQty:any) => {
        let arrayRemains = currentRemainsAdded;

        if(arrayRemains?.[index]){
    
            arrayRemains[index].remainsQuantity = newQty;
        }
    
        setCurrentRemainsAdded(JSON.parse(JSON.stringify(arrayRemains)));
    }
    const checkHasErrorRemains = (index:any) => {
        let currentRemains = getCurrentRemainsArray();
        let productRemains = currentRemains[index];
    
        let remainsQuantity = productRemains?.remainsQuantity ? parseFloat(productRemains?.remainsQuantity) : 0;
        if(remainsQuantity > 0){
          //has qty defined all ppvvs must be defined!!
          let arrPPVVS = productRemains.arrayProductParameterizedVariables;
          for(let i = 0; i<arrPPVVS.length;i++){
            if(arrPPVVS[i].Value === null || arrPPVVS[i].Value === ""){
              return true;
            }
          }
        }
    
        return false;
      }

    const updateRemainsProductPPVV = (index:any, idProductParameterizedVariable:any, newValue:any) => {

          let arrayRemains = currentRemainsAdded;
          if(arrayRemains?.[index]){
      
      
            let remainsProduct = arrayRemains[index];
            let arrayPPVVS = remainsProduct?.arrayProductParameterizedVariables ? remainsProduct?.arrayProductParameterizedVariables : [];
      
            for(let i = 0; i< arrayPPVVS.length; i++){
              if(arrayPPVVS[i].Id_Product_ParameterizedVariable === idProductParameterizedVariable){
                arrayPPVVS[i].Value = newValue;
                break;
              }
            }
      
            remainsProduct.arrayProductParameterizedVariables = JSON.parse(JSON.stringify(arrayPPVVS));
            arrayRemains[index] = remainsProduct;
            setCurrentRemainsAdded(JSON.parse(JSON.stringify(arrayRemains)));
      
          }
      
    }
    const addNewRemain = () => {
  
        let baseProduct = props.baseProduct;
        let newArrRemains = [...currentRemainsAdded];
    
        let newRemainsProductDefault = {
          Id_Product: baseProduct.Id_Product,
          productName:baseProduct.name,
          productCode:baseProduct.code,
          arrayProductParameterizedVariables:[],
          remainsQuantity:0
        };
    
        let newPPVVs = baseProduct?.arrayProductParameterizedVariables ? JSON.parse(JSON.stringify(baseProduct?.arrayProductParameterizedVariables)) : [];
        //set the values as empty (numeric only for now)
        for(let i = 0; i<newPPVVs.length; i++){
          if(newPPVVs[i].varType === "numeric"){
            newPPVVs[i].Value = "";
          }
        }
        newRemainsProductDefault.arrayProductParameterizedVariables = newPPVVs;
    
        
        newArrRemains.push(newRemainsProductDefault);

        setCurrentRemainsAdded(newArrRemains);
    }

      const removeRemains = (index:any) => {
     
        let arrRemains = [...currentRemainsAdded];
        let newArr = [];
        for(let i = 0; i< arrRemains.length; i++){
          if(i !== index){
            newArr.push(arrRemains[i]);
          }
        }
       setCurrentRemainsAdded(JSON.parse(JSON.stringify(newArr)));
   
      }
    return (
        <>
        {popupDefinedPVSData && (
            <DefineProductParameterizedVariableValuesPopup
                    
                isLoading={false}
                currentData={popupDefinedPVSData}
                allowCurrentDataValuesLoad={true}
                includeFullParameterizedVariablesData={true}
                title={t('product.parameters')}
                onClosePopup={() => {
                    setPopupDefinedPVSData(null);
                    setProductSelected(null);
                    setResultingProductSelected(null);
                    
                }}
                onSuccessConfirm={(res:any) => {
                    setResultingProductSelected({...resultingProductSelected,  arrayProductParameterizedVariables:res});
                    setPopupDefinedPVSData(null);
                }}
            />
        )}
        {!popupDefinedPVSData && (
            <>
                <HxfIonPopup onClose={() => {
                    props.onClose();
                }} title={t('remains')} modelSettings={{contentHeightPct:85,customPopupMaxHeight:'850px'}} bottomConfirmationContainer={(
                    <div className={styles.bottomContainer}>

                        <div>
                        <IonButton
                            disabled={false}
                            className={styles.btnConfirm}
                            data-btn={"select-a-product-confirm-btn"}
                            size="large"
                            onClick={() => {
                                let currentRemains = getCurrentRemainsArray();
                                for(let i=0; i<currentRemains.length; i++){
                                  let remainsQuantity = currentRemains[i]?.remainsQuantity ? parseFloat(currentRemains[i]?.remainsQuantity) : 0;
                                  if(remainsQuantity > 0){
                                    //has qty defined all ppvvs must be defined!!
                                    let arrPPVVS = currentRemains[i].arrayProductParameterizedVariables;
                                    for(let i = 0; i<arrPPVVS.length;i++){
                                      if(arrPPVVS[i].Value === null || arrPPVVS[i].Value === ""){
                                        console.log("Has errors , remains");
                                        return;
                                      }
                                    }
                                  }
                                }

                                props.onConfirm({remains:currentRemains});
                            }}
                            >
                            <IonIcon slot="start" size="large" icon={checkmarkOutline} />
                            {currentRemainsAdded.length === 0 ? t('no.remains') : t("confirm")}
                            </IonButton>
                            
                        </div>
                    </div>
                )} content={(
                <div className={styles.container}>
                <div className={styles.label}>
    
                    {t('set.the.remains.qty')}
                
                </div>
                <div className={`${styles.stepIcon}`}>
                <div className={`${styles.stepRemains}`}>
                <QueueIcon fontSize={"medium"}/>
                </div>
                
                </div>
                <div>

                </div>
                                

                {getCurrentRemainsArray()?.length > 0 && (
                <>
                {getCurrentRemainsArray().map((objRemainsProduct:any, index:any) => (
                        <div key={"remains_product_" + index} className={styles.remainsProductContainer}>
                            <div style={{width:'100%'}}>
                                <div className={styles.remainsContainerTop}>
                                    <div><b>{objRemainsProduct.productName}</b></div>
                                    <div onClick={() => {
                                        removeRemains(index);
                                    }} className={styles.remainsCloseBtn}>X</div>
                                </div>
                                <div style={{display:'flex',justifyContent:'center',flexWrap:'wrap',minWidth:10}}>
                            
                                
                                {objRemainsProduct?.arrayProductParameterizedVariables?.length > 0 && (
                                    <>
                                    {objRemainsProduct?.arrayProductParameterizedVariables.map((objRemainsProductPPV:any, indexPPV:any) => (
                                        <div style={{margin:10,display:'flex',justifyContent:'center'}} key={"remainsproduct_" + index +"_ppv_" + indexPPV}>
                                            {
                                            objRemainsProductPPV?.varType === "numeric" ? (
                                                <>
                                                <HxfTextfieldNumber
                                                dataInputFieldAttributeValue={"input-field-new-pv-value-idxproduct-" + index + "-idxppv-" + indexPPV}
                                                
                                                centerInputText={true}
                                                error={checkHasErrorRemains(index)}
                                                label={objRemainsProductPPV.name} 
                                                onChange={(evt:any) => {
                                                    let newValue = evt;
                                                    updateRemainsProductPPVV(index, objRemainsProductPPV.Id_Product_ParameterizedVariable, newValue);
                                                }}
                                                value={objRemainsProductPPV.Value}
                                                /> 
                                                </>
                                            ) : (
                                                <div style={{margin:10}}><b>{objRemainsProductPPV.name}:</b> {objRemainsProductPPV.Value}</div>
                                            )
                                            }
                                            
                                        </div>
                                    ))}
                                    </>
                                )}
    
                                </div>



                                <div className={styles.inputContainerQtyWorked}>
                                                <IonButton
                                                    data-btn={"subcontract-remains-qty-idx-" + index}
                                                    disabled={false}
                                                    color="success"
                                                    className={`${styles.addrembtn} ${styles.remainsBtnStyle} `}
                                                    onClick={() => {
                                                    
                                                        
                                                        let qt = getRemainsProductQty(index);
                                                        if(!qt){
                                                            qt = 0;
                                                        }
            
                                                        let newQt = parseInt(qt) - 1;
                                                        if(newQt < 0){
                                                            newQt = 0;
                                                        }
                                                        

                                                      
                                                        updateRemainsQty(index, newQt);
                                                    }}
                                                >
                                                    <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                                                </IonButton>
        
                                                <div>
                                                <HxfTextfieldNumber
                                            
                                                fullWidth={true} centerInputText={true} error={false} 
                                                label={t('quantity')} placeholder={t('quantity')} onChange={(evt:any) => {
            
                                                    if(evt !== null && evt !== ""){
                                                        evt = parseInt(evt); //ints only
                                                    }
                                                    updateRemainsQty(index, evt);
                                
                                                }}
                                                value={getRemainsProductQty(index)} //getRemainsAdjProductQty(index)
                                                /> 
                                                </div>
        
        
                                            <IonButton
                                            disabled={false}
                                            data-btn={"add-remains-qty-idx-" + index}
                                                className={styles.addrembtn}
                                                color="success"
                                                onClick={() => {
                                                
                                                let qt = getRemainsProductQty(index);
                                                if(!qt){
                                                    qt = 0;
                                                }
        
                                                let newQt = parseInt(qt) +1;
                                                updateRemainsQty(index, newQt);
                                                }}
                                            >
                                                <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                                            </IonButton>
                                </div>
                            </div>
        
        
                            <div>
        
                            </div>
                        </div>
                    ))}
                    </>
                )}
                        <div style={{display:'flex',justifyContent:'center',marginTop:30}}>
                            <IonButton
                            disabled={false}
                                data-btn={"add-new-remains"}
                                className={styles.addrembtn}
                                color="success"
                                onClick={() => {
                                    addNewRemain();
                             
                                }}
                            >
                                <IonIcon slot="start" icon={addOutline}></IonIcon>
                                {t('add.new.remains')}
                            </IonButton>
                        </div> 
                    </div>
                )}/>
            </>
        )}

        </>
    )
}

export default DefineProductRemainsPopup;