import {
  IonButton,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner,
  useIonAlert,
  useIonToast
} from "@ionic/react";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import { albumsOutline, closeCircle } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import receivingOrderProductsController from "../../../../../../../barrel/controllers/receivingOrderProductsController";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import usePrevious from "../../../../../../../barrel/hooks/usePrevious";
import DatesConversionService from "../../../../../../../barrel/services/datesConversionService";
import UomService from "../../../../../../../barrel/services/uomService";
import LoadingSpinnerImg from "../../../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomAppliedFilterChip from "../../../../../../CustomElements/CustomAppliedFilterChip/CustomAppliedFilterChip";
import CustomArrowBack from "../../../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import CustomUnavailableErrorNotice from "../../../../../../CustomElements/CustomUnavailableErrorNotice/CustomUnavailableErrorNotice";
import ErrorNoticeRequiresNetworkLoadObject from "../../../../../../CustomElements/ErrorNoticeRequiresNetworkLoadObject/ErrorNoticeRequiresNetworkLoadObject";
import HxfInfiniteScroll from "../../../../../../CustomElements/HxfInfiniteScroll/HxfInfiniteScroll";
import DefineProductQuantityPopup from "../../../../../../CustomElements/statePopups/defineProductQuantityPopup/DefineProductQuantityPopup";
import DefineStockLocationPopup from "../../../../../../CustomElements/statePopups/defineStockLocationPopup/DefineStockLocationPopup";
import TaskProgressBar from "../../../../../../CustomElements/TaskProgressBar/TaskProgressBar";
import InAppTemplate from "../../../../../../InAppTemplate/InAppTemplate";
import CornerButton from "../../../../../Productions/Standard/ProductionProcess/CornerButton/CornerButton";
import styles from "./ShippingReturnedReceivingOrderProducts.module.scss";
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import useFeedbackService from "../../../../../../../barrel/hooks/useFeedbackService";
import useSyncSettings from "../../../../../../../barrel/hooks/useSyncSettings";
import CustomErrorNotice from "../../../../../../CustomElements/CustomErrorNotice/CustomErrorNotice";
import AddFinishedProductPopup from "./AddFinishedProductPopup/AddFinishedProductPopup";

function ShippingReturnedReceivingOrderProducts(props:any) {
  const showProgressOnTheSelectedUom = true; //in the future we might want to make this a setting instead of a constant
  const setDefaultNoLotAsLotOnLoad = false; //in the future we might want to make this dynamic, its on false by default to force the worker to confirm it wants to use default
  const [present, dismiss] = useIonAlert();
  const shippingOrderId = props?.match?.params?.soId;
  const { sessionState } = useGlobalState();
  const [didMount, setDidMount] = useState(false);
  const previousDidMount = usePrevious(didMount);
  const sizePage = 25;
  const {t} = useHxfTranslation();
  const loadedObjsRef = useRef<any>(null);
  const syncSettings = useSyncSettings();
  
  const [notFound, setNotFound] = useState(false);
  const [failedToLoadPosError, setFailedToLoadPosError] = useState(false);
  const [requiresNetworkLoadError, setRequiresNetworkLoadError] =
    useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [infoOrderData, setInfoOrderData] = useState<any>({});

  const [
    receivingOrderProductsReceivedData,
    setReceivingOrderProductsReceivedData,
  ] = useState<any>(null);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const infiniteScrollEnabledRef = useRef(false);
  const allowedInfiniteScrollLoadPageRef = useRef(0);
  const [indexReRender, setIndexReRender] = useState(0);
  const feedbackService = useFeedbackService();
  const [searchbarInput, setSearchbarInput] = useState("");
  const timerStartSearching = useRef(0);

  const receivingOrdersSearchFilters = useRef({});
  const posContainerRef = useRef(null);
  const [filtersApplied, setFiltersApplied] = useState([]);
  const previousFiltersApplied = usePrevious(filtersApplied);
  const [addedProducts, setAddedProducts] = useState<any>([]);
  const [receivingLocation, setReceivingLocation] = useState<any>(null);

  const [receivingProducts, setReceivingProducts] = useState<any>([]); //array of {Id_ShippingOrder_Product, quantity};
  const previousReceivingProducts = usePrevious(receivingProducts);
  const [isPopupReceivingLocationOpen, setIsPopupReceivingLocationOpen] =
    useState(false);
  const [presentToast] = useIonToast();

  //for loaded receiving products
  const [
    isPopupDefineProductQuantityOpen,
    setIsPopupDefineProductQuantityOpen,
  ] = useState(false);

  //for added products
  const [
    popupAddedProductDefineProductQuantityOpen,
    setPopupAddedProductDefineProductQuantityOpen
  ] = useState<any>(null);
  const [popupQuantityProductData, setPopupQuantityProductData] =
    useState<any>(null);

  const [addFinishedProductPopup, setAddFinishedProductPopup] = useState<any>(null);

  const removeQtyReceivingProduct = (idShippingProduct:any) => {
    let newReceivingProducts:any = [];
    for (let i = 0; i < receivingProducts.length; i++) {
      if (
        receivingProducts[i].Id_ShippingOrder_Product !== idShippingProduct
      ) {
        newReceivingProducts.push(receivingProducts[i]);
      }
    }

    setReceivingProducts(newReceivingProducts);
  };

  const addOrMergeNewProduct = (addingProduct:any) => {
    //check if product already defined
    let foundProductOnReceivingDefined = false;
    let loadedObjs =loadedObjsRef.current;
    //check if product exists in the "loadedObjs"
    for(let i = 0; i<loadedObjs.length; i++){
      let idProduct = loadedObjs[i].Id_Product;
      if(idProduct === addingProduct.Id_Product){

        let pvsMatch = true;
        let addingProductPvs = addingProduct?.stockMovementProductParameterizedVariables ? addingProduct?.stockMovementProductParameterizedVariables : [];
        let loadedObjPvs = loadedObjs[i]?.shippingOrderProductParameterizedVariablesValueData ? JSON.parse(loadedObjs[i].shippingOrderProductParameterizedVariablesValueData) : [];

        
        if(loadedObjPvs.length !== addingProductPvs.length){
          pvsMatch = false;
          continue;
        }else{
          for(let j = 0; j<addingProductPvs.length; j++){
            let found = false;
            for(let k = 0; k<loadedObjPvs.length; k++){
              if(addingProductPvs[j].Id_Product_ParameterizedVariable === loadedObjPvs[k].Id_Product_ParameterizedVariable){
                if(addingProductPvs[j].Value === loadedObjPvs[k].Value){
                  found = true;
                  break;
                }
              }
            }

            if(!found){
              pvsMatch = false;
              break;
            }

          }
        }


        if(pvsMatch){
          //product matched 
          addQtyReceivingProduct(loadedObjs[i].Id,addingProduct.Quantity );
          foundProductOnReceivingDefined = true;
          return;
        }

      }


      if(!foundProductOnReceivingDefined){

        let newAddedProducts = [...addedProducts];
        let foundProduct = false;
        //check if alredy defined and sum, or push new
        for(let i = 0; i<newAddedProducts.length; i++){
          let idProduct = newAddedProducts[i].Id_Product;
          if(idProduct === addingProduct.Id_Product){
            let addingProductPvs = addingProduct?.stockMovementProductParameterizedVariables ? addingProduct?.stockMovementProductParameterizedVariables : [];
            let addedProductPvs = newAddedProducts[i]?.stockMovementProductParameterizedVariables ? newAddedProducts[i].stockMovementProductParameterizedVariables : [];

            let pvsMatch = true;

            if(addedProductPvs.length !== addingProductPvs.length){
              pvsMatch = false;
              continue;
            }else{
              for(let j = 0; j<addingProductPvs.length; j++){
                let found = false;
                for(let k = 0; k<addedProductPvs.length; k++){
                  if(addingProductPvs[j].Id_Product_ParameterizedVariable === addedProductPvs[k].Id_Product_ParameterizedVariable){
                    if(addingProductPvs[j].Value === addedProductPvs[k].Value){
                      found = true;
                      break;
                    }
                  }
                }

                if(!found){
                  pvsMatch = false;
                  break;
                }

              }
            }


            if(pvsMatch){
              //product matched 
              newAddedProducts[i].Quantity = addingProduct.Quantity;
              foundProduct = true;
              return;
            }

          }
        }


        if(!foundProduct){
          //push new
          newAddedProducts = [...newAddedProducts, addingProduct];
          setAddedProducts(newAddedProducts);
        }
      }
    }


  }

  const addQtyReceivingProduct = (idShippingProduct:any, addQty:any) => {
    let newReceivingProducts = [...receivingProducts];
    for (let i = 0; i < newReceivingProducts.length; i++) {
      if (
        newReceivingProducts[i].Id_ShippingOrder_Product === idShippingProduct
      ) {
        newReceivingProducts[i].quantity = newReceivingProducts[i].quantity + addQty;
        setReceivingProducts(newReceivingProducts);
        return true;
      }
    }
    newReceivingProducts.push({
      Id_ShippingOrder_Product: idShippingProduct,
      quantity: addQty,
    });
    setReceivingProducts(newReceivingProducts);
    return true;
  }
  const updateQtyReceivingProduct = (idShippingProduct:any, newQty:any) => {
    let newReceivingProducts = [...receivingProducts];
    for (let i = 0; i < newReceivingProducts.length; i++) {
      if (
        newReceivingProducts[i].Id_ShippingOrder_Product === idShippingProduct
      ) {
        newReceivingProducts[i].quantity = newQty;
        setReceivingProducts(newReceivingProducts);
        return true;
      }
    }

    newReceivingProducts.push({
      Id_ShippingOrder_Product: idShippingProduct,
      quantity: newQty,
    });
    setReceivingProducts(newReceivingProducts);
    return true;
  };

  const loadedSuccessfully = () => {

    return !notFound && !failedToLoadPosError;
  }

  const getQtyReceivingProductByIdReceivingProduct = (idShippingProduct:any) => {
    for (let i = 0; i < receivingProducts.length; i++) {
      if (
        receivingProducts[i].Id_ShippingOrder_Product === idShippingProduct
      ) {
        return receivingProducts[i].quantity;
      }
    }

    return 0;
  };

  const getReceivingOrderProductTotalQuantity = (quantity:any, obj:any) => {
    if (showProgressOnTheSelectedUom && obj?.Id_UoM_Selected && quantity > 0) {
      quantity = UomService().convertQuantity(
        quantity,
        -1,
        obj.Id_UoM_Selected,
        obj.uomConversionsData
      );
    }

    return quantity;
  };

  const getFinishedReceivingOrderProductQuantity = (
    idShippingProduct:any,
    obj:any
  ) => {
    if (!receivingOrderProductsReceivedData) {
      return 0;
    }
    //compare shipping product with the received id product and arr product parameterized vars
    for (let i = 0; i < receivingOrderProductsReceivedData.length; i++) {
      if (
        receivingOrderProductsReceivedData[i].Id_ShippingOrder_Product ===
        idShippingProduct
      ) {
        let receivedQuantity =
          receivingOrderProductsReceivedData[i].receivedQuantity;
        if (
          showProgressOnTheSelectedUom &&
          obj?.Id_UoM_Selected &&
          receivedQuantity > 0
        ) {
          receivedQuantity = UomService().convertQuantity(
            receivedQuantity,
            -1,
            obj.Id_UoM_Selected,
            obj.uomConversionsData
          );
        }

        return receivedQuantity;
      }
    }
    return 0;
  };



  const onFilterChipRemove = (index:any) => {
    let newFiltersApplied:any = [];
    for (let i = 0; i < filtersApplied.length; i++) {
      if (i !== index) {
        newFiltersApplied.push(filtersApplied[i]);
      }
    }
    setFiltersApplied(newFiltersApplied); //will trigger useeffect
  };

  const getProperFilterChip = (obj:any, index:any) => {
    //custom styling of chip
    if (obj.filterKey === "Id_BuyOrder") {
      return (
        <CustomAppliedFilterChip
          filterTextElement={
            <>
              <b>{t("buy.order")}:</b> {obj.orderCode}
            </>
          }
          onRemoveFilter={() => {
            onFilterChipRemove(index);
          }}
        />
      );
    }

    let labelDefault = obj?.filterLabel ? obj.filterLabel : obj.filterKey;
    let chipVal = obj[obj.filterKey];
    //default
    return (
      <CustomAppliedFilterChip
        filterTextElement={
          <>
            <b>{labelDefault}:</b> {chipVal}
          </>
        }
        onRemoveFilter={() => {
          onFilterChipRemove(index);
        }}
      />
    );
  };

  const getTaskUomLabel = (obj:any) => {
    if (obj?.temp_UoM_SelectedLabel) {
      return obj.temp_UoM_SelectedLabel;
    } else if (obj?.temp_UoM_BaseLabel) {
      return obj.temp_UoM_BaseLabel;
    }
    return "";
  };
  const getFormattedFiltersForRequest = (filtersArray:any) => {
    let filtersArr = [...filtersArray];
    let formattedFilters:any = {};
    for (let i = 0; i < filtersArr.length; i++) {
      let filterKey = filtersArr[i].filterKey;
      let filterValue = filtersArr[i][filterKey];
      formattedFilters[filterKey] = filterValue;
    }

    return formattedFilters;
  };

  const getAddingQtyElem = (idShippingProduct:any, obj:any) => {
    let qty = getQtyReceivingProductByIdReceivingProduct(idShippingProduct);

    if (qty <= 0) {
      return <></>;
    }

    let uomLabel = "";

    if (showProgressOnTheSelectedUom && obj?.Id_UoM_Selected && qty > 0) {
      qty = UomService().convertQuantity(
        qty,
        -1,
        obj.Id_UoM_Selected,
        obj.uomConversionsData
      );
      uomLabel = obj?.temp_UoM_SelectedLabel;
    }

    return (
      <div className={styles.addingQty}>
        <div>
          +{qty} {uomLabel}
        </div>
      </div>
    );
  };

  const getAddingProductElement = (obj:any, index:any) => {
    let productData = obj;

    obj = { ...obj };


    let uomLabel = "";


    return (
      <IonCardHeader
        className={styles.elemElement}
        onClick={() => {
          
          setPopupAddedProductDefineProductQuantityOpen({
            indexEdittingAddedProduct:index,
            name:obj.name,
            code:obj.code,
            qtyValue:obj.Quantity
          });
         /* let productData = JSON.parse(obj.productData);
          let passData:any = {
            Id_ShippingOrder_Product: obj.Id,
            Id_Product: productData.Id_Product,
            name: productData.name,
            code: productData.code,
            qtyValue: 0,
            uomLabel: null,
            Id_UoM_Selected: null,
            uomConversionsData: obj?.uomConversionsData,
          };

          let qtyValue = obj.Quantity;*/

          //todo handle uom properly
          /*if (obj?.Id_UoM_Selected) {
            //has default uom selected
            passData.uomLabel = obj?.temp_UoM_SelectedLabel;
            passData.Id_UoM_Selected = obj?.Id_UoM_Selected;
            //convert back to base
            passData.qtyValue = UomService().convertQuantity(
              qtyValue,
              -1,
              obj.Id_UoM_Selected,
              obj.uomConversionsData
            );
          } else {
            //no uom was selected on the shipping, so we use the label of the base uom
            passData.uomLabel = obj.temp_UoM_BaseLabel;
          }*/
          //setPopupQuantityProductData(passData);
          //setIsPopupDefineProductQuantityOpen(true);
        }}
      >
        <div className={styles.elemBackgroundContainer}>
          <HighlightAltIcon />
        </div>
        <div className={styles.orderNameCodeContainer}>
          <div className={styles.orderTopLabel}>
            <b>{t("product")}</b>
          </div>
          <div className={styles.orderNameCode}>
            <div
              className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
            >
              ({productData.code})
            </div>
            <div
              className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
            >
              {productData.name}
            </div>
          </div>
        </div>

        {/*
        {productParams.length > 0 && (
          <div className={styles.orderNameCodeContainer}>
            <div className={styles.orderTopLabel}>
              <b>{t("parameterizedvariables")}</b>
            </div>
            <div>
              {productParams.map((paramObj, idxParamObj) => (
                <span key={"rop_" + index + "_ppv_" + idxParamObj}>
                  <b> {paramObj.paramName}: </b>
                  {paramObj.paramVal}
                </span>
              ))}
            </div>
          </div>
        )}
        */}

        {/*<div className={styles.progressBarContainer}>
          <TaskProgressBar
            width={"325px"}
            tasksDone={getFinishedReceivingOrderProductQuantity(obj.Id, obj)}
            totalTasks={getReceivingOrderProductTotalQuantity(
              obj.Quantity,
              obj
            )}
            totalTasksNumberLabel={getTaskUomLabel(obj)}
          />
        </div>*/}
      <div className={styles.addingQtyBigger}>
        <div>
          +{obj.Quantity} {uomLabel}
        </div>
      </div>
      </IonCardHeader>
    );
  }

  const getReceivingOrderProductElement = (obj:any, index:any) => {
    let productData = JSON.parse(obj.productData);

    obj = { ...obj };
    let productParams = [];

    if (obj.receivingOrderProductParameterizedVariablesValueData) {
      obj.receivingOrderProductParameterizedVariablesValueData = JSON.parse(
        obj.receivingOrderProductParameterizedVariablesValueData
      );
      obj.parameterizedVariablesData = JSON.parse(
        obj.parameterizedVariablesData
      );
      obj.productParameterizedVariablesData = JSON.parse(
        obj.productParameterizedVariablesData
      );
      for (
        let i = 0;
        i < obj.receivingOrderProductParameterizedVariablesValueData.length;
        i++
      ) {
        let varValue =
          obj.receivingOrderProductParameterizedVariablesValueData[i].Value;
        if (!varValue) {
          varValue = "";
        }
        let idProductParameterizedVariable =
          obj.receivingOrderProductParameterizedVariablesValueData[i]
            .Id_Product_ParameterizedVariable;

        for (let j = 0; j < obj.productParameterizedVariablesData.length; j++) {
          if (
            obj.productParameterizedVariablesData[j]
              .Id_Product_ParameterizedVariable ===
            idProductParameterizedVariable
          ) {
            let idParameterizedVariable =
              obj.productParameterizedVariablesData[j].Id_ParameterizedVariable;

            for (let k = 0; k < obj.parameterizedVariablesData.length; k++) {
              if (
                obj.parameterizedVariablesData[k].Id_ParameterizedVariable ==
                idParameterizedVariable
              ) {
                productParams.push({
                  paramCode: obj.parameterizedVariablesData[k].code,
                  paramName: obj.parameterizedVariablesData[k].name,
                  paramVal: varValue,
                });
                break;
              }
            }
            break;
          }
        }
      }
    }

    return (
      <IonCardHeader
        className={styles.elemElement}
        onClick={() => {
          let productData = JSON.parse(obj.productData);
          let passData:any = {
            Id_ShippingOrder_Product: obj.Id,
            Id_Product: productData.Id_Product,
            name: productData.name,
            code: productData.code,
            qtyValue: 0,
            uomLabel: null,
            Id_UoM_Selected: null,
            uomConversionsData: obj?.uomConversionsData,
          };

          let qtyValue = getQtyReceivingProductByIdReceivingProduct(obj.Id);
          passData.qtyValue = qtyValue;

          if (obj?.Id_UoM_Selected) {
            //has default uom selected
            passData.uomLabel = obj?.temp_UoM_SelectedLabel;
            passData.Id_UoM_Selected = obj?.Id_UoM_Selected;
            //convert back to base
            passData.qtyValue = UomService().convertQuantity(
              qtyValue,
              -1,
              obj.Id_UoM_Selected,
              obj.uomConversionsData
            );
          } else {
            //no uom was selected on the shipping, so we use the label of the base uom
            passData.uomLabel = obj.temp_UoM_BaseLabel;
          }
          setPopupQuantityProductData(passData);
          setIsPopupDefineProductQuantityOpen(true);
        }}
      >
        <div className={styles.elemBackgroundContainer}>
          <HighlightAltIcon />
        </div>
        <div className={styles.orderNameCodeContainer}>
          <div className={styles.orderTopLabel}>
            <b>{t("product")}</b>
          </div>
          <div className={styles.orderNameCode}>
            <div
              className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
            >
              ({productData.code})
            </div>
            <div
              className={` ${styles.orderLabel} ${styles.singleLineOverFlowElipsis}`}
            >
              {productData.name}
            </div>
          </div>
        </div>

        {productParams.length > 0 && (
          <div className={styles.orderNameCodeContainer}>
            <div className={styles.orderTopLabel}>
              <b>{t("parameterizedvariables")}</b>
            </div>
            <div>
              {productParams.map((paramObj, idxParamObj) => (
                <span key={"rop_" + index + "_ppv_" + idxParamObj}>
                  <b> {paramObj.paramName}: </b>
                  {paramObj.paramVal}
                </span>
              ))}
            </div>
          </div>
        )}

        <div className={styles.progressBarContainer}>
          <TaskProgressBar
            width={"325px"}
            tasksDone={getFinishedReceivingOrderProductQuantity(obj.Id, obj)}
            totalTasks={getReceivingOrderProductTotalQuantity(
              obj.Quantity,
              obj
            )}
            totalTasksNumberLabel={getTaskUomLabel(obj)}
          />
        </div>
        {getAddingQtyElem(obj.Id, obj)}
      </IonCardHeader>
    );
  };

  const isMatchingAll = () => {
    if (loadedObjsRef?.current === null) {
      return false;
    }

    for (let i = 0; i < loadedObjsRef.current.length; i++) {
      let matched = false;
      for (let j = 0; j < receivingProducts.length; j++) {
        if (
          loadedObjsRef.current[i].Id ===
          receivingProducts[j].Id_ShippingOrder_Product
        ) {
          let totalQty = loadedObjsRef.current[i].Quantity;
          if (receivingProducts[j].quantity >= totalQty) {
            matched = true;
            break;
          }
        }
      }

      if (!matched) {
        return false;
      }
    }

    return true;
  };
  const getReceivingLocationElement = () => {
    if (!receivingLocation?.Id_WarehouseLocation) {
      return (
        <>
          <b>{t("location")}:</b> {t("shopfloor")}
        </>
      );
    }

    return (
      <div>
        <div className={styles.locDiv}>
          <div>
            <b>{t("warehouselocation")}:</b>
          </div>
          <div>{receivingLocation?.temp_warehouseLocationLabel}</div>
        </div>

        {!receivingLocation?.temp_warehouseLabel ? (
          <div className={styles.incompleteDefinedStockLocation}>
            {t("no.warehouse.defined")}.
          </div>
        ) : (
          <>
            <div className={styles.locDiv}>
              <div>
                <b>{t("warehouse")}:</b>
              </div>
              <div>{receivingLocation?.temp_warehouseLabel}</div>
            </div>
          </>
        )}

        {!receivingLocation?.temp_lotLabel ? (
          <div className={styles.incompleteDefinedStockLocation}>
            {t("no.lot.defined")}.
          </div>
        ) : (
          <>
            <div className={styles.locDiv}>
              <div>
                <b>{t("lot")}:</b>
              </div>
              <div>{receivingLocation?.temp_lotLabel}</div>
            </div>
          </>
        )}
      </div>
    );
  };


  const callGoBack = () => {
    let buyOrderData = infoOrderData?.buyOrderData;
    if(buyOrderData){
      buyOrderData = JSON.parse(buyOrderData);
      if (buyOrderData?.Id_BuyOrder) {
        props.history.push({
          pathname: "/feature/receivings/returned-material",
          state: {
            applyFilters: [
              {
                filterKey: "Id_BuyOrder",
                Id_BuyOrder: buyOrderData?.Id_BuyOrder,
                orderCode: buyOrderData.code,
                orderName: buyOrderData.name,
              },
            ],
          },
        });
        return;
      }
     
    }

     props.history.push("/feature/receivings/returned-material");
    
  };
  const loadMore = useCallback(
    (passedFilters = null) => {
      let currentPage = allowedInfiniteScrollLoadPageRef.current;

      let extraFiltersApply = {};
      if (passedFilters) {
        extraFiltersApply = getFormattedFiltersForRequest(passedFilters);
      } else {
        extraFiltersApply = getFormattedFiltersForRequest(filtersApplied);
      }

      receivingOrderProductsController()
        .getReturnedMaterialSubcontractFromShippingOrder({
          page: currentPage,
          size: sizePage,
          getDefaultReceivingLocation: true,
          openOnly: true,
          Id_ShippingOrder:shippingOrderId,
          ...receivingOrdersSearchFilters.current,
          ...extraFiltersApply,
        })
        .then((resp) => {
          syncSettings.check(resp);
          let posData = resp.data.response.data.return;
          let posTotalSize = resp.data.response.data.totalSize;
          let posExtraData = resp.data.response.data.extra;

          if (currentPage === 0) {
            //first load only
            //loads the extraData that loads in the backend only when the page = 0
            //this is done to avoid double requests just to gather extra unrelated data

            if (posExtraData?.shippingOrderData) {
         
              setInfoOrderData(posExtraData.shippingOrderData);
            }

            
            if (posExtraData?.receivingOrderProducts_received) {
              setReceivingOrderProductsReceivedData(
                posExtraData?.receivingOrderProducts_received
              );
            }

            if (posExtraData?.defaultReceivingLocation) {
              let useShopfloor =
                posExtraData.defaultReceivingLocation.useShopfloor === 1
                  ? true
                  : false;
              if (useShopfloor) {
                setReceivingLocation({});
              } else if (
                !posExtraData?.defaultReceivingLocation?.Id_WarehouseLocation ||
                posExtraData.defaultReceivingLocation.Id_WarehouseLocation ===
                  null
              ) {
                setReceivingLocation(null);
              } else {
                let newReceivingLocation:any = {};

                let idWarehouseLocation =
                  posExtraData?.defaultReceivingLocation?.Id_WarehouseLocation;
                let warehouseLocationLabel =
                  posExtraData?.defaultReceivingLocation
                    ?.warehouseLocationLabel;
                let idWarehouse =
                  posExtraData?.defaultReceivingLocation?.Id_Warehouse;
                let warehouseLabel =
                  posExtraData?.defaultReceivingLocation?.warehouseLabel;

                let idLot = posExtraData?.defaultReceivingLocation?.Id_Lot;
                let lotLabel = posExtraData?.defaultReceivingLocation?.lotLabel;

                let defaultNoLotObj =
                  posExtraData?.defaultReceivingLocation?.defaultNoLotObj;

                newReceivingLocation["Id_WarehouseLocation"] =
                  idWarehouseLocation;
                newReceivingLocation["temp_warehouseLocationLabel"] =
                  warehouseLocationLabel;

                if (idWarehouse) {
                  newReceivingLocation["Id_Warehouse"] = idWarehouse;
                  newReceivingLocation["temp_warehouseLabel"] = warehouseLabel;
                }

                if (idLot) {
                  newReceivingLocation["Id_Lot"] = idLot;
                  newReceivingLocation["temp_lotLabel"] = lotLabel;
                }

                if (defaultNoLotObj) {
                  newReceivingLocation["defaultNoLotObj"] = defaultNoLotObj;

                  if (!idLot && setDefaultNoLotAsLotOnLoad) {
                    newReceivingLocation["Id_Lot"] = defaultNoLotObj.Id_Lot;
                    newReceivingLocation["temp_lotLabel"] =
                      "(" + defaultNoLotObj.code + ") " + defaultNoLotObj.name;
                  }
                }

                setReceivingLocation(newReceivingLocation);
              }
            }
          }

          let newArrayLoadedObjs = [];

          if (loadedObjsRef.current !== null) {
            newArrayLoadedObjs = [...loadedObjsRef.current, ...posData];
          } else {
            newArrayLoadedObjs = posData;
          }

          loadedObjsRef.current = newArrayLoadedObjs;

          let hasMoreElements =
            newArrayLoadedObjs.length < posTotalSize && posData.length !== 0;
          if (hasMoreElements) {
            allowedInfiniteScrollLoadPageRef.current =
              allowedInfiniteScrollLoadPageRef.current + 1;
            infiniteScrollEnabledRef.current = true;
          } else {
            infiniteScrollEnabledRef.current = false;
          }
          if (currentPage === 0) {
            setLoadingPage(false);
          }
          //setLoadedObjs(loadedObjsRef.current);
          setIsLoadingMore(false);
          setIndexReRender(indexReRender + 1);
        })
        .catch((resp) => {
          console.log("THE ERROR: ", resp);
       
          if(resp?.request.status === 404){
            setNotFound(true);
            setLoadingPage(false);
            return;
          }
          if (resp["error"] && resp["error"]["error_type"] == "offline_fail") {
            if (failedToLoadPosError) {
              setFailedToLoadPosError(false);
            }
            setRequiresNetworkLoadError(true);
          } else {
            if (requiresNetworkLoadError) {
              setRequiresNetworkLoadError(false);
            }
            setFailedToLoadPosError(true);
          }
          console.log("FAILED::", resp);

          setLoadingPage(false);
        });
    },
    [
      setDefaultNoLotAsLotOnLoad,
      shippingOrderId,
      failedToLoadPosError,
      indexReRender,
      requiresNetworkLoadError,
      filtersApplied,
      syncSettings
    ]
  );
  const restartSearch = useCallback(() => {
    allowedInfiniteScrollLoadPageRef.current = 0;
    loadedObjsRef.current = null;

    loadMore();
  }, [loadMore, loadedObjsRef, allowedInfiniteScrollLoadPageRef]);
  useEffect(() => {
    if (!didMount) {
      let loadingFilters = props?.location?.state?.applyFilters;

      if (loadingFilters) {
        setFiltersApplied(loadingFilters);
      } else {
        loadingFilters = null;
      }

      window.history.replaceState({}, document.title); //clear history state

      loadMore(loadingFilters);
      setDidMount(true);
    }
  }, [
    props?.location?.state?.applyFilters,
    didMount,
    failedToLoadPosError,
    requiresNetworkLoadError,
    sessionState,
    loadMore,
  ]);

  useEffect(() => {
    if (previousDidMount === didMount && didMount) {
      if (previousFiltersApplied !== filtersApplied) {
        console.log("filters were changed");
        restartSearch();
      }
    }
  }, [
    filtersApplied,
    previousDidMount,
    didMount,
    previousFiltersApplied,
    restartSearch,
  ]);

  if (!didMount) {
    return <></>;
  }

  if (!shippingOrderId) {
    return (
      <InAppTemplate>
        <CustomArrowBack
          onClick={() => {
            callGoBack();
          }}
        />
        <div className={styles.pageTitle}>{t("returnedmaterial.received")}</div>
      </InAppTemplate>
    );
  }

  return (
    <InAppTemplate>
      {addFinishedProductPopup && (
        <>
          <AddFinishedProductPopup
            openFrom="receivings"
            onConfirmAddProduct={(addedStockMovementProduct:any) => {
          

         
              addOrMergeNewProduct(addedStockMovementProduct);
              setAddFinishedProductPopup(null);
            }}
            onCancel={() => {
              setAddFinishedProductPopup(null);
            }}
          />
        </>
      )}
      {isPopupReceivingLocationOpen && (
        <DefineStockLocationPopup
          allowLotCreation={true}
          subtitle={t("receivings.define.receiving.to")}
          currentData={receivingLocation}
          title={t("receivinglocation")}
          onClosePopup={() => {
            setIsPopupReceivingLocationOpen(false);
          }}
          onSuccessConfirm={(result:any) => {
            if (!result?.Id_Lot && result?.defaultNoLotObj) {
              result.Id_Lot = result.defaultNoLotObj.Id_Lot;
              result.temp_lotLabel =
                "(" +
                result.defaultNoLotObj.code +
                ") " +
                result.defaultNoLotObj.name;
            }

            setReceivingLocation(result);
            setIsPopupReceivingLocationOpen(false);
            console.log("success", result);
          }}
        />
      )}


      {popupAddedProductDefineProductQuantityOpen && (
        <DefineProductQuantityPopup
        deprecatedModePopup={true}
          productData={popupAddedProductDefineProductQuantityOpen}
          title={t('receiving.qty')}
          onClosePopup={() => {
            setPopupAddedProductDefineProductQuantityOpen(null);
          }}
          onSuccessConfirm={(res:any) => {
         
            let quantityResult = res.quantityResult;
            let newAddedProducts = [...addedProducts];
            if (quantityResult === 0) {
              //remove added product
              newAddedProducts.splice(popupAddedProductDefineProductQuantityOpen.indexEdittingAddedProduct,1);
            } else {
              /*
              if (res?.newProductData?.Id_UoM_Selected !== -1) {
                //convert qty received back to base uom
                quantityResult = UomService().convertQuantity(
                  quantityResult,
                  res.newProductData.Id_UoM_Selected,
                  -1,
                  res.newProductData.uomConversionsData
                );
              }*/
              //updateQtyReceivingProduct(idShippingProduct, quantityResult);
              //todo handle uom
              
              newAddedProducts[popupAddedProductDefineProductQuantityOpen.indexEdittingAddedProduct].Quantity = quantityResult;
            }

            setAddedProducts(newAddedProducts);

            setPopupAddedProductDefineProductQuantityOpen(null);
          }}
        />
      )}  

      {isPopupDefineProductQuantityOpen && (
        <DefineProductQuantityPopup
        deprecatedModePopup={true}
          productData={popupQuantityProductData}
          title={t('receiving.qty')}
          onClosePopup={() => {
            setIsPopupDefineProductQuantityOpen(false);
            setPopupQuantityProductData(null); //so that when called again, it doesnt render hxfkeyboard before loading the data
          }}
          onSuccessConfirm={(res:any) => {
            let idShippingProduct =
              popupQuantityProductData.Id_ShippingOrder_Product;

            let quantityResult = res.quantityResult;

            if (quantityResult === 0) {
              removeQtyReceivingProduct(idShippingProduct);
            } else {
              if (res?.newProductData?.Id_UoM_Selected !== -1) {
                //convert qty received back to base uom
                quantityResult = UomService().convertQuantity(
                  quantityResult,
                  res.newProductData.Id_UoM_Selected,
                  -1,
                  res.newProductData.uomConversionsData
                );
              }
              updateQtyReceivingProduct(idShippingProduct, quantityResult);
            }

            setIsPopupDefineProductQuantityOpen(false);
            setPopupQuantityProductData(null); //so that when called again, it doesnt render hxfkeyboard before loading the data
          }}
        />
      )}

      <div className={styles.topPageTitleBtnsContainer}>
        <div className={styles.arrowSpaceFiller}>
          <CustomArrowBack
            onClick={() => {
              callGoBack();
            }}
          />
        </div>
        <div className={styles.pageTitle}>{t("returnedmaterial.received")}</div>
        <div className={styles.cornerBtnsContainer}>
          <CornerButton
            onClick={() => {
              present({
                cssClass: "my-css",
                header: t("receivingorder.leave"),
                message: t("receivingorder.exit.confirm"),
                buttons: [
                  t("cancel"),
                  {
                    text: t("yes"),
                    handler: (d) => {
                      callGoBack();
                    },
                  },
                ],
                onDidDismiss: (e) => {},
              });
            }}
            btnType={"danger"}
            label={t("cancel")}
            iconPassed={closeCircle}
          />
        </div>
      </div>

      {loadingPage ? (
        <>
          <LoadingSpinnerImg />
        </>
      ) : (
        <>

      {loadedSuccessfully() && (
          <div className={styles.infoAndSelection}>
            {infoOrderData?.code && (
              <div className={styles.orderInfoContainer}>
                <div className={styles.orderInfo}>
                  <div className={styles.orderInfoContent}>
                    <div>
                      <div>
                        <b>{t("associated.shippingorder")}:</b>
                      </div>
                      <div>
                        ({infoOrderData.code}) {infoOrderData.name}
                      </div>
                    </div>
              
                    <div>
                      <div>
                        <b>{t("date.due")}</b>
                      </div>
                      <div>
                        {infoOrderData?.dueDate
                          ? DatesConversionService().formattedDate(
                              infoOrderData.dueDate
                            )
                          : " " + t("date.any") +" "}
                      </div>
                    </div>


                    {infoOrderData?.supplierData?.Id && (
                      <div>
                        <div>
                          <b>{t("supplier")}:</b>
                        </div>
                        <div>
                          {infoOrderData?.supplierData?.name}
                        </div>
                      </div>
                    )}


                  </div>
                </div>
              </div>
            )}

            <div className={styles.receivingLocationContainer}>
              <div className={styles.receivingLocation}>
                <div className={styles.receivingLocationContent}>
                  <div className={styles.rlocTitle}>{t("receivinglocation")}</div>
                  {receivingLocation !== null ? (
                    <>
                      <div>{getReceivingLocationElement()}</div>
                    </>
                  ) : (
                    <div>{t("receiving.location.define")}</div>
                  )}
                </div>

                <IonButton
                  disabled={false}
                  className={styles.modifyLocationBtn}
                  onClick={() => {
                    setIsPopupReceivingLocationOpen(true);
                  }}
                >
                  <IonIcon slot="start" size="large" icon={albumsOutline} />
                   {t("location.modify")}
                </IonButton>
              </div>
            </div>
          </div>
          )}    
          {/*<div className={styles.settingsContainer}>
              <div className={styles.customSearchBarContainer}>
              <CustomSearchBar 
              value={searchbarInput}
              onResetTextClick={() => {
                initiateFilterCode("");
              }}
              onChange={(evt) => {
                initiateFilterCode(evt.target.value);
                

              }}
              iconElement={<div className={styles.searchIconStyles}><IonIcon icon={searchCircleOutline}/></div>} placeholder={"Search Product"}/>
              </div>

              </div>*/}
          {filtersApplied.length > 0 && (
            <div className={styles.appliedFiltersContainer}>
              {filtersApplied.map((obj, index) => (
                <div key={"cafc_" + index}>
                  {getProperFilterChip(obj, index)}
                </div>
              ))}
            </div>
          )}

          {loadedSuccessfully() && (
          <div className={styles.receivingOrdersContainer}>
          <div
            id="receivingOrdersSelectionDiv"
            key={"rerender_ref_selection_" + indexReRender}
            className={styles.receivingOrdersSelection}
            ref={posContainerRef}
          >

            <IonCardHeader
              className={`${styles.elemElement} ${styles.receiveAllElem} ${
                isMatchingAll() ? styles.receiveAllMatched : ""
              }`}
              onClick={() => {
                setAddFinishedProductPopup(true);
              }}
            >
              <div className={styles.elemBackgroundContainer}>
                <AddRoadIcon />
              </div>

              <div className={styles.receiveAllTitle}>{t("add.product")}</div>
            </IonCardHeader>
            {/*
            <IonCardHeader
              className={`${styles.elemElement} ${styles.receiveAllElem} ${
                isMatchingAll() ? styles.receiveAllMatched : ""
              }`}
              onClick={() => {
                let newReceivingProducts = [];
                for (let i = 0; i < loadedObjsRef.current.length; i++) {
                  let totalQty = loadedObjsRef.current[i].Quantity;
                  let idShippingProduct = loadedObjsRef.current[i].Id;

                  let currentAddedQty =
                    getQtyReceivingProductByIdReceivingProduct(
                      idShippingProduct
                    );
                  let currentReceivedQty:any =
                    getFinishedReceivingOrderProductQuantity(
                      idShippingProduct,null
                    );
                  let totalAddedAndReceivedQty =
                    currentAddedQty + currentReceivedQty;
                  let newAddingQtyVal = 0;
                  if (totalAddedAndReceivedQty < totalQty) {
                    newAddingQtyVal = totalQty - currentReceivedQty;
                  } else {
                    newAddingQtyVal = currentAddedQty;
                  }

                  newReceivingProducts.push({
                    Id_ShippingOrder_Product: idShippingProduct,
                    quantity: newAddingQtyVal,
                  });
                }

                setReceivingProducts(newReceivingProducts);
              }}
            >
              <div className={styles.elemBackgroundContainer}>
                <AddRoadIcon />
              </div>

              <div className={styles.receiveAllTitle}>{t("receive.all")}</div>
            </IonCardHeader>
              */
            }
            {addedProducts !== null ? (
              <>
                {addedProducts.map((obj:any, index:any) => (
                  <div
                    key={"ro_" + index}
                    className={styles.receivingOrderElement}
                  >
                   {getAddingProductElement(obj,index)}
                  </div>
                ))}{" "}
              </>
            ) : (
              <></>
            )}
            {loadedSuccessfully() && loadedObjsRef.current !== null ? (
              <>
                {loadedObjsRef.current.map((obj:any, index:any) => (
                  <div
                    key={"ro_" + index}
                    className={styles.receivingOrderElement}
                  >
                    {getReceivingOrderProductElement(obj, index)}
                  </div>
                ))}{" "}
              </>
            ) : (
              <></>
            )}

            {isLoadingMore && (
              <IonCardHeader
                className={styles.elemElement}
                onClick={() => {}}
              >
                <div className={styles.elemBackgroundContainer}>
                  <HighlightAltIcon />
                </div>
                <IonCardSubtitle>
                  <div className={styles.elemElementTitle}>Loading...</div>
                </IonCardSubtitle>
                <IonCardTitle className={styles.elemElementDescription}>
                  <IonSpinner />
                </IonCardTitle>
              </IonCardHeader>
            )}

            <HxfInfiniteScroll
              containerElementRef={posContainerRef}
              requiresContainerHavingScrollbar={true}
              onLoadMore={() => {
                if (infiniteScrollEnabledRef.current) {
                  setIsLoadingMore(true);
                  loadMore();
                }
              }}
              allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
            />
          </div>
        </div>
          )}

        {notFound ? (
            <div className={styles.errorArea}>
                  <CustomErrorNotice
                title={t("order.not.found")}
                description={t("failed.load")}
                secondDescription={t("order.not.found.desc")}
                extraContent={
                  <>
                    <IonButton
                      onClick={() => {
                        props.history.push('/feature/receivings/returned-material');
                      }}
                    >
                      {t("back")}
                    </IonButton>
                  </>
                }
              />
            </div>
          ) : (
            <></>
          )}

          {failedToLoadPosError ? (
            <div className={styles.errorArea}>
              <CustomUnavailableErrorNotice />
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {requiresNetworkLoadError ? (
        <div className={styles.errorArea}>
          <ErrorNoticeRequiresNetworkLoadObject />
        </div>
      ) : (
        <></>
      )}

      <div className={styles.confirmationBottomContainer}>
        <div className={styles.confirmationBottomContent}>
          <div className={styles.lineBreaker}></div>

          <div className={styles.centeredElem}>
            <IonButton
              disabled={failedToLoadPosError}
              className={styles.confirmButton}
              onClick={() => {
                if (receivingProducts.length === 0 && addedProducts.length === 0) {
                  present({
                    buttons: [
                      {
                        text: "hide",
                        handler: () => {
                          console.log("called dismiss");
                        },
                      },
                    ],
                    message: t("not.added.any.products"),
                    onDidDismiss: () => console.log("dismissed"),
                    onWillDismiss: () => console.log("will dismiss"),
                  });
                  return;
                }

                let properLocationDefined = false;
                if (
                  (receivingLocation !== null &&
                    receivingLocation?.Id_WarehouseLocation &&
                    receivingLocation?.Id_Warehouse &&
                    receivingLocation?.Id_Lot) ||
                  (receivingLocation !== null &&
                    !receivingLocation?.Id_WarehouseLocation &&
                    !receivingLocation?.Id_Warehouse &&
                    !receivingLocation?.Id_Lot)
                ) {
                  properLocationDefined = true;
                }

                if (!properLocationDefined) {
                  /*present(
                    {
                        buttons: [{ text: 'hide', handler: () => { console.log("called dismiss"); } }],
                        message: 'Please define a location to receive stock',
                        onDidDismiss: () => console.log('dismissed'),
                        onWillDismiss: () => console.log('will dismiss')
                    }
                  );   */
                  setIsPopupReceivingLocationOpen(true);
                  return;
                }

                let preparedAddedProducts = [...addedProducts];
                let preparedReceivingProducts = [...receivingProducts];
                let idProductionOrder = infoOrderData.Id_ProductionOrder;
                let idSupplier = infoOrderData.supplierData.Id;
                let idShippingOrder = infoOrderData.Id;


                for (let i = 0; i < preparedAddedProducts.length; i++) {
                  let idWarehouseLocation =
                    receivingLocation?.Id_WarehouseLocation
                      ? receivingLocation.Id_WarehouseLocation
                      : null;
                  let idWarehouse = receivingLocation?.Id_Warehouse
                    ? receivingLocation.Id_Warehouse
                    : null;
                  let idLot = receivingLocation?.Id_Lot
                    ? receivingLocation.Id_Lot
                    : null;

                  preparedAddedProducts[i]["Id_WarehouseLocation"] =
                    idWarehouseLocation;
                    preparedAddedProducts[i]["Id_Warehouse"] = idWarehouse;
                    preparedAddedProducts[i]["Id_Lot"] = idLot;

                  if (idLot === "new") {
                    preparedAddedProducts[i]["newLotNameCode"] =
                      receivingLocation.temp_lotLabel;
                  }
                }

                for (let i = 0; i < preparedReceivingProducts.length; i++) {
                  let idWarehouseLocation =
                    receivingLocation?.Id_WarehouseLocation
                      ? receivingLocation.Id_WarehouseLocation
                      : null;
                  let idWarehouse = receivingLocation?.Id_Warehouse
                    ? receivingLocation.Id_Warehouse
                    : null;
                  let idLot = receivingLocation?.Id_Lot
                    ? receivingLocation.Id_Lot
                    : null;

                  preparedReceivingProducts[i]["Id_WarehouseLocation"] =
                    idWarehouseLocation;
                  preparedReceivingProducts[i]["Id_Warehouse"] = idWarehouse;
                  preparedReceivingProducts[i]["Id_Lot"] = idLot;

                  if (idLot === "new") {
                    preparedReceivingProducts[i]["newLotNameCode"] =
                      receivingLocation.temp_lotLabel;
                  }
                }
                receivingOrderProductsController()
                  .returnShippingOrderProducts({Id_ShippingOrder:idShippingOrder, Id_Supplier:idSupplier, Id_ProductionOrder:idProductionOrder},preparedReceivingProducts,preparedAddedProducts)
                  .then((res) => {
                    if(res?.data?.code !== 200){
                      throw "unexpected error";
                    }
                    feedbackService.notifyToast(t("received.successfully"), "success");
                    props.history.push("/");
                  })
                  .catch((res) => {

                    feedbackService.notifyToast(t("generic.critical.error"), "error");
                  });
              }}
            >
              {t("confirm")}
            </IonButton>
          </div>
        </div>
      </div>
    </InAppTemplate>
  );
}

export default ShippingReturnedReceivingOrderProducts;
