import { IonRippleEffect } from "@ionic/react";
import HxfKeyRippleEffectCustom from "../HxfDigitKeyboard/HxfKeyRippleEffectCustom/HxfKeyRippleEffectCustom";
import styles from "./CustomAppliedFilterChip.module.scss";
import { closeCircle, closeCircleOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

interface IAppliedFilterProps {
  filterTextElement: any;
  onRemoveFilter?: any;
  disableClearFilter?:boolean;
}
function CustomAppliedFilterChip(props: IAppliedFilterProps) {
  return (
    <HxfKeyRippleEffectCustom>
      <div className={styles.appliedFilterContainer}>
        <div className={styles.appliedFilterContent}>
          <div className={styles.appliedFilterText} style={props?.disableClearFilter ? {} : {paddingRight:32}}>
            {props?.filterTextElement ? props.filterTextElement : ""}
          </div>
          {!props.disableClearFilter && (
          <>
         
          <div className={styles.iconBackgroundSmall}></div>
          
          <div
            className={styles.iconContainer}
            onClick={() => {
              if (props?.onRemoveFilter) {
                props.onRemoveFilter();
              }
            }}
          >
            <div className={styles.iconElement}>
              <IonIcon
                icon={closeCircle}
                className={`${styles.iconElement} ${styles.closeIconElement}`}
              />
            </div>
          </div>
          </>
          )}

        </div>
      </div>
    </HxfKeyRippleEffectCustom>
  );
}

export default CustomAppliedFilterChip;
