import { t } from "i18next";
import HxfIonPopup from "../../ProductionUI/HxfIonPopup/HxfIonPopup";
import styles from "./SelectWorkersPopup.module.scss";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import SettingsCellIcon from '@mui/icons-material/SettingsCell';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Groups2Icon from '@mui/icons-material/Groups2';
import GroupIcon from '@mui/icons-material/Group';
import {
    IonButton,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonSpinner,
    useIonAlert,
    useIonToast
  } from "@ionic/react";
import CustomSearchBar from "../../CustomSearchBar/CustomSearchBar";
import { searchCircleOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import workerController from "../../../../barrel/controllers/workerController";
import useLocalStorageHandler from "../../../../barrel/hooks/useLocalStorageHandler";
import HxfOverlayLoading from "../../HxfOverlayLoading/HxfOverlayLoading";
import HxfInfiniteScroll from "../../HxfInfiniteScroll/HxfInfiniteScroll";
import { useGlobalState } from "../../../../GlobalCustomStateManagement/GlobalStateProvider";
interface IProps{
    onClose?:any;
    title?:any;
    assignedToId_ProductionOrder?:any;
    onReturn:any;
}
function SelectWorkersPopup(props:IProps){
    const {t} = useHxfTranslation();
    const feedbackService = useFeedbackService();
    const [searchbarInput, setSearchbarInput] = useState("");
    const timerStartSearching = useRef<any>(0);
    const [activeFilter, setActiveFilter] = useState<'ALL'|'ASSIGNED'>('ASSIGNED');
    const [hideAssignedTab, setHideAssignedTab] = useState(false);
    const workersSearchFilters = useRef<any>({activeFilter:activeFilter});
    const myLocalStorage = useLocalStorageHandler();
    const [didMount, setDidMount] = useState(false);
    const posContainerRef = useRef<any>(null);
    const infiniteScrollEnabledRef = useRef<any>(false);
    const allowedInfiniteScrollLoadPageRef = useRef<any>(0);
    const scrollableContainerDetect= useRef<any>(0);
    const { sessionState } = useGlobalState();
    

    const currentWorkersList = useRef<any>([]);
    const [rerenderWorkersListIndex, setRerenderWorkersListIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedAllAssigned, setSelectedAllAssigned] = useState(false);


    const buildDefaultMapSelectedWorkers = () => {
        let currentIdWorker = sessionState?.userSessionData?.Id_Worker;
        let currentWorkerName = sessionState?.userSessionData?.workerName;
        let defaultMap:any = {};
        defaultMap[currentIdWorker] = {
            Id:currentIdWorker,
            name:currentWorkerName
        };
        return defaultMap;
    }

    const [mapSelectedWorkers, setMapSelectedWorkers] = useState<any>(buildDefaultMapSelectedWorkers());

    const resetSearchFilters = () => {
        workersSearchFilters.current = {};
    }
    const resetSearchState = () => {
        
        currentWorkersList.current = [];
        allowedInfiniteScrollLoadPageRef.current = 0;
    }
    const restartSearch = () => {
        let currentPage = allowedInfiniteScrollLoadPageRef.current;
        let opts:any = {
            size:25,
            page:currentPage
        };

        if(workersSearchFilters?.current?.searchBarFilter && workersSearchFilters?.current?.searchBarFilter !== ""){
            opts.searchBarFilter = workersSearchFilters.current.searchBarFilter;
        }
        if(workersSearchFilters?.current?.activeFilter === "ASSIGNED" && props?.assignedToId_ProductionOrder){
            opts.assignedToId_ProductionOrder = props.assignedToId_ProductionOrder;
        }

        let workerCode = myLocalStorage.getWorkerIdentificationCode();
   
        
        //todo page handle
        setIsLoading(true);
        workerController().getListJson(opts,{workerCode:workerCode}).then((res:any) => {
            setIsLoading(false);
            
            let workersList = res?.data?.response?.data?.return;
            let totalSize = res?.data?.response?.data?.totalSize;
            let hasNoWorkerSpecificallyAssigned = res?.data?.response?.data?.hasNoWorkerSpecificallyAssigned;
            
            if(workersList === undefined || totalSize === undefined){
                throw "unexpected";
            }

            if(hasNoWorkerSpecificallyAssigned && !hideAssignedTab){
                setActiveFilter('ALL');
                setHideAssignedTab(true);
            }

            let newWorkersList:any = [...currentWorkersList.current, ...workersList];
            let hasMoreElements = newWorkersList.length < totalSize;
            if (hasMoreElements) {
                allowedInfiniteScrollLoadPageRef.current = allowedInfiniteScrollLoadPageRef.current + 1;
                infiniteScrollEnabledRef.current = true;
              } else {
                infiniteScrollEnabledRef.current = false;
              }
            
            currentWorkersList.current = newWorkersList;
            setRerenderWorkersListIndex(rerenderWorkersListIndex+1);
        }).catch((res) => {
            setIsLoading(false);
            feedbackService.notifyToast(t('error.unexpected'),"danger");
            props.onClose();
        });
    }
    const initiateFilterCode = (val: any) => {
        setSearchbarInput(val);
        clearTimeout(timerStartSearching.current); //stops spam requests
        timerStartSearching.current = setTimeout(
            () => {
            workersSearchFilters.current = {
                ...workersSearchFilters.current,
                searchBarFilter: val,
            };
                resetSearchState();
            
                restartSearch();
           
           
            },
            500,
            val
        );
    };

    useEffect(() => {
        if(!didMount){
            restartSearch();
            setDidMount(true);
        }
    },[didMount]);
    return (
        <HxfIonPopup refContentContainer={scrollableContainerDetect} title={props?.title} onClose={() => {
            props.onClose();
        }} content={(<>
        {isLoading && (
            <HxfOverlayLoading/>
        )}
        <div>
        
             <div className={styles.popupTitle}>{t('selected.participants')}</div>
        
            <div>
            {!hideAssignedTab && (<div className={styles.popupTitle}>{t('active.filter')}</div>)}
            <div className={styles.filteringOptionsContainer}>
                {!hideAssignedTab && (
                    <>
                        <div>
                            <IonButton
                                    style={{width:128}}
                                    color={activeFilter === "ASSIGNED" ? "tertiary" : "medium"}
                                    className={styles.closeWindowButton}
                                    onClick={() => {
                                        setActiveFilter("ASSIGNED");
                                        resetSearchState();
                                        workersSearchFilters.current = {
                                            activeFilter:'ASSIGNED'
                                        };
                                        restartSearch();
                                    }}
                                    >
                                    
                                    <Diversity3Icon/>
                                    {t('assigned')}
                            </IonButton>
                        </div>
                        <div>
                            <IonButton
                                    style={{width:128}}
                                    color={activeFilter === "ALL" ? "tertiary" : "medium"}
                                    className={styles.closeWindowButton}
                                    onClick={() => {
                                        setActiveFilter("ALL");
                                        resetSearchState();
                                        workersSearchFilters.current = {
                                            activeFilter:'ALL'
                                        };
                                        restartSearch();
                                    }}
                                    >
                                    
                                    <Groups2Icon/>
                                    {t('all')}
                            </IonButton>
                        </div>
                    </>
                )}


            </div>

            <div style={{margin:20}}>
                <CustomSearchBar
                  value={searchbarInput}
                  onResetTextClick={() => {
                    initiateFilterCode("");
                  }}
                  onChange={(evt:any) => {
                    initiateFilterCode(evt.target.value);
                  }}
                  iconElement={
                    <div className={styles.searchIconStyles}>
                      <IonIcon icon={searchCircleOutline} />
                    </div>
                  }
                  placeholder={t('search.worker')}
                />
            </div>

            <div className={styles.quickTools}>
                {activeFilter === "ASSIGNED" && (<>
                    <div className={styles.assignedLabel} onClick={() => {

                        if(selectedAllAssigned){
                            //unselect all
                            let newMapSelectedWorkers = JSON.parse(JSON.stringify(mapSelectedWorkers));
                            let selectedWorkerKeys = Object.keys(newMapSelectedWorkers);
                                
                            let currentLoadedWorkers = currentWorkersList.current;
                            if(currentLoadedWorkers){
                                for(let i = 0; i<currentLoadedWorkers.length; i++){

                                    if(newMapSelectedWorkers?.[currentLoadedWorkers[i].Id] && currentLoadedWorkers[i].Id !== sessionState?.userSessionData?.Id_Worker){
                                        delete newMapSelectedWorkers[currentLoadedWorkers[i].Id];
                                    }

                                }
                            }
                            setMapSelectedWorkers({...newMapSelectedWorkers});
                            setSelectedAllAssigned(false);
                            setRerenderWorkersListIndex(rerenderWorkersListIndex+1);
                        }else{
                            //select all
                            workerController().getWorkersAssignedToProductionOrder({},props.assignedToId_ProductionOrder).then((res) => {
                            
                                
                                if(res?.data?.assignedWorkers === undefined){
                                    throw "unexpected";
                                }
                                
                                let newMapSelectedWorkers = mapSelectedWorkers;
                                let assignedWorkers= res?.data?.assignedWorkers;
                                
                                if(assignedWorkers){
                                    for(let i = 0; i<assignedWorkers.length; i++){
                                        debugger;
                                        if(!newMapSelectedWorkers?.[assignedWorkers[i].Id]){
                                            newMapSelectedWorkers[assignedWorkers[i].Id] = assignedWorkers[i];
                                        }
                                    }
                                }
                                setMapSelectedWorkers(newMapSelectedWorkers);
                                setRerenderWorkersListIndex(rerenderWorkersListIndex+1);
                                setSelectedAllAssigned(true);
                                
                            }).catch(() => {
                                setSelectedAllAssigned(false);
                                feedbackService.notifyToast(t('error.unexpected'),"danger");
                            });
                        }

                    }}>{selectedAllAssigned ? t('unselect.all') :  t('select.all')}</div>
                    </>
                )}
           </div>

            <div className={styles.workersContainer} ref={posContainerRef} key={rerenderWorkersListIndex}>
                {currentWorkersList.current.map((objWorker:any, index:any) => (
                    <div className={`${styles.workerContainer} ${mapSelectedWorkers?.[objWorker.Id] ? styles.selectedWorker : ""} ${sessionState?.userSessionData?.Id_Worker === objWorker.Id ? styles.disabledWorkerBtn : ""}`} key={"worker_" + index} onClick={() => {
                        if(sessionState?.userSessionData?.Id_Worker === objWorker.Id){
                            return;
                        }
                        let newMapSelectedWorkers:any = {...mapSelectedWorkers};
                        if(newMapSelectedWorkers?.[objWorker.Id]){
                            delete newMapSelectedWorkers[objWorker.Id];
                        }else{
                            newMapSelectedWorkers[objWorker.Id] = objWorker;
                        }
                       
                        setMapSelectedWorkers(newMapSelectedWorkers);
                    }}>
                        <div>{objWorker.name}</div>
                        <div></div>
                    </div>
                ))}
                  <HxfInfiniteScroll
                    loadModeMode="SCROLL_POSITION"
                    loadModeScrollMinimumPercentage={80}
                    containerElementRef={scrollableContainerDetect}
                    requiresContainerHavingScrollbar={true}
                    onLoadMore={() => {
                        
                        if (infiniteScrollEnabledRef.current) {
                            
                            restartSearch();
                        }
                    }}
                    allowedLoadPageRef={allowedInfiniteScrollLoadPageRef}
                />
           
            </div>
        </div>
        </div>
        </>)} bottomConfirmationContainer={(<div className={styles.bottomContainer}>
            <div>
                <IonButton
                    disabled={Object.keys(mapSelectedWorkers).length === 0}
                    className={styles.confirmButton}
                    onClick={() => {
                        props.onReturn({mapSelectedWorkers:mapSelectedWorkers});
                    }}
                >
                        <div style={{margin:'30px',fontSize:'30px'}}>
                            {t('confirm')}
                        </div>
                    
                </IonButton>
            </div>
            <div className={`${styles.totalWorkersContainer} ${Object.keys(mapSelectedWorkers).length > 0 ? styles.workersSomeSelected : styles.workersNoneSelected } `}>
                <div><GroupIcon fontSize="large"/></div>
                <div className={`${styles.totalWorkersCount} `}>{Object.keys(mapSelectedWorkers).length}</div>
            </div>
        </div>)}/>
    )
}

export default SelectWorkersPopup;