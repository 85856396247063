import axios from "axios";
import useLocalStorageHandler from "../hooks/useLocalStorageHandler";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function productionOrdersController() {
  const localStorage = useLocalStorageHandler();
  const getActiveProductionOrders = (
    options: any = {
      page: 0,
      size: 10,
      openOnly: true,
      getAllWorkerOnGoingProductions: false,
      operationCodeFilter: false,
      getAllWorkerOnGoingMultiProductions: false,
      getViewRelevantFlags: false,
      includeOperationsAccessData:false,
      includeOperationsWorkToBeDone:false,
      includeDailyDistributionData:false,
      getWorkerTeamAccessibleProductOperations:false,
      orderBy:null,
      orderDir:null
    },
    cancelToken: any
  ) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();

    if (!options?.getAllWorkerOnGoingProductions) {
      options["getAllWorkerOnGoingProductions"] = false;
    }
    if (!options?.getProductionOrdersSimultaneousOperations) {
      options["getProductionOrdersSimultaneousOperations"] = false;
    }

    if (!options?.getAllWorkerOnGoingMultiProductions) {
      options["getAllWorkerOnGoingMultiProductions"] = false;
    }
    if (!options?.getViewRelevantFlags) {
      options["getViewRelevantFlags"] = false;
    }

    if(options?.orderByWorkerPrefs){
      options["orderByWorkerPrefs"] = true;
    }
    if(options?.searchBarFilter){
      options["searchBarFilter"] = options.searchBarFilter;
    }
    if(options?.includePopsOperationsProgressData){
      options["includePopsOperationsProgressData"] = options.includePopsOperationsProgressData;
    }

    let requestUrl =
      "production-orders/listJson/?page=" +
      options["page"] +
      "&size=" +
      options["size"] +
      "&openOnly=" +
      options["openOnly"] +
      "&getAllWorkerOnGoingProductions=" +
      options["getAllWorkerOnGoingProductions"] +
      "&getProductionOrdersSimultaneousOperations=" +
      options["getProductionOrdersSimultaneousOperations"] +
      "&workerIdentificationCode=" +
      currentWorkerCode;

    if(options?.includeSubcontracts){
        requestUrl = requestUrl + "&includeSubcontracts=true";
    }
    if(options?.calculateMaterialStaging){
      requestUrl = requestUrl + "&calculateMaterialStaging=true";
  }
    
    if (options?.operationCodeFilter) {
      requestUrl = requestUrl + "&operationCode=" + options.operationCodeFilter;
    }

    if(options?.includeOperationsAccessData){
      requestUrl = requestUrl + "&includeOperationsAccessData=" + options["includeOperationsAccessData"];
    }

    if(options?.orderByWorkerPrefs){
      requestUrl = requestUrl + "&orderByWorkerPrefs=true";
    }
    if(options?.includeOperationsWorkToBeDone){
      requestUrl = requestUrl + "&includeOperationsWorkToBeDone=" + options["includeOperationsWorkToBeDone"];
    }
    
    if(options?.includeDailyDistributionData){
      requestUrl = requestUrl + "&includeDailyDistributionData=" + options["includeDailyDistributionData"];
    }

    if (options?.getAllWorkerOnGoingMultiProductions) {
      requestUrl = requestUrl + "&getAllWorkerOnGoingMultiProductions=true";
    }

    if (options?.getViewRelevantFlags) {
      requestUrl = requestUrl + "&getViewRelevantFlags=true";
    }

    if(options?.getWorkerTeamAccessibleProductOperations){
      requestUrl = requestUrl + "&getWorkerTeamAccessibleProductOperations=true";
    }

    if(options?.orderBy){
      requestUrl = requestUrl + "&orderBy=" + options.orderBy;
    }

    if(options?.orderDir){
      requestUrl = requestUrl + "&orderDir=" + options.orderDir;
    }

    if(options?.searchBarFilter){
      requestUrl = requestUrl + "&searchBarFilter=" + options.searchBarFilter;
    }

    if(options?.includePopsOperationsProgressData){
      requestUrl = requestUrl + "&includePopsOperationsProgressData=" + options.includePopsOperationsProgressData;
    }
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {},
      cancelToken
    );
  };

  const getProductionOrderById = (
    id: any,
    paramsData: any,
    cancelToken: any
  ) => {
    let currentWorkerCode = localStorage.getWorkerIdentificationCode();

    if (!paramsData) {
      paramsData = {};
    }
    paramsData["workerCode"] = currentWorkerCode;
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "production-order/get/" + id,
      paramsData,
      cancelToken
    );
  };

  const getCandidatesToPop = (workerCode: any, idPop: any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "product/get-candidates-to-pop/" +
        idPop,
      { workerCode: workerCode }
    );
  };

  const closeProductionOrder = (opts:any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() +
        "production-order/close",
      { ...opts }
    );
  }

  return {
    getCandidatesToPop,
    getProductionOrderById,
    getActiveProductionOrders,
    closeProductionOrder
  };
}

export default productionOrdersController;
