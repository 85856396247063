import { IonButton, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from "@ionic/react";
import { InputAdornment } from "@mui/material";
import { closeOutline, hammerOutline } from "ionicons/icons";
import { useState } from "react";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import SimpleContentPopup from "../../SimpleContentPopup/SimpleContentPopup";
import { dispatchCustomPopupsStore, useCustomPopupsStore } from "../CustomPopupsStore";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from "./CollabCancelPopup.module.scss";
import useProductionProcess from "../../../../../barrel/hooks/useProductionProcess";
import { useProductionOrderSelectionStore } from "../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";

interface IProps{
  collabAllowCancelSelfPr?:any;
  collabAllowCancelFullPr?:any;
  onCancelFull?:any;
  onCancelSelf?:any,
  onClose?:any;
}

function CollabCancelPopup(props:IProps){
    const {t} = useHxfTranslation();
    const popupsStore = useCustomPopupsStore();
    const allowSelfRecordCancellation = props?.collabAllowCancelSelfPr === 1;
    const allowFullRecordCancellation = props?.collabAllowCancelFullPr === 1;


    return (
    <>
        <SimpleContentPopup>
        <div>
        <div className={styles.container}>
          <div className={styles.barContainer}>
            <div></div>

            <div className={styles.title}>{t("production.record.cancelation")}</div>

            <IonButton
              color="danger"
              className={styles.closeWindowButton}
              onClick={() => {

                props.onClose();
              }}
            >
              <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
          </div>
          <div className={styles.content}>
            <div style={{display:'flex', justifyContent:'center',marginTop:30,marginBottom:30}}>
              <div>
                {allowFullRecordCancellation && (
                  <>
                                  <div className={styles.btnCancel}>
                      <IonButton
                        color="danger"
                        size="large"
                        onClick={() => {
             
                          props.onCancelFull();
                        }}
                    >
                       <span style={{margin:5}}><DeleteForeverIcon fontSize="large"/></span>
                        
                        {t('cancel.full.record')}
                
                    </IonButton>
                </div>
                  {allowSelfRecordCancellation && (
                  <div className={styles.orSeparate}>
                    {t('or')}
                  </div>
                  )}

                  </>
                )}

                {allowSelfRecordCancellation && (
                <div className={styles.btnCancel}>

                    <IonButton
                        size="large"
                        onClick={() => {

                          props.onCancelSelf();
                        }}
                    >
                        <span style={{margin:5}}><HighlightOffIcon fontSize="large"/></span>
                        {t('cancel.participation')}
                
                    </IonButton>
                </div>
                )}

                {!allowFullRecordCancellation && !allowSelfRecordCancellation && (
                  <div>{t('collab.cancellation.settings.not.configured')}</div>
                )}
              </div>


            </div>

          </div>
          
        </div>


  
      </div>
        </SimpleContentPopup>
    </>)
}

export default CollabCancelPopup;