import { IonItem, IonList, IonListHeader, useIonAlert, useIonPopover } from "@ionic/react";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ToolbarLangSwitcher from "../ToolbarLangSwitcher/ToolbarLangSwitcher";
import styles from "./ToolbarAccountPopover.module.scss";
import { useTranslation } from "react-i18next";
import { useGlobalState } from "../../../GlobalCustomStateManagement/GlobalStateProvider";
import MobileWrapperCommunicationService from "../../../barrel/services/mobileWrapperCommunicationService";
type Props = {
  onHide: any;
  onLogOff: any;
  onSwitchEntity: any;
  getSessionState: any;
};

const ToolbarAccountPopoverList: React.FC<{
  onHide: () => void;
  onLogOff: () => any;
  onSwitchEntity: () => any;
  getSessionState: () => any;
}> = ({ onHide, onLogOff, onSwitchEntity, getSessionState }: Props) => {
  const [present] = useIonAlert();
  const {t} = useHxfTranslation();
  const {i18n} = useTranslation();
  const [presentLangSwitch, dismissLangSwitch] = useIonPopover(ToolbarLangSwitcher, {
    onHide: () => dismissLangSwitch(),
    getSessionState: () => {
      return getSessionState();
    },
  });
  const getLoggedInPropername = () => {
    let objSess = getSessionState();
    return objSess.userSessionData.workerName;
  };

  const hideSignOffBtn = () => {
    let objSess = getSessionState();
    return objSess?.userSessionData?.hideSignOffWorkstation === 1;
  }

  const showAuthenticatedAs = () => {
    let objSess = getSessionState();
    return (
      objSess.userSessionData?.Id_Worker &&
      objSess.userSessionData.Id_Worker !== 0 
    );
  };


  return (
    <IonList>
      <IonListHeader>
          <div>
            {t('connected.to')} <b>{getSessionState().userSessionData?.entityName}</b> 
          </div>
          <IonItem
          lines="none"
          detail={false}
          button
          onClick={(e) => {
          
            presentLangSwitch({
                event: e.nativeEvent,
              });
            onHide();
          }}
        >
            <div className={styles.toolbarElement}>
                        <div
                          onClick={(e) => {
                  
                          }}
                          className={styles.toolbarLangSwitcher}
                        >
                          <div className={`${styles.toolbarLangSection}`}>
                            <div>
                              <div>
                              <img src={"/assets/images/flags/" + i18n.language + ".png"} alt="lang flag" />
                              
                              </div>
                            </div>
                          </div>
                          <div className={styles.langLabel}>{t(i18n.language)}</div>
                          
                          <KeyboardArrowDownIcon />
                        </div>
                      </div>
            </IonItem>
      </IonListHeader>
      {showAuthenticatedAs() ? (
        <IonListHeader>
          {t("authenticated.as")} {getLoggedInPropername()}
        </IonListHeader>
      ) : (
        <></>
      )}
  
      {MobileWrapperCommunicationService().isDeviceMobileWrapper() && getSessionState()?.userSessionData?.allowSwapWSandBO === 1 && (
          <IonItem
            lines="none"
            detail={false}
            button
            onClick={() => {
              MobileWrapperCommunicationService().notifySwapToBackoffice();
            }}
          >
            {t('access.backoffice')}
          </IonItem>
      )}

      {!hideSignOffBtn() && (
        <IonItem
          lines="none"
          detail={false}
          button
          onClick={() => {
            present({
              cssClass: "my-css",
              header: t("logout.confirmation"),
              message: t("sure.logoff"),
              buttons: ["Cancel", { text: "Yes", handler: (d) => onLogOff() }],
              onDidDismiss: (e) => {},
            });
            onHide();
          }}
        >
          {t('app.signout')}
        </IonItem>
      )}

    </IonList>
  );
};

export default ToolbarAccountPopoverList;
