import useHxfTranslation from "../../../../../../../../barrel/hooks/useHxfTranslation";
import SelectWorkersPopup from "../../../../../../../CustomElements/statePopups/selectWorkersPopup/SelectWorkersPopup";
import styles from "./PreActionSelectParticipants.module.scss";


interface IProps{
    onClose?:any;
    onFinish?:any;
    Id_ProductionOrder?:any;
    Id_ProductionOrder_Product?:any;
}
function PreActionSelectParticipants(props:IProps){
    const {t} = useHxfTranslation();

    return (<><SelectWorkersPopup onReturn={(returnObj:any) => {
        props.onFinish(returnObj);
    }} assignedToId_ProductionOrder={props?.Id_ProductionOrder} title={t('select.participants')} onClose={props.onClose} /></>)
}

export default PreActionSelectParticipants;