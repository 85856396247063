import { IonButton, IonCardHeader, IonIcon, useIonAlert } from "@ionic/react";
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { lockClosedOutline, searchCircleOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import productionOrdersController from "../../../../../barrel/controllers/productionOrdersController";
import ProductionOrdersDao from "../../../../../barrel/dataAccessObjects/ModelObjects/Objects/productionOrdersDao";
import { useCancelToken } from "../../../../../barrel/hooks/useCancelToken";
import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import usePunchclock from "../../../../../barrel/hooks/usePunchclock";
import useSyncSettings from "../../../../../barrel/hooks/useSyncSettings";
import AvailableWorkTodoService from "../../../../../barrel/services/availableWorkTodoService";
import { isEmptyObject } from "../../../../../barrel/utils/ObjectUtils";
import LoadingSpinnerImg from "../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { useGlobalState } from "../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import CustomArrowBack from "../../../../CustomElements/CustomArrowBack/CustomArrowBack";
import ProductionTopInfo from "../../../../CustomElements/ProductionUI/ProductionTopInfo/ProductionTopInfo";
import TaskProgressBar from "../../../../CustomElements/TaskProgressBar/TaskProgressBar";
import InAppTemplate from "../../../../InAppTemplate/InAppTemplate";
import {
  dispatchProductionOrderSelectionStore, useProductionOrderSelectionStore
} from "../../../ProductionOrders/Standard/store/productionOrderSelectionStore";
import styles from "./ProductionProductSelectionStandard.module.scss";
import CustomSearchBar from "../../../../CustomElements/CustomSearchBar/CustomSearchBar";
function ProductionProductSelectionStandard(props) {
  const punchclock = usePunchclock();
  const [didMount, setDidMount] = useState(false);
  const { cancelToken, isCancel } = useCancelToken();
  const {t} = useHxfTranslation();
  const syncSettings = useSyncSettings();
  const {sessionState}  = useGlobalState();
  const productionOrdersDao = ProductionOrdersDao();
  const [presentAlert] = useIonAlert();
  const productionOrderId = props.match.params.poId;
  const mylocalStorage = useLocalStorageHandler();
  const feedbackService = useFeedbackService();
  const [productionOrder, setProductionOrder] = useState({});
  const timerStartSearching = useRef(null);
  const [searchbarInput, setSearchbarInput] = useState("");

  const [searchFilterValue, setSearchFilterValue] = useState(null);
  const SHOW_BTN_CLOSE_PO = sessionState?.userSessionData?.workerPermissions?.wsAllowClosePo === 1; 

  const [loadingPage, setLoadingPage] = useState(true);

  const productionOrderSelection = useProductionOrderSelectionStore();

  const [showNotFound, setShowNotFound] = useState(false);

  const MACHINE_STATUS_STYLE = 2;

  const getProductOperationsDoneCompleteness = (obj) => {
    let operationsData = obj.operationsData;

    let operationsAccessData = productionOrder?.extra?.operationsAccessData;
    let workerTeamAcessibleProductOperations = productionOrder?.extra?.workerTeamAcessibleProductOperations;
    let operationsWorkDoneInfo = AvailableWorkTodoService().getWorkAvailableAndWorkDoneInfo(operationsData,operationsAccessData,workerTeamAcessibleProductOperations);    
    let totalTimesDone = 0;
    let totalTimesMustBeDone = 0;
    let totalTimesDoneIgnoredOverflow = 0;
    
    let shouldIncludeDailyDistributionData = sessionState?.userSessionData?.operationsDistribution === "DAILY_DISTRIBUTION";
 
    //
    if(shouldIncludeDailyDistributionData){

      totalTimesDone = operationsWorkDoneInfo.totalTodayTimesDone;
      totalTimesMustBeDone = operationsWorkDoneInfo.totalTodayTimesMustBeDone;
      totalTimesDoneIgnoredOverflow = operationsWorkDoneInfo.totalTodayTimesDoneIgnoredOverflow;
    }else{
      //default behaviour
      totalTimesDone = operationsWorkDoneInfo.totalTimesDone;
      totalTimesMustBeDone = operationsWorkDoneInfo.totalTimesMustBeDone;
      totalTimesDoneIgnoredOverflow = operationsWorkDoneInfo.totalTimesDoneIgnoredOverflow;
    
    }
    //

    return {totalTimesDone:totalTimesDone, totalTimesDoneIgnoredOverflow:totalTimesDoneIgnoredOverflow,totalTimesMustBeDone:totalTimesMustBeDone}
  };

  //previous implementation, do not remove as it might be needed if some client needs to see the "total products done" at this stage screen
  const getProducedProductCompleteness = (obj) => {
    
    let idProductionOrderProduct = obj.Id;
    let productsCompletePcts =
      productionOrder.productionOrder_product_completePct;
    let found = false;
    let producedQuantity = 0;
    let quantityRequired = 0;

    for (let i = 0; i < productsCompletePcts.length; i++) {
      if (
        productsCompletePcts[i]["Id_ProductionOrder_Product"] ===
        idProductionOrderProduct
      ) {
        found = true;
        producedQuantity = productsCompletePcts[i]["consideredProducedQty"];
        quantityRequired = productsCompletePcts[i]["quantityRequired"];
        break;
      }
    }
    if (!found) {
      return <></>;
    }



    return {producedQuantity:producedQuantity,quantityRequired:quantityRequired}
  };

  const isEnabledCloseOrder = () => {
    let completedPctReal = productionOrder?.productionOrder_completePctReal;
    if(completedPctReal){
      let minimumToAllow = sessionState?.userSessionData?.globalSettings?.featureExpander?.wsPoCloseBtnMinCompletenessPct;
      if(minimumToAllow){
        return parseFloat(completedPctReal) >= parseFloat(minimumToAllow);
      }
    }
    return true;
  }


  const getProductNameByObj = (obj) => {
    let productName = "(" + obj.code + ") " + obj.name;

    let n = 50;
    return productName.length > n
      ? productName.substr(0, n - 1) + "..."
      : productName;
  };

  const getProductParameterizedVariablesObj = (obj) => {
    let idPop = obj.Id;

    let showProductPPVVS = sessionState?.userSessionData?.flags?.flag_shopfloor_popSelShowPVVS;
    if(!showProductPPVVS){
      return (<></>)
    }
    
    let poppvvsObjArray = [];
    for (
      let i = 0;
      i <
      productionOrderSelection.productionOrder.productionOrderProducts.length;
      i++
    ) {
      if (
        idPop ===
        productionOrderSelection.productionOrder.productionOrderProducts[i].Id
      ) {
        let arrayProductParameterizedVariables = productionOrderSelection.productionOrder.productionOrderProducts[i]?.arrayProductParameterizedVariables;
        if(!arrayProductParameterizedVariables){
          return (<></>)
        }
        poppvvsObjArray= arrayProductParameterizedVariables;
        break;
      }
    }

    if (poppvvsObjArray.length === 0) {
      return <></>;
    }

    return (
      <>
        <div className={styles.ppvContainer}>
          <div>
            <b>{t('parameterizedvariables')}:</b>
          </div>
          <div className={styles.pvsContent}>
            {poppvvsObjArray.map((obj, index) => (
              <span key={"pproductselectionstd_pop_index_" + index}>
                {index !== 0 ? "," : ""} {obj.name}: {obj.Value}
              </span>
            ))}
          </div>
        </div>
      </>
    );
  };


  const getConsumptionsUI = (obj) => {
    //

    let showProductConsumptions = sessionState.userSessionData?.globalSettings?.featureExpander?.wsProductSelShowConsumptions === 1;

    if(showProductConsumptions && obj?.bomProducts &&  obj?.bomProducts?.length > 0){

      const showConsumptionsPVVS = false; 
   
      return (<div style={{color:'black',display:'flex',fontSize:13}}>
        <div style={{marginRight:3}}><b>{t('bom.short')}:</b></div>
        <div className={styles.bomProductsContainer}>
        {obj?.bomProducts?.map((bomProduct, indexBP) => (
          <div key={"bomproduct_" + indexBP} className={styles.bomSelProductContainer}>
            <span>{bomProduct.childProduct_name}</span>
             {(showConsumptionsPVVS && bomProduct?.arrayProductParameterizedVariables &&  bomProduct?.arrayProductParameterizedVariables.length > 0) && (
                <span className={styles.bomPVVS}>
                  [
                    {bomProduct?.arrayProductParameterizedVariables.map((objBOMPVV, indexBOMPVV) => (
                       <span key={"bomp_" + indexBP + "_pvv_" + indexBOMPVV}>
                          {objBOMPVV.name}:{objBOMPVV.Value}
                       </span>
                    ))}
                   
                  ]
                </span>
             )}
             {indexBP !== obj?.bomProducts?.length - 1 && (
              <>,</>
             )}
          </div>
        ))}
        </div>

      </div>)
    }
    return (<></>)
  }

  const getAdjustedMachineStatus2 = (obj) => {


    const ODD_COL = "#8b8bc7";
    const EVEN_COL = "#8bb3bf";

    if(sessionState.hasMachineAdjustedStatusEnabled()){
      let adjustedMachinesData = obj?.adjustedMachinesData ? obj?.adjustedMachinesData : [];

      //split machines into categories
      let mapCategories_Machines = {};
      let mapCategories = {};
      for(let i = 0; i<adjustedMachinesData.length; i++){

        let categories = adjustedMachinesData[i]?.categories;
        if(categories && categories.length > 0){
          for(let j = 0; j<categories.length; j++){

            if(!mapCategories_Machines?.[categories[j].Id_MachineCategory]){
              mapCategories_Machines[categories[j].Id_MachineCategory] = {};
            }
            mapCategories_Machines[categories[j].Id_MachineCategory][adjustedMachinesData[i].Id] = adjustedMachinesData[i];
          
            mapCategories[categories[j].Id_MachineCategory] = categories[j];
          }
        }
      }


      let arrayMachineCategoriesWithAdjMachines = [];
      let categoryIds = Object.keys(mapCategories_Machines);
      for(let i = 0; i<categoryIds.length; i++){
        let categoryId = categoryIds[i];
        let machineCategoryData = mapCategories[categoryId];

        let catAdjMachinesIds = Object.keys(mapCategories_Machines[categoryId]);
        let arrayAdjMachines = [];
        for(let j = 0; j<catAdjMachinesIds.length; j++){
          let adjMachineId = catAdjMachinesIds[j];
          let adjMachineData = mapCategories_Machines[categoryId][adjMachineId];
          arrayAdjMachines.push(adjMachineData);
        }

        arrayMachineCategoriesWithAdjMachines.push({
          Id_MachineCategory:categoryId,
          adjustedMachinesArray:arrayAdjMachines
        });
      }

      if(adjustedMachinesData && adjustedMachinesData.length > 0){
        return (
                <div className={styles.machinfoContainer}>
                      <span><b>{t('adjusted.machines')}:</b></span>
                      <div className={styles.machboxesContainer}>
                        <div>
                          <div className={styles.machCategoriesContainer}>
                            {arrayMachineCategoriesWithAdjMachines.map((objCat, index) => (
                              <div className={styles.machCategoryContainer} key={"machCat_" + index}>
                                {objCat.adjustedMachinesArray.map((objMachine,indexMach) => (
                                  <div className={styles.machbox} data-adjusted-machine-code-status={objMachine.code} style={{backgroundColor:index % 2 == 0 ? EVEN_COL : ODD_COL}} key={index + "_adjMachine_"+indexMach}>
                                    <div style={{margin:3,whiteSpace:'nowrap'}}>{objMachine.code}</div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                </div>
              )
      }

    }
    return (<></>)
  }

  const getAdjustedMachineStatus = (obj) => {


    if(sessionState.hasMachineAdjustedStatusEnabled()){
      let adjustedMachinesData = obj?.adjustedMachinesData;
      if(adjustedMachinesData && adjustedMachinesData.length > 0){
        let hasMoreThanTwoMachinesAdjusted = adjustedMachinesData.length > 2;
        let remainingAdjustedMachinesCount = adjustedMachinesData.length - 2;
        return (<div className={styles.infoContainer}>
                <span><b>{t('adjusted.machines')}:</b></span>
                <div>
                  {adjustedMachinesData.slice(0,2).map((objMachine,index) => (
                    <span className={styles.adjMachineContainer} key={"adjMachine_"+index}>
                      {index !== 0 ? " ," : ""}{objMachine.code}
                    </span>
                  ))}
                  {hasMoreThanTwoMachinesAdjusted ? "[+" + remainingAdjustedMachinesCount + "]" : ""}
                </div>

          </div>)
      }

    }
    return (<></>)
  }

  const getProductFixedVariablesObj = (obj) => {
    return "";
    let maxLengthTotal = 80;

    let fixVarsString = "";
    let arrayJsxElems = [];
    if (obj.productFixedVariables !== null) {
      let objProductFixedVariables = JSON.parse(obj.productFixedVariables);

      for (var i = 0; i < objProductFixedVariables.length; i++) {
        if (i !== 0) {
          fixVarsString = fixVarsString + " ";
        }
        if (fixVarsString.length > maxLengthTotal) {
          break;
        }
        var maxSizeOfNameAllowed = maxLengthTotal - fixVarsString.length;

        let breakBeforeNextAppend = false;
        let varLabel = objProductFixedVariables[i].name;
        if (
          objProductFixedVariables[i].name.length + 1 >
          maxSizeOfNameAllowed
        ) {
          //+1 para contar com ":"
          //cut name
          varLabel = varLabel.substr(0, maxSizeOfNameAllowed - 1) + "...";
          breakBeforeNextAppend = true;
        }
        arrayJsxElems.push(
          <div className={styles.fixVarLab} key={i + "_label"}>
            <b>{varLabel}:</b>
          </div>
        );
        fixVarsString = fixVarsString + varLabel + ":";
        if (breakBeforeNextAppend) {
          break;
        }
        var maxSizeOfValueAllowed = maxLengthTotal - fixVarsString.length;
        let varVal = objProductFixedVariables[i].Value;
        if (objProductFixedVariables[i].Value.length > maxSizeOfValueAllowed) {
          //cut Value
          varVal = varVal.substr(0, maxSizeOfValueAllowed - 1) + "...";
          breakBeforeNextAppend = true;
        }
        fixVarsString = fixVarsString + varVal;
        arrayJsxElems.push(
          <div className={styles.fixVarVal} key={i + "_val"}>
            {varVal}
          </div>
        );
        if (breakBeforeNextAppend) {
          break;
        }
      }
    }

    return <div className={styles.fixedVarsContainer}>{arrayJsxElems}</div>;
  };

  const initiateFilterCode = (val) => {
    setSearchbarInput(val);
    clearTimeout(timerStartSearching.current); 
    timerStartSearching.current = setTimeout(
      () => {
        if(val){
          val = val.toLowerCase();
        }
        setSearchFilterValue(val);
      },
      500,
      val
    );
  };

  const getCurrentProducts = () => {
    let curProducts = productionOrder.productionOrderProducts;
    let filteredProducts = [];
    if(searchFilterValue && searchFilterValue !== ""){

      for(let i = 0; i<curProducts.length; i++){
        let searchable = curProducts[i]?.productData?.searchable;
        if(searchable){
          searchable = searchable.toLowerCase();
          if(searchable.includes(searchFilterValue)){
            filteredProducts.push(curProducts[i]);
          }
        }
      }
    }else{
      filteredProducts = curProducts;
    }

    return filteredProducts;
  }

  useEffect(() => {
    if (!didMount) {
      let shouldIncludeDailyDistributionData = sessionState?.userSessionData?.operationsDistribution === "DAILY_DISTRIBUTION";
      
      let shouldIncludeEachProductBom =  sessionState.userSessionData?.globalSettings?.featureExpander?.wsProductSelShowConsumptions === 1;
      let objFilters = {includeDailyDistributionData:shouldIncludeDailyDistributionData,includeOperationsAccessData:true, includePopDataByPopId:"ALL",getWorkerTeamAccessibleProductOperations:true};

      if(shouldIncludeEachProductBom){
        objFilters["includeProductBom"] = shouldIncludeEachProductBom;
      }
      objFilters["modeCleanup"] = "POPS_SELECTION";

      let flag_shopfloor_popSelShowPVVS = sessionState?.userSessionData?.flags?.flag_shopfloor_popSelShowPVVS;
      if(flag_shopfloor_popSelShowPVVS){
        objFilters["includePopPVVS"] = true;
      }

      productionOrdersController()
        .getProductionOrderById(productionOrderId, objFilters, cancelToken)
        .then((resp) => {
          syncSettings.check(resp);
          console.log("finished", resp);
          let poData = resp?.data?.response?.data;
          if (!poData) {
            throw new "invalid podata response"();
          }
          setLoadingPage(false);
          let enabled = poData.enabled;
          if(enabled === 0){
            setShowNotFound(true);
            return;
          }
          let requirePunchclockIn =
            resp?.data?.response?.data?.extra?.requirePunchclockIn;
          if (requirePunchclockIn) {
            punchclock.redirRequirePunchclockIn();
            return;
          }
          dispatchProductionOrderSelectionStore({
            type: "SET_STORE",
            payload: { productionOrder: poData },
          });

          setProductionOrder(poData);
    
          if(poData.open === 0){
            props.history.push("/feature/production-orders");
          }
          
        })
        .catch((resp) => {
          if (isCancel(resp)) {
            return;
          }
          //todo error handling

          if (resp?.response?.status === 404) {
            setShowNotFound(true);
          }

          setLoadingPage(false);
        });

      setDidMount(true);
    }
  }, [
    punchclock,
    didMount,
    productionOrderId,
    productionOrdersDao,
    cancelToken,
    isCancel,
    props.history,
    sessionState?.userSessionData,
    syncSettings
  ]);

  return (
    <InAppTemplate>
      <ProductionTopInfo />
      <CustomArrowBack pushUrl="/feature/production-orders" />
      {loadingPage && <LoadingSpinnerImg />}

      {showNotFound && (
        <div className={styles.pageTitle}>{t("production.order.not.found")}</div>
      )}
      {!isEmptyObject(productionOrder) && (
        <>
          <div className={styles.pageTitle}>{t("product")}</div>

          <div className={styles.poBtns}>
          {SHOW_BTN_CLOSE_PO && (
                <IonButton
                color="primary"
                className={styles.closeWindowButton}
                disabled={!isEnabledCloseOrder()}
                onClick={() => {
                  presentAlert({
                    cssClass: "my-css",
                    header: t('close.order'),
                    message: t("are.you.sure.close.order"),
                    buttons: [
                      t("cancel"),
                      {
                        text: t("yes"),
                        handler: (d) => {
                          let currentWorkerCode = mylocalStorage.getWorkerIdentificationCode();
                          productionOrdersController().closeProductionOrder({workerCode:currentWorkerCode,Id_ProductionOrder:productionOrderId}).then((res) => {
                            
                            if(res?.status !== 200){
                              throw "unexpected result";
                            }
                            feedbackService.notifyToast(t('closed.successfully'),"success");
                            props.history.push({pathname:"/page-redirector",state:{"redirectTo":"/feature/production-orders"}}); 
                              
                          }).catch((res) => {
                          
                            feedbackService.notifyToast(t('failed.error.generic'),"error");
                          });
                        },
                      },
                    ],
                    onDidDismiss: (e) => {},
                  });
                }}
              >
                <IonIcon slot="icon-only" size="small" icon={lockClosedOutline} />
                {t('close.order')}
            </IonButton>
          )}

          </div>

          <div>
          <div>

            <div className={styles.topSearchbar}>

                <div className={styles.customSearchBarContainer}>
                    <CustomSearchBar
                    value={searchbarInput}
                    onResetTextClick={() => {
                      initiateFilterCode("");
                    }}
                    onChange={(evt) => {
                      initiateFilterCode(evt.target.value);
                    }}
                    iconElement={
                      <div className={styles.searchIconStyles}>
                        <IonIcon icon={searchCircleOutline} />
                      </div>
                    }
                    placeholder={t('search.product')}
                  />
              
              </div>

            </div>


            </div>
          </div>
          <div className={styles.productionOrdersContainer}>
            <div className={styles.productionOrdersSelection}>
              {getCurrentProducts().map((obj, index) => (
                <IonCardHeader
                  id={"popBtnIndex_" + index}
                  data-pop-product-code={obj.code}
                  key={index}
                  className={styles.elemElement}
                  onClick={() => {
                    props.history.push({
                      pathname:
                        "/feature/production-order/" +
                        productionOrderId +
                        "/po-product/" +
                        obj.Id,
                      state: {
                        productionOrder_product: obj,
                        productionOrder: productionOrder,
                      },
                    });
                  }}
                >
                  <div className={styles.elemBackgroundContainer}>
                    <HighlightAltIcon />
                  </div>
                  <div className={styles.repositionDoneProduct}>
                    
                    <TaskProgressBar showMinimumFill={true} minimumFillPct={10} fillerColor={"#258d4f4d"} tasksDone={getProducedProductCompleteness(obj).producedQuantity} totalTasks={getProducedProductCompleteness(obj).quantityRequired} width={'100%'} progressBarContainerWidth={'125%'} height={20} fontSize={17} fillerRadius={'0px'}/>
                  </div>
                  <div className={styles.productDataContent}>
                      <div style={{height:38,marginTop:5}} className={`${styles.elemElementTitle} ${styles.operationDescriptionContainer}`}>
                        {getProductNameByObj(obj)}
                      </div>
                      {getProductParameterizedVariablesObj(obj)}
                      {getProductFixedVariablesObj(obj)}
                      {getConsumptionsUI(obj)}
                      {MACHINE_STATUS_STYLE === 1 && (
                        <>{getAdjustedMachineStatus(obj)}</>
                      )}
                      {MACHINE_STATUS_STYLE === 2 && (
                        <>{getAdjustedMachineStatus2(obj)}</>
                      )}

                  </div>
            



                  <div className={styles.repositionDoneWork}>
                    <TaskProgressBar showMinimumFill={true} minimumFillPct={10} tasksDone={getProductOperationsDoneCompleteness(obj).totalTimesDoneIgnoredOverflow} totalTasks={getProductOperationsDoneCompleteness(obj).totalTimesMustBeDone} width={'100%'} progressBarContainerWidth={'125%'} height={20} fontSize={17} fillerRadius={'0px'}/>
                  </div>
                </IonCardHeader>
              ))}
            </div>
          </div>
        </>
      )}
    </InAppTemplate>
  );
}

export default ProductionProductSelectionStandard;
