import { IonButton, IonIcon } from "@ionic/react";
import {
  FormControl,
  InputLabel, Select,
  TextField
} from "@mui/material";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import expectedProductsController from "../../../../barrel/controllers/expectedProductsController";
import useFeedbackService from "../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import LoadingSpinnerImg from "../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import HxfSelectorField from "../../HxfSelectorField/HxfSelectorField";
import SimpleContentPopup from "../../ProductionUI/SimpleContentPopup/SimpleContentPopup";
import DefineProductParameterizedVariableValuesConfirmationButtonsPopup from "./confirmationButtons/DefineProductParameterizedVariableValuesConfirmationButtonsPopup";
import styles from "./DefineProductParameterizedVariableValuesPopup.module.scss";

interface IPopup {
  onClosePopup: any;
  onSuccessConfirm: any;
  title: string;
  currentData: any;
  includeFullParameterizedVariablesData?:boolean;
  label?:any;
  customLabelElement?:any;
  customProductInfoBgColor?:any;
  isConfirmDisabled?:any;
  isLoading?:any;
  isFromUnavailability?:boolean;
  allowIncompleteFilledList?:boolean;
  allowCurrentDataValuesLoad?:boolean;
}
function DefineProductParameterizedVariableValuesPopup(props: IPopup) {
  const [didMount, setDidMount] = useState(false);
  const feedbackService = useFeedbackService();
  const [pvErrorsMap, setPvErrorsMap] = useState<any>({});
  const [valuesMap, setValuesMap] = useState<any>({});

  const [productVariants, setProductVariants] = useState<any>([]);
  const [unavActionAdjMachToProductListVariantsShowStock, setUnavActionAdjMachToProductListVariantsShowStock] = useState(false);


  const {t} = useHxfTranslation();
  useEffect(() => {
    if (!didMount) {
      if (props?.currentData) {
        //any data to load?
      }
    
      let idProduct = props.currentData?.Id_Product;

      if(props?.isFromUnavailability){

        let willNeedToFetchVariants = false;
        let willAlsoFetchVariantStocks = false;
        let arrayProductParameterizedVariables = props?.currentData?.arrayProductParameterizedVariables;
   
        for(let i = 0; i<arrayProductParameterizedVariables.length; i++){
          if(arrayProductParameterizedVariables[i]?.unavActionAdjMachToProductListVariants && parseInt(arrayProductParameterizedVariables[i]?.unavActionAdjMachToProductListVariants) === 1
            ){
              willNeedToFetchVariants = true;
             
            }
            if(arrayProductParameterizedVariables[i]?.unavActionAdjMachToProductListVariantsShowStock && parseInt(arrayProductParameterizedVariables[i]?.unavActionAdjMachToProductListVariantsShowStock) === 1
            ){
              willAlsoFetchVariantStocks = true;
             
            }
            
        }

        if(willNeedToFetchVariants){
          expectedProductsController().fetch({Id_Product:idProduct,includeNotExpectedVariants:willAlsoFetchVariantStocks}).then((res:any) => {
            let expectedVariants = res?.data?.expectedVariants;
           
            if(!expectedVariants){
              throw "unexpected error";
            }
            if(willAlsoFetchVariantStocks){
              setUnavActionAdjMachToProductListVariantsShowStock(true);
            }
            setProductVariants(expectedVariants);
          }).catch((res:any) => {
            feedbackService.notifyToast(t('generic.critical.error'),"error");
            props.onClosePopup();
          });
        }

      }

      if(props?.allowCurrentDataValuesLoad){
        let curData = props?.currentData;
        let arrayProductParameterizedVariables = curData?.arrayProductParameterizedVariables;
        for(let i = 0; i<arrayProductParameterizedVariables.length; i++){
          if(arrayProductParameterizedVariables[i]?.Value !== null){
            setFieldData(getProperFieldNameKey(arrayProductParameterizedVariables[i].Id_Product_ParameterizedVariable), {
              name:arrayProductParameterizedVariables[i].name,
              code:arrayProductParameterizedVariables[i].code,
              valueObj:arrayProductParameterizedVariables[i]?.Value
            });
          }
        }
        

      }

      //if product has at least one variable with unavActionAdjMachToProductListVariants, then fetch all variants and store
      //---

      setDidMount(true);
    }
  }, [didMount, props.currentData]);

  const setFieldValue = (fieldName: string, value: any) => {
    let currentState = valuesMap;

    currentState = { ...currentState };
    currentState[fieldName] = {};
    currentState[fieldName]["valueObj"] = value;

    setValuesMap(currentState);
  };

  const setFieldData = (fieldName: string, objData:any) => {
    let currentState = valuesMap;
    currentState = { ...currentState };
    currentState[fieldName] = {};
    currentState[fieldName] = {...objData};

    setValuesMap(currentState);
  };
  const getHxfSelFieldValue = (fieldName: any) => {
    return getFieldValue(fieldName, true);
  };
  const getFieldValue = (fieldName: string, allowNull = false) => {
    let currentState = valuesMap;
    if (!currentState) {
      if (allowNull) {
        return null;
      }
      return "";
    }
    let objectFields = Object.keys(currentState);

    if (!objectFields.includes(fieldName)) {
      if (allowNull) {
        console.log("RETURNING NULL");
        return null;
      }
      console.log("returning nothing");
      return "";
    }

    let returnObj = currentState[fieldName]["valueObj"];
    if (returnObj === undefined) {
      if (allowNull) {
        console.log("RETURNING NULL 2");
        return null;
      }
      console.log("returning nothing 2 ");
      return "";
    }

    console.log("RETURNING ", returnObj);
    return returnObj;
  };

  const getProperFieldNameKey = (idProductParameterizedVariable:any) => {
    return getParameterVariableStatePrefix() + "" + idProductParameterizedVariable;
  }

  const hasErrorCheck = (fieldName: any) => {
    let currentState = pvErrorsMap;
    if (!currentState) {
      return false;
    }

    if (currentState[fieldName]) {
      if (currentState[fieldName] === true) {
        return true;
      }
    }

    return false;
  };

  const getParameterVariableStatePrefix = () => {
    return "PPV_Id_";
  };

  const getProperNumericValue = (numericValue:any) => {
    // Parse the input number
    const parsedNum = parseFloat(numericValue);
    
    // Check if the parsed number is a valid number
    if (!isNaN(parsedNum)) {
        // Convert number to fixed 3 decimal places
        const fixedNum = parsedNum.toFixed(3);
        
        // Remove trailing zeroes
        const trimmedNum = parseFloat(fixedNum);
        
        return trimmedNum;
    } else {
        // If input is not a valid number, return null or handle error as needed
        return null;
    }
  }

  const getProductPVVariantsOptSelect = (objPv:any) => {

    let asdasd = props.currentData;
    console.log("CURDAT: ", asdasd);
    let opts = [];

    //stock calc
    //we only want the product variants that match the current product, EXCEPT this ppv
    for(let i = 0; i<productVariants.length; i++){
      let arrayppvs = productVariants[i]?.arrayProductParameterizedVariables;

      let matchedProductVariantExceptThisPv = true;


      if(unavActionAdjMachToProductListVariantsShowStock){
        //get the stock of the variants that perfectly matches the current product except this variable
        for(let k = 0; k<arrayppvs.length; k++){
          let curVariantIdPPV = arrayppvs[k].Id_Product_ParameterizedVariable;
          let curVariantValue = arrayppvs[k].Value;
  
          if(curVariantIdPPV !== objPv.Id_Product_ParameterizedVariable){
              let found = false;
              let curDefPvs = props.currentData.arrayProductParameterizedVariables;
  
              for(let j = 0; j<curDefPvs.length; j++){
                let idPPV = curDefPvs[j].Id_Product_ParameterizedVariable;
                let valueDefined = getFieldValue(getProperFieldNameKey(idPPV));
                
                if(valueDefined === curVariantValue){
                  found = true;
                }
        
              }
  
              if(!found){
                matchedProductVariantExceptThisPv = false;
                break;
              }
          }
        }
      }


      if(matchedProductVariantExceptThisPv){
        if(arrayppvs){
          for(let j = 0; j<arrayppvs.length;j++){
            if(arrayppvs[j].Id_Product_ParameterizedVariable === objPv.Id_Product_ParameterizedVariable){
              let valStore = arrayppvs[j].Value;
              if(valStore === ""){
                continue;
              }
              const DONOT_SHOW_IF_NOT_INSTOCK = true;
              
              let canAdd = true;
              let settingLabel = arrayppvs[j].Value;
              if(unavActionAdjMachToProductListVariantsShowStock){
                let inStockQty = 0;
                if(productVariants[i]?._inStock){
                  inStockQty = productVariants[i]?._inStock;
                  settingLabel = settingLabel + " " + "(Stock: " + inStockQty+")";
                }else{
                  settingLabel = settingLabel + " (" + t('no.stock')+")";
                }
                if(inStockQty <= 0){
                  if (DONOT_SHOW_IF_NOT_INSTOCK){
                    canAdd = false;
                  }
                }
              }
              if(canAdd){
                opts.push({label:settingLabel, value:valStore,isUnexpectedVariant:productVariants[i]?.isUnexpectedVariant ? productVariants[i]?.isUnexpectedVariant : false });
              }
              
            }
          }
        }
      }

    }

    for(let i = 0;i<productVariants.length; i++){

      let arrayppvs = productVariants[i]?.arrayProductParameterizedVariables;


     
    }

  
    // Sorting the array alphabetically in descending order based on the "Value" key
    opts.sort((a:any, b:any) => {
      // Convert values to uppercase for case-insensitive sorting

      let aValue = a.value;
      let bValue = b.value;
      if(objPv.varType === "numeric"){
        aValue = !isNaN(aValue) ? getProperNumericValue(aValue) : aValue;
        bValue = !isNaN(bValue) ? getProperNumericValue(bValue) : bValue;
      }
      const valueA = aValue;
      const valueB = bValue;

      if (valueA < valueB) {
          return 1; // Return 1 to indicate "a" comes after "b"
      } else if (valueA > valueB) {
          return -1; // Return -1 to indicate "a" comes before "b"
      } else {
          return 0; // Return 0 if the values are equal
      }
    });

    return opts;
  }

  const getProperValueUnavAdjVariants = (obj:any) => {

    let val = getFieldValue(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable));
    if(val === ""){
      return null;
    }
    return val;
  }

  
  const getVariableInputField = (arrayPPVVs:any, obj: any, index: number) => {

   
    let unavActionAdjMachToProductListVariants = obj?.unavActionAdjMachToProductListVariants && parseInt(obj?.unavActionAdjMachToProductListVariants) === 1;
    let parameterizedVariableObj = obj;
   

    if(props?.isFromUnavailability && unavActionAdjMachToProductListVariants){
      return (
        <HxfSelectorField
         customNoOptionsLabel={t('insert.new.variant')}
          allowFreeTypePersist={true}

          error={hasErrorCheck(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable))}
          uniqueId={"hxfsel_" + index}
          disabled={false}
          labelPlaceholder={obj.name}
          onChange={(evt:any, val:any) => {
            
            clearFieldError(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable));
            let ppvData = arrayPPVVs[index];
        
            let pvName = ppvData.name;
            let pvCode = ppvData.code;
            let valueToApply = val?.value ? val.value : null;

            let isFreeTypenValue = typeof val === 'string';
            if(isFreeTypenValue){
              valueToApply = val;
            }
            /*setFieldValue(
              getParameterVariableStatePrefix() + obj.Id,
              evt.target.value
            );*/
            setFieldData(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable), {
              valueObj:valueToApply,
              name:pvName,
              code:pvCode
            });
           /* console.log("ONCHG: ", val);
            if (!val) {
              val = null;
            }
            //setChecklistPvValue(indexChecklist, indexPv, val, objPv.varType);

            let newChecklists:any = [...checklistFields];
            newChecklists[indexPv].Value = val?.value;

            setChecklistFields(newChecklists);
            //clear error
            let newCurMap = { ...mapErrors };
            if (
              newCurMap[indexPv]
            ) {
              newCurMap[indexPv] =
                false;
              setMapErrors(newCurMap);
            }*/

            
          }}

          value={getProperValueUnavAdjVariants(obj)}
          disableDefaultItemsCaching={true}
          defaultItems={getProductPVVariantsOptSelect(parameterizedVariableObj)}
          customRenderOption={(propsx, option) => {

            return (<li {...propsx} className={` ${propsx.className} ${!option?.isUnexpectedVariant ? styles.expectedVariantOption : ""}`}>{option.label}</li>)
            
          }}
          // value={getChecklistPvValue(indexPv, objPv.varType)}
 
        />
      )
    }

    if (parameterizedVariableObj.varType === "optionselect") {
      let optionsSelectableStr =
        parameterizedVariableObj.varTypeOptionSelect_Opts;
      let optionsSelectableArr = optionsSelectableStr.split(",");

      let defaultOptionsObj = [];
      for (let i = 0; i < optionsSelectableArr.length; i++) {
        defaultOptionsObj.push({
          label: optionsSelectableArr[i],
          value: optionsSelectableArr[i],
        });
      }
      let inputLabel = "";
      inputLabel = parameterizedVariableObj.name; //"(" + parameterizedVariableObj.code + ") " +

      return (
        <FormControl variant={"outlined"} fullWidth>
          <InputLabel>{inputLabel}</InputLabel>
          <Select
            native
            error={hasErrorCheck(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable))}
            value={getFieldValue(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable))}
            label={inputLabel}
            onChange={(evt) => {
              clearFieldError(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable));
              let ppvData = arrayPPVVs[index];
          
              let pvName = ppvData.name;
              let pvCode = ppvData.code;
              /*setFieldValue(
                getParameterVariableStatePrefix() + obj.Id,
                evt.target.value
              );*/
              setFieldData(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable), {
                valueObj:evt.target.value,
                name:pvName,
                code:pvCode
              });
              
            }}
          >
            <option key={"ppv_x"} value={""}></option>
            {defaultOptionsObj.map((obj, index) => (
              <option key={"ppv_" + index} value={obj.value}>
                {obj.label}
              </option>
            ))}
          </Select>
        </FormControl>
      );

      /*
            return (<HxfSelectorField  disabled={false}  disableInternalFiltering={true} labelPlaceholder={inputLabel} uniqueId={"productPV_sel_" + index} error={hasErrorCheck(getParameterVariableStatePrefix() + obj.Id)} onChange={(evt,val) => {
                clearFieldError(getParameterVariableStatePrefix() + obj.Id);
                setFieldValue(getParameterVariableStatePrefix() + obj.Id,val );
            }} value={getHxfSelFieldValue(getParameterVariableStatePrefix() + obj.Id)} defaultItems={defaultOptionsObj}/>)
            */
    }

    if (parameterizedVariableObj.varType === "text") {
      let inputLabel = "";
      inputLabel =
        "(" +
        parameterizedVariableObj.code +
        ") " +
        parameterizedVariableObj.name;
      return (
        <TextField
          disabled={false}
          error={hasErrorCheck(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable))}
          onChange={(evt) => {
            clearFieldError(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable));
            /*setFieldValue(
              getParameterVariableStatePrefix() + obj.Id,
              evt.target.value
            );*/


            let ppvData = arrayPPVVs[index];
         
            let pvName = ppvData.name;
            let pvCode = ppvData.code;
            setFieldData(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable), {
              valueObj:evt.target.value,
              name:pvName,
              code:pvCode
            });
          }}
          value={getFieldValue(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable))}
          fullWidth={true}
          label={inputLabel}
          variant="outlined"
        />
      );
    }

    if (parameterizedVariableObj.varType === "numeric") {
      let inputLabel = "";
      inputLabel =
        "(" +
        parameterizedVariableObj.code +
        ") " +
        parameterizedVariableObj.name;
      return (
        <TextField
          data-input-pv-index={index}
          disabled={false}
          error={hasErrorCheck(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable))}
          onChange={(evt) => {
            clearFieldError(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable));
            /*setFieldValue(
              getParameterVariableStatePrefix() + obj.Id,
              evt.target.value
            );*/

            let ppvData = arrayPPVVs[index];
      
            let pvName = ppvData.name;
            let pvCode = ppvData.code;
            setFieldData(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable), {
              valueObj:evt.target.value,
              name:pvName,
              code:pvCode
            });
          }}
          type={"number"}
          value={getFieldValue(getProperFieldNameKey(obj.Id_Product_ParameterizedVariable))}
          fullWidth={true}
          label={inputLabel}
          variant="outlined"
        />
      );
    }
  };

  const clearFieldError = (fieldName: any) => {
    let newErrorsMap = { ...pvErrorsMap };
    if (newErrorsMap[fieldName]) {
      delete newErrorsMap[fieldName];
      setPvErrorsMap(newErrorsMap);
    }
  };

  const getPPvsElements = () => {

    let mergedProductParameterizedVariablesData = props.currentData.arrayProductParameterizedVariables;
  
    return (
      <div className={styles.pvsSection}>
        {mergedProductParameterizedVariablesData.map(
          (obj: any, index: number) => (
            <div className={styles.pvContainer} key={"productPV_" + index}>
              {getVariableInputField(mergedProductParameterizedVariablesData,obj, index)}
            </div>
          )
        )}
      </div>
    );
  };

  return (
    <div className={styles.intPopup}>

      <SimpleContentPopup>
        <div>
          <div className={styles.container}>
            <div
              className={`popup-barcontainer-border-styles ${styles.barContainer}`}
            >
              <div></div>
              <div className={styles.title}>{props.title}</div>
              <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                  if (props?.onClosePopup) {
                    props.onClosePopup();
                  }
                }}
              >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
              </IonButton>
            </div>
            <div className={styles.selectedProductInfo} style={props?.customProductInfoBgColor ? {backgroundColor: props.customProductInfoBgColor} : {}}>
              <div className={styles.selcetedProductInfoLabels}>
                ({props.currentData.code}) {props.currentData.name}
              </div>
            </div>
            <div
              className={`popup-content-border-sides-only-styles ${styles.content}`}
            >
              <div className={styles.label}>
                {props?.label ? props.label : (<>{t('define.product.variables')}</>)}
              </div>

              {props?.customLabelElement ? props.customLabelElement : (<></>)}

              {props?.isLoading ? (
                 <div style={{display:'flex',justifyContent:'center',marginTop:30}}> <LoadingSpinnerImg/></div>
                ) : (<>
                    <div className={styles.productVariablesSection}>
                      <div className={styles.productVariables}>
                        {getPPvsElements()}
                      </div>
                    </div>
                </>)}

            </div>
          </div>
          <DefineProductParameterizedVariableValuesConfirmationButtonsPopup
            disabled={props?.isConfirmDisabled || props?.isLoading}
            onClickConfirm={() => {

              let REQUIRE_ALL_IF_AT_LEAST_ONE_FILLED = true;
              let requiresAllFilled = !props?.allowIncompleteFilledList;
              
              let filledAll = true;
    
              let productParameterizedVariables =
                props.currentData?.arrayProductParameterizedVariables;

 
              let mapPPVfullInfo:any = {};
              if (productParameterizedVariables) {
  
                //Validate if fields are empty
                let newErrorsMap = { ...pvErrorsMap };
                let hasErrors = false;
              

                for (let i = 0; i < productParameterizedVariables.length; i++) {
                  let idPPV = productParameterizedVariables[i].Id_Product_ParameterizedVariable;
                  mapPPVfullInfo[idPPV] = productParameterizedVariables[i];
                  if ( !valuesMap["PPV_Id_" + idPPV] || !valuesMap["PPV_Id_" + idPPV].valueObj) {
                    filledAll = false;
                    newErrorsMap["PPV_Id_" + idPPV] = true;
                    if(requiresAllFilled){
                      
                      hasErrors = true;
                    }

                  }else{

                    if(REQUIRE_ALL_IF_AT_LEAST_ONE_FILLED){
                      requiresAllFilled = true;
                      if(!filledAll){
                        hasErrors = true;
                      }
                    }

                    //validate type
                    if(productParameterizedVariables[i].varType === "numeric"){
                      let theVal = valuesMap["PPV_Id_" + idPPV]?.valueObj;
                      if(isNaN(theVal)){
                        newErrorsMap["PPV_Id_" + idPPV] = true;
                        hasErrors = true;
                      }
                    }
                  }
                }

                if (hasErrors) {
                  setPvErrorsMap(newErrorsMap);
                  return;
                }
              }

              //if no values map set error all fields
              let prepareReturnObject = [];
              let valuesMapKeys = Object.keys(valuesMap);

              for (let i = 0; i < valuesMapKeys.length; i++) {
                let idppv = valuesMapKeys[i].replace("PPV_Id_", "");

                let value = "";
                if (valuesMap[valuesMapKeys[i]].valueObj?.value) {
                  value = valuesMap[valuesMapKeys[i]].valueObj.value;
                } else {
                  value = valuesMap[valuesMapKeys[i]].valueObj;
                }


                let objPrepared:any = {
                  Id_Product_ParameterizedVariable: idppv,
                  Value: value,
                  label: value,
                  name:valuesMap[valuesMapKeys[i]]?.name,
                  code:valuesMap[valuesMapKeys[i]]?.code
                };
                if(props?.includeFullParameterizedVariablesData){
               
                  let fullInfo:any = mapPPVfullInfo?.[idppv];
                  if(fullInfo){
                    objPrepared = {
                      ...objPrepared,
                      varType:fullInfo?.varType,
                      varTypeOptionSelect_Opts:fullInfo?.varTypeOptionSelect_Opts
                    };
                  }
                  
                }
                prepareReturnObject.push(objPrepared);
              }

              let asdasd = props;
              let receivedPPVVS = props?.currentData?.arrayProductParameterizedVariables;
              if(receivedPPVVS){
                //order according to the received ppvvs
                let returningPPVVS = prepareReturnObject;
                for(let i = 0; i<returningPPVVS.length; i++){
                  returningPPVVS[i].Id_Product_ParameterizedVariable = parseInt(returningPPVVS[i].Id_Product_ParameterizedVariable);
                }
                for(let i = 0; i<receivedPPVVS.length; i++){
                  receivedPPVVS[i].Id_Product_ParameterizedVariable = parseInt(receivedPPVVS[i].Id_Product_ParameterizedVariable);
                }

                let orderMap = new Map();
                receivedPPVVS.forEach((item:any, index:any) => {
                  orderMap.set(item.Id_Product_ParameterizedVariable, index);
                });

                
                // Sort array2 based on the order of Id_X from array1
                returningPPVVS.sort((a:any, b:any) => {
                  let orderA = orderMap.has(a.Id_Product_ParameterizedVariable) ? orderMap.get(a.Id_Product_ParameterizedVariable) : Infinity;
                  let orderB = orderMap.has(b.Id_Product_ParameterizedVariable) ? orderMap.get(b.Id_Product_ParameterizedVariable) : Infinity;
                  
                  // If both are present in array1, sort based on their order in array1
                  if (orderA !== orderB) {
                    return orderA - orderB;
                  }
                  
                  // If neither is in array1, maintain their relative order
                  return 0;
                });
              }
              debugger;
              props.onSuccessConfirm(prepareReturnObject, {filledAll:filledAll});
            }}
          />
        </div>
      </SimpleContentPopup>
    </div>
  );
}

export default DefineProductParameterizedVariableValuesPopup;
