import { IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useEffect, useState } from "react";
import entityUserController from "../../barrel/controllers/entityUserController";
import useLocalStorageHandler from "../../barrel/hooks/useLocalStorageHandler";
import usePunchclock from "../../barrel/hooks/usePunchclock";
import { useGlobalState } from "../../GlobalCustomStateManagement/GlobalStateProvider";
import { dispatchCustomPopupsStore } from "../CustomElements/ProductionUI/CustomPopups/CustomPopupsStore";
import InAppTemplate from "../InAppTemplate/InAppTemplate";
import InterestsIcon from '@mui/icons-material/Interests';
import SellIcon from '@mui/icons-material/Sell';
import SingleMultiWorkerModeToggler from "./BtnFeature/SingleMultiWorkerModeToggler/SingleMultiWorkerModeToggler";
import styles from "./Home.module.scss";
import PunchClockIcon from '@mui/icons-material/PunchClock';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import HistoryIcon from '@mui/icons-material/History';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import useHxfTranslation from "../../barrel/hooks/useHxfTranslation";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import PinDropIcon from '@mui/icons-material/PinDrop';
import WifiFindIcon from '@mui/icons-material/WifiFind';
import CRUDSalesOrderPopup from "../FeaturePages/ShippingsAndReceivings/Popups/CRUDSalesOrderPopup/CRUDSalesOrderPopup";

const Home = (props: any) => {
  const { state, setState, stuff, setStuff, dothis, sessionState } =
    useGlobalState();
  const [didMount, setDidMount] = useState(false);
  let myLocalStorage = useLocalStorageHandler();
  const {t} = useHxfTranslation();

  //const [showNewSalesOrder, setShowNewSalesOrder] = useState(false);
  
  const punchclock = usePunchclock();
  useEffect(() => {
    if (!didMount) {
      console.log("user sess data", sessionState.userSessionData);
      setState({ ...state, firstname: "toy" });

      setStuff({ ...state, firstname: "tino" });
      dothis();

      if (myLocalStorage.getSMWMode() === "SMW_MODE_MULTI") {
        sessionState.deauthWorker();
      }

      setDidMount(true);
    }
  }, [
    sessionState,
    didMount,
    state,
    setState,
    dothis,
    setStuff,
    myLocalStorage,
    sessionState.userSessionData,
  ]);

  const featureElement = (element: any) => {
    return (
      <div style={{ margin: "10px", width: "250px", height: "120px" }}>
        {element}
      </div>
    );
  };

  const loadFeature = (featureAlias: string, color:string) => {
    if (featureAlias === "featureBtnShopfloor_singleMultiWorkerToggler") {
      return <>{featureElement(<SingleMultiWorkerModeToggler />)}</>;
    }

    if (featureAlias === "featureBtnShopfloor_Punchclock") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                punchclock.redirPunchclockMode();
              }}
            >
              <div className={styles.featureBackgroundContainer}>
                <PunchClockIcon />
              </div>
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                  <PunchClockIcon/>
                </div>
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
                {t("punchclock")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                  {t("punchclock.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }

    if (featureAlias === "featureBtnShopfloor_productionOrders") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              id="startProductionBtn"
              data-home-btn="startProductionBtn"
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/production-orders");
              }}
            >

              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                  <PrecisionManufacturingIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <PrecisionManufacturingIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
                {t("start.production")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("productionorders")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }

    if (featureAlias === "featureBtnShopfloor_receivings") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/receivings");
              }}
              data-home-btn="receivings"
            >
              <div className={'hxfFeatureCornerIconBtn'}>
              <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                  <WarehouseIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <WarehouseIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
                {t("receivings")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("receivings.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }

    /*if(featureAlias === "featureBtnShopfloor_newSalesOrder"){
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                //props.history.push("/feature/sales-order/new");
                setShowNewSalesOrder(true);
              }}
              data-home-btn="sales-order-creation"
            >
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                  <SellIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <SellIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
              {t("new.sales.order")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("new.sales.order.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }*/

    if (featureAlias === "featureBtnShopfloor_shippings") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/shippings");
              }}
              data-home-btn="shippings"
            >
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                  <LocalShippingIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <LocalShippingIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
              {t("shippings.sending")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("shippings.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }

    if (featureAlias === "featureBtnShopfloor_moveStock") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              data-home-btn="movestock"
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/move-stock");
              }}
            >
              <div className={'hxfFeatureCornerIconBtn'}>
              <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                <Rotate90DegreesCwIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <Rotate90DegreesCwIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
              {t("movestock")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("movestock.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }

    if (featureAlias === "featureBtnShopfloor_locateStock") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/locate-stock");
              }}
            >
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                <PinDropIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <PinDropIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
              {t("locate.stock")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("locate.stock.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }


    if (featureAlias === "featureBtnShopfloor_materialStaging") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/material-staging");
              }}
            >
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                <VerticalSplitIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <VerticalSplitIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
                {t("materialstaging")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("materialstaging.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }

    if (featureAlias === "featureBtnShopfloor_machines") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              data-home-btn="machines"
              onClick={() => {
                props.history.push("/feature/machines");
              }}
            >
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                <QuickreplyIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <QuickreplyIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
              {t("machines")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("machines.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }

    if (featureAlias === "featureBtnShopfloor_workerDowntime") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/worker-downtime");
              }}
            >
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                  <QuickreplyIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <QuickreplyIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
              {t("worker.downtime")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("worker.downtime.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }

    if (featureAlias === "featureBtnShopfloor_stockInOut") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/stock-in-out");
              }}
            >
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                <RvHookupIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <RvHookupIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
              {t("stockinout")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("stockinout.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }

    if (featureAlias === "featureBtnShopfloor_productionHistory") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/production-history");
              }}
            >
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                <HistoryIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <HistoryIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
                {t("productionhistory")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("productionhistory.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }


    if (featureAlias === "featureBtnShopfloor_genericScanner") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/generic-scanner");
              }}
            >
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                <WifiFindIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <WifiFindIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
                {t("genericscanner")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("genericscanner.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }

    if (featureAlias === "featureBtnShopfloor_stockCounting") {
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                props.history.push("/feature/inventory-evaluation/stock-counting");
              }}
            >
              <div className={'hxfFeatureCornerIconBtn'}>
                <div className={`hxfFeatureCornerIconContainer ${styles.featureBaseIcon}`}>
                <InterestsIcon />
                </div>
                
              </div>
              <div className={styles.featureBackgroundContainer}>
                <InterestsIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
                {t("stockcounting")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("stockcounting.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }
    if (featureAlias === "featureBtnShopfloor_setupZebraPrinters") {
      return (<></>)
      return (
        <>
          {featureElement(
            <IonCardHeader
              className={styles.featureElement}
              style={{backgroundColor:color}}
              onClick={() => {
                // props.history.push("/feature/stock-in-out");

                dispatchCustomPopupsStore({
                  type: "SHOW_ZEBRA_PRINTERS_POPUP",
                });
              }}
            >
              <div className={styles.featureBackgroundContainer}>
                <LocalShippingIcon />
              </div>
              <IonCardTitle className={styles.featureElementTitle}>
              {t("zebraprinters")}
              </IonCardTitle>
              <IonCardSubtitle>
                <div className={styles.featureElementDescription}>
                {t("zebraprinters.desc")}
                </div>
              </IonCardSubtitle>
            </IonCardHeader>
          )}
        </>
      );
    }
    return <>unexpected feature</>;
  };

  return (
    <InAppTemplate>
      {/*showNewSalesOrder && (
        <CRUDSalesOrderPopup Id="new" onClose={() => {
          setShowNewSalesOrder(false);
        }}/>
      )*/}
      <div style={{width:'100%',display:'flex',justifyContent:"center"}}>
        <div className={styles.featuresSelection}>
          {sessionState.userSessionData.shopfloorBtnFeatures.map(
            (row: any, index: number) => (
              <div key={index}>{loadFeature(row.value, row.color)}</div>
            )
          )}
        </div>
      </div>
    </InAppTemplate>
  );
};

export default Home;
