import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";

function workerPrefsController() {


  const togglePinProductionOrder = (objData:any = {}) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + 'worker-prefs/toggle-pin-production-order',
      objData
    );
  }

  const fetchProductionOrderPrefs = (objData:any =  {}) => {
    return BarrelWrapper().appPostRequest(
        HxfAppServersService().getBackend() + 'worker-prefs/fetch-production-order-prefs',
        objData
      );
  }

  return { togglePinProductionOrder, fetchProductionOrderPrefs };
}

export default workerPrefsController;
