import { dispatchActionScreenAskProducedProductIrregularityStore } from "../../pages/CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskProducedProductIrregularity/ActionScreenAskProducedProductIrregularityStore";
import { dispatchProductionOrderSelectionStore } from "../../pages/FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
function ProductionService() {
  const isProductionOrderSelectionRoute = (route) => {
    if (route === "/feature/production-orders") {
      return true;
    }

    return false;
  };
  const isProductionOrderSetupRoute = (route) => {
    if (
      route === "/feature/production-order/:poId/po-product/:popId" ||
      route === "/feature/production-order/:poId"
    ) {
      return true;
    }

    return false;
  };

  const isProductionRoute = (route) => {
    if (
      route ===
      "/feature/production-order/:poId/po-product/:popId/production/:productionId"
    ) {
      return true;
    }

    return false;
  };

  const resetCurrentOperationProductionState = () => {
    dispatchProductionOrderSelectionStore({ type: "RESET_CURRENT_OPERATION" });
    dispatchProductionOrderSelectionStore({ type: "RESET_CURRENT_PRODUCTION" });
    dispatchProductionOrderSelectionStore({ type: "RESET_SET_PRODUCTION_POSTING_DATA_OBJECT" });
  };

  const loadOperationAskPreviousProductionSettings = (
    operationObj,
    isFirstOperation = false
  ) => {
    let obj = operationObj;
    let allowNonePreviousProductionRecord =
      obj?.shopfloorSetting_allowAssociateWithNonePreviousProductionRecord ===
      1;
    let autoSelectLatestOperation =
      obj?.shopfloorSetting_autoSelectLatestOperation;
    let showButtonAutoCalculateLatestOperation =
      obj?.shopfloorSetting_showButtonAutoCalculateLatestOperation;

    let autoCalculatePreviousProductionRecord =
      obj?.shopfloorSetting_autoCalculatePreviousProductionRecord;

    if (obj?.executionNumberOfTimes > 1 && isFirstOperation) {
      allowNonePreviousProductionRecord = true; //if its the first operation, it has no previous so need to allow None
    }

    return {
      allowNonePreviousProductionRecord: allowNonePreviousProductionRecord,
      autoSelectLatestOperation: autoSelectLatestOperation,
      showButtonAutoCalculateLatestOperation:
        showButtonAutoCalculateLatestOperation,
      autoCalculatePreviousProductionRecord:
        autoCalculatePreviousProductionRecord,
    };
  };

  return {
    loadOperationAskPreviousProductionSettings,
    resetCurrentOperationProductionState,
    isProductionOrderSelectionRoute,
    isProductionRoute,
    isProductionOrderSetupRoute,
  };
}

export default ProductionService;
