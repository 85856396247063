import { useCustomPopupsStore } from "./CustomPopupsStore";
import { withRouter } from "react-router";
import OnGoingProductionsPopupDeprecated from "./OnGoingProductionsPopup/OnGoingProductionsPopupDeprecated";
import AssociatePreviousProductionRecordPopup from "./AssociatePreviousProductionRecordPopup/AssociatePreviousProductionRecordPopup";
import MultiPopProductionsStarterPopup from "./MultiPopProductionsStarterPopup/MultiPopProductionsStarterPopup";
import OnGoingMultiProductionsPopup from "./OnGoingMultiProductionsPopup/OnGoingMultiProductionsPopup";
import ZebraPrintersPopup from "./ZebraPrintersPopup/ZebraPrintersPopup";
import GenericOverlayPopup from "./GenericOverlayPopup/GenericOverlayPopup";
import CustomIntegrationsPopup from "./CustomIntegrationsPopup/CustomIntegrationsPopup";
import CollabCancelPopup from "./CollabCancelPopup/CollabCancelPopup";
import { useProductionOrderSelectionStore } from "../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import useProductionProcess from "../../../../barrel/hooks/useProductionProcess";
import { dispatchCustomPopupsStore } from "./CustomPopupsStore";
function CustomSystemPopups(props: any) {
   const productionOrderSelectionStore = useProductionOrderSelectionStore();
   const productionProcess = useProductionProcess();
   const popupsStore = useCustomPopupsStore();
  return (
    <>
      <OnGoingMultiProductionsPopup />
      <AssociatePreviousProductionRecordPopup />
      <MultiPopProductionsStarterPopup />
      <ZebraPrintersPopup />
      <GenericOverlayPopup/>
      <CustomIntegrationsPopup/>
      {popupsStore.collabCancelPopup && (
          <CollabCancelPopup collabAllowCancelSelfPr={productionOrderSelectionStore?.currentOperation?.collabAllowCancelSelfPr === 1} collabAllowCancelFullPr={productionOrderSelectionStore?.currentOperation?.collabAllowCancelFullPr} onCancelFull={() => {
            dispatchCustomPopupsStore({
              type: "CLOSE_COLLAB_CANCEL_POPUP",
            });
            productionProcess.cancelRecord({collabCancelParentRecord:true});
        }} onCancelSelf={() => {
            dispatchCustomPopupsStore({
              type: "CLOSE_COLLAB_CANCEL_POPUP",
            });
            productionProcess.cancelRecord();
        }} onClose={() => {
          dispatchCustomPopupsStore({
            type: "CLOSE_COLLAB_CANCEL_POPUP",
          });
        }}/>
      )}

      
    </>
  );
}

export default withRouter(CustomSystemPopups);
