import { IonButton } from "@ionic/react";
import { useEffect, useState } from "react";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import HxfIonPopup from "../../../HxfIonPopup/HxfIonPopup";
import styles from "./ActionScreenProduceTransformationFromProductStock.module.scss";
import useActionPreConfirm from "../../../../../../barrel/hooks/useActionPreConfirm";
import DefineProductPopup from "../../../../statePopups/defineProductPopup/DefineProductPopup";
import DefineProductQuantityPopup from "../../../../statePopups/defineProductQuantityPopup/DefineProductQuantityPopup";
import DefineProductRemainsPopup from "../../../../statePopups/defineProductRemainsPopup/DefineProductRemainsPopup";
interface IProps{
    callbackOnClose?:any;
    callbackEnableConfirm?:any;
    callbackSuccessConfirm?:any;

}
function ActionScreenProduceTransformationFromProductStock(props:IProps){
    const {t} = useHxfTranslation();

    const [didMount, setDidMount] = useState(false);

    const [transformingFromProduct, setTransformingFromProduct] = useState<any>({});
    const [currentStep, setCurrentStep] = useState<"SELECT_A_PRODUCT" | "SET_QUANTITY" | "SET_ASK_REMAINS">("SELECT_A_PRODUCT");

    const preConfirm = useActionPreConfirm();



    const preparedBaseProduct = () => {

        return {
            Id_Product:transformingFromProduct.Id_Product,
            name:transformingFromProduct.name,
            code:transformingFromProduct.code,
            arrayProductParameterizedVariables:transformingFromProduct?.arrayProductParameterizedVariables ? JSON.parse(JSON.stringify(transformingFromProduct?.arrayProductParameterizedVariables)) : []
        };

    }

    useEffect(() => {
        if(!didMount){

        }
    },[didMount]);



    if(currentStep === "SELECT_A_PRODUCT"){
        return (
            <DefineProductPopup title={t('product.transformation')} onClose={() => {
                props.callbackOnClose();
            }} customContent1={(<div className={styles.selectInitialProduct}>{t('select.the.initial.product')}</div>)} onConfirm={(result:any) => {
                
                let transformingFromProductObj = result;
                let SKIP_QTY_SET = true;
                if(SKIP_QTY_SET){
                  
                    transformingFromProductObj.Quantity = 1;
                    setTransformingFromProduct(JSON.parse(JSON.stringify(transformingFromProductObj)));
                    debugger;
                    setCurrentStep("SET_ASK_REMAINS");
                }else{
                    setTransformingFromProduct(JSON.parse(JSON.stringify(transformingFromProductObj)));
                    setCurrentStep("SET_QUANTITY");
                }
                
               
            }}/>
        )
    }

    if(currentStep === "SET_QUANTITY"){
        return (
            <DefineProductQuantityPopup
            deprecatedModePopup={true}
                onClosePopup={() => {
                    props.callbackOnClose();
                }}
                onSuccessConfirm={(res:any) => {
                    debugger;
                    setTransformingFromProduct({...transformingFromProduct, Quantity: res?.quantityResult ? res?.quantityResult: 0});
                    setCurrentStep("SET_ASK_REMAINS");
                }}
                title={t('quantity')}
                productData={transformingFromProduct}
            />
        )
    }

    if(currentStep === "SET_ASK_REMAINS"){
        return (
            <DefineProductRemainsPopup baseProduct={preparedBaseProduct()} onClose={() => {
                props.callbackOnClose();
            }} onConfirm={(result:any) => {
                let remains = result.remains;

                props.callbackSuccessConfirm({transformations:[
                    {
                        remains:remains,
                        transformingFromProduct:transformingFromProduct
                    }
                ]});
            }}/>
        )
    }



    return (
    
        <>
        <HxfIonPopup title={t('product.transformation')} content={(
            <div>
               this action is not available yet
            </div>
        )} onClose={() => {
            props.callbackOnClose();
        }} modelSettings={{contentHeightPct:80,customPopupMinHeight:'80vh'}} bottomConfirmationContainer={(
            <div style={{marginTop:5,marginBottom:5}}>

                <div>
                <IonButton
                        disabled={false}
                        className={styles.btnConfirm}
                        data-btn={"select-a-product-confirm-btn"}
                        size="large"
                        onClick={() => {
                            if(!preConfirm.check()){
                                return;
                            }
                            let resultObj= {};
                            props.callbackSuccessConfirm(resultObj);
                        }}
                        >
                        
                        {t("confirm")}
                        </IonButton>

                </div> 
            </div>
        )}/>
        </>
    )
}

export default ActionScreenProduceTransformationFromProductStock;