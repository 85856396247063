import axios from "axios";
import HxfAppServersService from "../services/hxfAppServersService";
import BarrelWrapper from "./barrelWrapper";
import GenericListJsonService from "../services/genericListJsonService";
function workerController() {
  const getByCode = (code:any) => {
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + "worker/fetch-by-code",
      {
        code: code,
      }
    );
  };

  const startDowntime = (options = {},Id_Unavailability:any, cancelToken:any) => {
    let requestUrl =
    "worker/initiate-downtime";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {Id_Unavailability:Id_Unavailability,...options},
      cancelToken
    );
  }

  const getWorkersAssignedToProductionOrder = (options = {}, Id_ProductionOrder:any) => {
    let requestUrl = "worker/get-assigned-to-po";
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      {Id_ProductionOrder:Id_ProductionOrder,...options}
    );
  }

  const getListJson = (
    options: any = { page: 0, size: 10, openOnly: true }, postOptions = {}
  ) => {
    let requestUrl = "worker/listJson/?";
    requestUrl = GenericListJsonService().getUrlFromOptions(requestUrl, {...options});
    return BarrelWrapper().appPostRequest(
      HxfAppServersService().getBackend() + requestUrl,
      postOptions
    );
  };
  return { getByCode,startDowntime,getListJson, getWorkersAssignedToProductionOrder };
}

export default workerController;
