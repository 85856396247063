import { IonButton, IonIcon } from "@ionic/react";
import HxfIonPopup from "../../../../CustomElements/ProductionUI/HxfIonPopup/HxfIonPopup";
import styles from "./ProductionOrderSelectDataPopup.module.scss";
import PushPinIcon from '@mui/icons-material/PushPin';
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import workerPrefsController from "../../../../../barrel/controllers/workerPrefsController";
import useLocalStorageHandler from "../../../../../barrel/hooks/useLocalStorageHandler";
import useFeedbackService from "../../../../../barrel/hooks/useFeedbackService";
import { useEffect, useRef, useState } from "react";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HxfOverlayLoading from "../../../../CustomElements/HxfOverlayLoading/HxfOverlayLoading";
import ShippingOrdersShow from "../../../ShippingsAndReceivings/Shippings/SubViews/ShippingsOrders/ShippingOrdersShow";
interface IProductionOrder{
    Id?:any;
    name?:any;
}
interface IProps{
    productionOrderObject:IProductionOrder;
    onClose:any;
}
const ProductionOrderSelectDataPopup = (props:IProps) => {
    const {t} = useHxfTranslation();
    const myLocalStorage = useLocalStorageHandler();
    const feedbackService = useFeedbackService();
    const [isLoading, setIsLoading] = useState(false);
    const [isComponentLoading, setIsComponentLoading] = useState(true);
    const [didMount, setDidMount] = useState(false);
    const [requirePosReload, setRequirePosReload] = useState(false);
    const [loadedPrefs, setLoadedPrefs] = useState<any>(null);
    const [showShippingsPopup,setShowShippingsPopup] = useState<any>(null);

    const firstLoaded = useRef<any>(false);

    const isPoPinned = () => {
        return loadedPrefs?.pinnedPrefs !== null && loadedPrefs.pinnedPrefs !== undefined;
    }

    const getOnClosedData = () => {
        return {requirePosReload:requirePosReload};
    }
    const loadComponentData = (opts?:any) => {
        let workerCode = myLocalStorage.getWorkerIdentificationCode();
        if(!firstLoaded?.current){
            setIsComponentLoading(true);
        }else{
            setIsLoading(true);
        }
        
        workerPrefsController().fetchProductionOrderPrefs({includeProductionOrderFields:['Id',"Id_SalesOrder"],Id_ProductionOrder: props.productionOrderObject.Id, workerCode:workerCode}).then((res) => {
            if(!firstLoaded?.current){
                setIsComponentLoading(false);
            }else{
                setIsLoading(false);
            }
            if(!res?.data?.done){
                throw "unexpected";
            }
            
            setLoadedPrefs(res?.data?.result);
            firstLoaded.current = true;
        }).catch((res) => {
            props.onClose(getOnClosedData());
            if(!firstLoaded?.current){
                setIsComponentLoading(false);
            }else{
                setIsLoading(false);
            }
        });
    }
    useEffect(() => {
        if(!didMount){
            loadComponentData();
            setDidMount(true);
        }
    },[didMount]);


    if(isComponentLoading){
        return (
            <HxfOverlayLoading/>
        )
    }
    return (<>
    {isLoading && (
        <HxfOverlayLoading/>
    )}
        <HxfIonPopup title={"(" + props.productionOrderObject.name + ") " + props.productionOrderObject.name} onClose={() => {
            props.onClose(getOnClosedData());
        }} content={(
            <div className={styles.content}>
                {showShippingsPopup && (
                    <>
                        <HxfIonPopup title={t('shippings')} onClose={() => {
                            setShowShippingsPopup(null);
                        }} modeIonPageContainer={true} content={(
                            <div>
                             
                                <ShippingOrdersShow loadingFromPopup={true} disableClearFilters={true} applyFilters={
                                    [
                                        {
                                            filterKey:'Id_SalesOrder',
                                            Id_SalesOrder:showShippingsPopup?.Id_SalesOrder,
                                            orderCode:showShippingsPopup?.code
                                        },
                                        {
                                            filterKey:'availableOnly',
                                            value:false
                                        }
                                    ]
                                    } />
                            </div>
                        )} />
                    </>
                )}
                {loadedPrefs && (
                    <div className={styles.poTools}>
                        <IonButton
                            color={isPoPinned() ? "primary": "warning"}
                            className={styles.toolBtn}
                            onClick={() => {
                                let workerCode = myLocalStorage.getWorkerIdentificationCode();
                                setIsLoading(true);
                                workerPrefsController().togglePinProductionOrder({Id_ProductionOrder: props.productionOrderObject.Id, workerCode:workerCode}).then((res?:any) => {
                                    setIsLoading(false);
                                    if(!res?.data?.done){
                                    throw "unexpected";
                                    }
                                    setRequirePosReload(true);
                                    feedbackService.notifyToast(t('pinned.successfully'), "success");
                                    loadComponentData();
                                }).catch((res:any) => {
                                    setRequirePosReload(false);
                                    setIsLoading(false);
                                    feedbackService.notifyToast(t('error.unexpected'),"error");
                                });
                            }}
                        >
                            <PushPinIcon/>

                            {isPoPinned() ? t('make.unpinned') : t('make.pinned')}
                        </IonButton>
                    </div>
                )}

                {loadedPrefs?.productionOrderData?.Id_SalesOrder && (
                    <div className={styles.poTools}>
                        <IonButton
                            color={"primary"}
                            className={styles.toolBtn}
                            onClick={() => {
                                setShowShippingsPopup({Id_SalesOrder:loadedPrefs?.productionOrderData?.Id_SalesOrder,name:loadedPrefs?.productionOrderData?.salesOrder_name,code:loadedPrefs?.productionOrderData?.salesOrder_code});
                            }}
                        >
                            <span style={{marginRight:5}}><LocalShippingIcon/></span>

                        {t('associated.shippings')}
                        </IonButton>
                    </div>
                )}

            </div>
        )}/></>
    )
}

export default ProductionOrderSelectDataPopup;