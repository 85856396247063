import { useEffect, useState } from "react";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import HxfOverlayLoading from "../../../../HxfOverlayLoading/HxfOverlayLoading";
import PrintLabelPopup from "../../../../statePopups/printLabelPopup/printLabelPopup";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import useActionScreenTopBar from "../Components/hooks/useActionScreenTopBar";
import useHookProductionOperationHandler from "../../useHookProductionOperationHandler";


interface IProps {
    callbackOnClose?:any;
    callbackEnableConfirm?:any;
    callbackSuccessConfirm?:any;
}
function ActionScreenAskPrintLabelsV2(props: IProps) {
    const {t} = useHxfTranslation();
    const actTopBar = useActionScreenTopBar();
    const currentActionScreenManager = useActionScreenManagerStore();
    const productionOrderSelectionStore= useProductionOrderSelectionStore();
    const feedbackService = useFeedbackService();
    const productionOperationHandler = useHookProductionOperationHandler({
        successEndOperationCallback:function(){
            feedbackService.notifyToast(t('operation.finished'),"success");
        },
        failedEndOperationCallback:() => {
            feedbackService.notifyToast(t('error.unexpected'),"error");
        }
      });
    const [isLoading, setIsLoading] = useState(false);
    const [didMount, setDidMount] = useState(false);

    const [showPrintLabelPopup,setShowPrintLabelPopup] = useState<any>(null);
    // states to allow clearing when loading a default stock location config

    const closeCallback = () => {
        props.callbackOnClose(
        );
    };

    useEffect(() => {
        if (!didMount) {
            //mounting

            let endRecordOnReach = currentActionScreenManager?.currentActionConfig?.endRecordOnReach === 1;

            let currentOperationActions = productionOrderSelectionStore?.currentOperation?.actions;

            let labelsList = [...currentActionScreenManager.currentActionConfig?.labelsList];

            try{

                let mapLabelsDiscard:any = {}; //if certain parameters are not met we dont want the labels to show
                for(let i = 0; i<labelsList.length; i++){
                    let prLotTagFillFromPPQ = labelsList[i]?.prLotTagFillFromPPQ;
                    let prProductTagBeeingProduced = labelsList[i].prProductTagBeeingProduced;

                    let additionalParameters:any = {};
                    if(prLotTagFillFromPPQ=== 1){
                        let idLotSelected = null;
                        
                        
                        if(currentOperationActions){
                            for(let j = 0; j<currentOperationActions.length; j++){
                                if(currentOperationActions[j].code === "PRODUCE_PRODUCT_QUANTITY"){
                                    let tempStore = currentOperationActions[j]?._tempStore;
                                    if(tempStore?.Id_Lot){
                                        //getting lot defined in the produce product quantity
                                        idLotSelected = tempStore.Id_Lot;
                                    }
                                }
                            }
                        }
                        
                        if(!idLotSelected){
                          
                            mapLabelsDiscard[labelsList[i].Id] = true;
                            continue; //skip
                            //throw "unexpected.ppq.no.lot.selected";
                        }
                       
                        additionalParameters.Id_Lot = idLotSelected;
                    }

                    if(prProductTagBeeingProduced === 1){
                        let idProduct = productionOrderSelectionStore?.product?.Id;
                        if(!idProduct){
                            throw "unexpected.sel.product.id";
                        }
                        additionalParameters.Id_Product = idProduct;
                    }
    
                    labelsList[i]._additionalParameters = additionalParameters;
                }


                let cleanLabelList = [];
                for(let i = 0; i<labelsList.length; i++){
                   if(!mapLabelsDiscard?.[labelsList[i].Id]){
                    cleanLabelList.push(labelsList[i]);
                   }
                }

                labelsList = cleanLabelList;
                let printPopupData = {printableLabels:labelsList,genericLabelData:{test:123}};
                setShowPrintLabelPopup(printPopupData);
            } catch(error) {
                console.error(error);
                feedbackService.notifyToast(t('error.unexpected'),"error");
            }

            if(endRecordOnReach){
                productionOperationHandler.actionEndOperation({
                    nextActionShouldExitRecord:true
                });
            }
            
            setDidMount(true);
        }
    }, [
        didMount,
        currentActionScreenManager,
        feedbackService,
        t
    ]);

    if (isLoading) {
        return <>
            <HxfOverlayLoading/></>;
    }

    return (
        <>
            <PrintLabelPopup
                title={t('label.printing')}
                showSkipButton={true}
                onClosePopup={() => {
                    
                    if(productionOrderSelectionStore?.nextOperationActionExitRecord){
                        productionOperationHandler.redirectAfterClosingRecord();
                    }else{
                        actTopBar.closeActionWindows();//custom close
                        props.callbackOnClose();
                    }
           
                }}
                onSkip={() => {
                    setShowPrintLabelPopup(null);
                    props.callbackSuccessConfirm();
                }}
                printableLabels={showPrintLabelPopup?.printableLabels ? showPrintLabelPopup?.printableLabels : []}
                genericLabelData={showPrintLabelPopup?.genericLabelData}
            />
        </>
    );
}
export default ActionScreenAskPrintLabelsV2;
