import { useCallback, useEffect, useState } from "react";
import useActionPreConfirm from "../../../../../../barrel/hooks/useActionPreConfirm";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import LoadingSpinnerImg from "../../../../../../components/UIComps/LoadingSpinnerImg/LoadingSpinnerImg";
import { dispatchProductionOrderSelectionStore, useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import HxfIonPopup from "../../../HxfIonPopup/HxfIonPopup";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import styles from "./ActionScreenAskLogisticsLocation.module.scss";
import stockManagementController from "../../../../../../barrel/controllers/stockManagementController";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import DatesConversionService from "../../../../../../barrel/services/datesConversionService";
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import { IonButton, IonIcon } from "@ionic/react";
import { dispatchActionScreenProduceQuantityProductStore } from "../ActionScreenProduceQuantityProduct/ActionScreenProduceQuantityProductStore";
import { dispatchActionScreenAskBomConsumptionsStore } from "../ActionScreenAskBomConsumptions/ActionScreenAskBomConsumptionsStore";
interface IProps{
    callbackOnClose?:any;
    callbackEnableConfirm?:any;
    callbackSuccessConfirm?:any;

}
function ActionScreenAskLogisticsLocation(props:IProps){
    const {t} = useHxfTranslation();
    const preConfirm = useActionPreConfirm();
    const [didMount, setDidMount] = useState(false);
    const currentActionScreenManager = useActionScreenManagerStore();
    const currentProductionOrderSelectionData =  useProductionOrderSelectionStore();
    const [screenSettings, setScreenSettings] = useState<any>(null);
    const [currentLocation, setCurrentLocation] = useState<any>({Id_WarehouseLocation:null, Id_Warehouse:null, Id_Lot:null});
    const [modeLocation, setModeLocation] = useState<"SELF_PRODUCT_LOTS_EXISTANCE"|"SELF_PRODUCT_STOCK"|"ALL_LOCATION" | null>(null);
    const productionOrderSelectionStore = useProductionOrderSelectionStore();
    const [productStockMapResults, setProductStockMapResults] = useState<any>(null);

    const [exLotsMapResults, setExLotsMapResults] = useState<any>(null);
    const feedbackService = useFeedbackService();

    const [isLoadingComponent, setIsLoadingComponent] = useState(true);
    const finishConfirm = () => {
        if(!preConfirm.check()){
            return;
          }

    }

    const loadCurrentLocation = (loadingInfo?:any) => {
        if(!loadingInfo){
            loadingInfo = {
                Id_WarehouseLocation: currentLocation,
                Id_Warehouse: currentLocation.Id_Warehouse,
                Id_Lot: currentLocation.Id_Lot,
                modeLocation: modeLocation
            };
        }else{
            loadingInfo = {
                Id_WarehouseLocation: loadingInfo.currentLocation,
                Id_Warehouse: loadingInfo.currentLocation.Id_Warehouse,
                Id_Lot: loadingInfo.currentLocation.Id_Lot,
                modeLocation: loadingInfo.modeLocation
            };
        }

        if(loadingInfo.modeLocation === "ALL_LOCATION"){
            //not implemented

        }else if(loadingInfo.modeLocation === "SELF_PRODUCT_LOTS_EXISTANCE"){

            let ppvvs = currentProductionOrderSelectionData?.productionOrderProduct_ParameterizedVariableValues ? currentProductionOrderSelectionData?.productionOrderProduct_ParameterizedVariableValues : [];

            let preparedProductObj = {
                Id_Product:currentProductionOrderSelectionData?.product?.Id,
                stockMovement_productParameterizedVariables:ppvvs,
                exactProductStockLocation:false,
                exactProductParameterizedVariables: true
            };

            stockManagementController().checkProductsLotsExistance({
                lotPrecisionPage:1,
                productFilter:preparedProductObj
              }).then((res:any) => {
                if(isLoadingComponent){
                    setIsLoadingComponent(false);
                }

                 let mapResultWhLoc_Results:any = {};
                 let mapWhLocData:any = {};
                 let mapWhData:any = {};
                 
                 let mapWh_LotData:any = {};
                 let mapLotData:any = {};

                  if(res?.data?.response?.data === undefined){
                    throw "unexpected res";
                  }
                  let existingLotsStockData =res?.data?.response?.data?.[0]?.stockResult ? res?.data?.response?.data?.[0]?.stockResult : [];
               
                  if(existingLotsStockData.length === 0){
                    feedbackService.notifyToast(t('no.existing.lots.available.product'), "error");
                    return;
                  }
                  let cleanLocationQtyForThisProduct = [];
                  //TODO CLear filters
                  for(let i = 0; i<existingLotsStockData.length; i++){
                    if(!existingLotsStockData[i]?.Id_Lot){
                      continue; //skip shopfloor
                    }

                    
                    let lotQuantity = existingLotsStockData[i]?.Quantity ? existingLotsStockData[i]?.Quantity : 0;
                    let idWarehouseLocation = existingLotsStockData[i]?.Id_WarehouseLocation;
                    let idWarehouse = existingLotsStockData[i]?.Id_Warehouse;
                    let idLot = existingLotsStockData[i]?.Id_Lot;
                    if(idWarehouseLocation){
                        if(!mapResultWhLoc_Results?.[idWarehouseLocation]){
                            mapResultWhLoc_Results[idWarehouseLocation] = [];
                        }
                        mapResultWhLoc_Results[idWarehouseLocation].push({
                            Id_Warehouse:existingLotsStockData[i]?.Id_Warehouse
                        });
                    }

                    if(!mapWhLocData?.[idWarehouseLocation]){
                        mapWhLocData[idWarehouseLocation] = {
                            Id:idWarehouseLocation,
                            name:existingLotsStockData[i]?.warehouseLocationName,
                            code:existingLotsStockData[i]?.warehouseLocationCode
                        };
                    }

                    if(!mapWhData?.[idWarehouse]){
                        mapWhData[idWarehouse] = {
                            Id:idWarehouse,
                            name:existingLotsStockData[i]?.warehouseName,
                            code:existingLotsStockData[i]?.warehouseCode
                        };
                    }

                    if(!mapWh_LotData?.[idWarehouse]){
                        mapWh_LotData[idWarehouse] = {};
                    }
                    if(!mapWh_LotData?.[idWarehouse]?.[idLot]){
                        mapWh_LotData[idWarehouse][idLot] = {
                            Id_Lot:idLot,
                            Quantity:lotQuantity
                        };
                    }

                    
                    if(!mapLotData?.[idLot]){
                        mapLotData[idLot] = {
                            Id:idLot,
                            name:existingLotsStockData[i].lotName,
                            code:existingLotsStockData[i].lotCode,
                            expirationDate:existingLotsStockData[i].lotExpirationDate,
                            Quantity:lotQuantity,
                            Id_Warehouse:idWarehouse,
                            Id_WarehouseLocation:idWarehouseLocation
                        };
                    }

                  }

            

                  setExLotsMapResults({
                    mapResultWhLoc_Results:mapResultWhLoc_Results,
                    mapWhLocData:mapWhLocData,
                    mapWhData:mapWhData,
                    mapWh_LotData:mapWh_LotData,
                    mapLotData:mapLotData
                  });
              }).catch((res:any) => {
                feedbackService.notifyToast(t('error.unexpected'), "error");
                props.callbackOnClose();
              });
        
        }else if(loadingInfo.modeLocation === "SELF_PRODUCTS_STOCK"){
            //TODO get stock of product at that location and list

            //if loading fails, show error and close

            let ppvvs = currentProductionOrderSelectionData?.productionOrderProduct_ParameterizedVariableValues ? currentProductionOrderSelectionData?.productionOrderProduct_ParameterizedVariableValues : [];

            let preparedProductObj = {
                Id_Product:currentProductionOrderSelectionData?.product?.Id,
                stockMovement_productParameterizedVariables:ppvvs,
                exactProductStockLocation:false,
                exactProductParameterizedVariables: true
            };

            
            stockManagementController().getProductsStockAtLocation([preparedProductObj],{lotTraceability:false,includeDefaultNoLot:true}).then((res:any) => {
                if(isLoadingComponent){
                    setIsLoadingComponent(false);
                }
                

                if(!res?.data?.response?.data){
                    throw "unexpected stock err";

                }
                let mapResultWhLoc_Results:any = {};
                let mapWhLocData:any = {};
                let mapWhData:any = {};


                let stockResult = res?.data?.response?.data?.[0]?.stockResult;
                for(let i = 0; i<stockResult.length; i++){
                    let Quantity = stockResult[i]?.Quantity ? stockResult[i]?.Quantity : 0;
                    let idWarehouseLocation = stockResult[i]?.Id_WarehouseLocation;
                    let idWarehouse = stockResult[i]?.Id_Warehouse;
                    if(idWarehouseLocation){
                        if(!mapResultWhLoc_Results?.[idWarehouseLocation]){
                            mapResultWhLoc_Results[idWarehouseLocation] = [];
                        }
                        mapResultWhLoc_Results[idWarehouseLocation].push({
                            Id_Warehouse:stockResult[i]?.Id_Warehouse,
                            Quantity:Quantity
                        });
                    }

                    if(!mapWhLocData?.[idWarehouseLocation]){
                        mapWhLocData[idWarehouseLocation] = {
                            Id:idWarehouseLocation,
                            name:stockResult[i]?.warehouseLocationName,
                            code:stockResult[i]?.warehouseLocationCode
                        };
                    }

                    if(!mapWhData?.[idWarehouse]){
                        mapWhData[idWarehouse] = {
                            Id:idWarehouse,
                            name:stockResult[i]?.warehouseName,
                            code:stockResult[i]?.warehouseCode
                        };
                    }
                }

                setProductStockMapResults({
                    mapResultWhLoc_Results:mapResultWhLoc_Results,
                    mapWhLocData:mapWhLocData,
                    mapWhData:mapWhData
                });

            }).catch((res:any) => {
                setIsLoadingComponent(false);
                feedbackService.notifyToast(t('generic.critical.error'), "danger");
                props.callbackOnClose();
                /*setIsLoading(false);
                feedbackService.notifyToast(t('generic.critical.error'), "danger");
                props.onClose();*/
            });
        }


    }
    useEffect(() => {
        if(!didMount){

      
            let allowGoBackFromDefault = false; //if change is required, create a new flag
            let modeSelection:any = "SELF_PRODUCT_LOTS_EXISTANCE";
        
            let currentActionConfig = currentActionScreenManager?.currentActionConfig;
            let def_Id_WarehouseLocation = currentActionConfig.def_Id_WarehouseLocation;
            let def_Id_Warehouse = currentActionConfig.def_Id_Warehouse;
            let def_Id_Lot= currentActionConfig.def_Id_Lot;

            setScreenSettings({
                allowGoBackFromDefault:allowGoBackFromDefault
            });

            let newLocation:any = {
                Id_WarehouseLocation:def_Id_WarehouseLocation ? def_Id_WarehouseLocation : null,
                Id_Warehouse:def_Id_Warehouse ? def_Id_Warehouse : null,
                Id_Lot:def_Id_Lot ? def_Id_Lot : null
            };

            if(def_Id_WarehouseLocation && currentActionConfig?.warehouseLocationData){
                newLocation.warehouseLocationData = currentActionConfig?.warehouseLocationData;
            }
            if(def_Id_Warehouse && currentActionConfig?.warehouseData){
                newLocation.warehouseData = currentActionConfig?.warehouseData;
            }
            setCurrentLocation(newLocation);
            setModeLocation(modeSelection);

            loadCurrentLocation({currentLocation:newLocation,modeLocation:modeSelection});
            
            setDidMount(true);
        }
    },[didMount]);


    const getContentSelfProductStock = () => {

        return; //not implemented
        if(!productStockMapResults){
            return (<></>)
        }
        let mapResultWhLoc_Results = productStockMapResults?.mapResultWhLoc_Results;
        let mapWhLocData = productStockMapResults?.mapWhLocData;
        let depthSearchPosition = "WAREHOUSE_LOCATION";

        let optionsSelectable = [];
        //currently selecting warehouse location
        if(depthSearchPosition === "WAREHOUSE_LOCATION"){
          
            if(mapResultWhLoc_Results){
                let whLocIdKeys = Object.keys(mapResultWhLoc_Results);
                for(let i = 0; i<whLocIdKeys.length; i++){
                    let idWhLoc = whLocIdKeys[i];
                    optionsSelectable.push({
                        Id:idWhLoc,
                        name:mapWhLocData?.[idWhLoc]?.name,
                        code:mapWhLocData?.[idWhLoc]?.code
                    });   
                }
            }
        }

        return (<div>
            {!depthSearchPosition && (
                <div>topbar</div>
            )}
            

            <div>
                {optionsSelectable.map((obj:any, index:any) => (
                    <div key={"logloc_" + index}>
                        {obj.name}
                    </div>
                ))}
            </div>
        </div>)
    }

    const getExLotsFiltered = useCallback((optsFilter?:any) => {
        

            let idWarehouseFilter = optsFilter?.Id_Warehouse;
    
            let filteredLotsResult = [];
            let mapLotsData = exLotsMapResults?.mapLotData ? exLotsMapResults?.mapLotData : [];
            let arrayLotIdKeys = Object.keys(mapLotsData);
            for(let i = 0; i<arrayLotIdKeys.length; i++){
                let lotData = mapLotsData[arrayLotIdKeys[i]];
    
                if(idWarehouseFilter){
                    if(lotData?.Id_Warehouse === idWarehouseFilter){
                        filteredLotsResult.push(lotData);
                    }
                }
    
            }

            //order lots ASC by their expirationDate
            // Sort the array
            filteredLotsResult.sort((a:any, b:any) => {
                if (a.expirationDate === null && b.expirationDate === null) {
                    return 0;
                }
                if (a.expirationDate === null) {
                    return 1; // null should come last
                }
                if (b.expirationDate === null) {
                    return -1; // null should come last
                }
                return new Date(a.expirationDate).getTime() - new Date(b.expirationDate).getTime(); // Sort by date
            });
  
    
            return filteredLotsResult;
        
    },[exLotsMapResults,currentLocation]);


    const isLotExpired = (lotObj:any) => {
        return lotObj?.expirationDate && (new Date(lotObj.expirationDate)).getTime() < (new Date()).getTime();
    }

    const getCurrentLocationToolbar = () => {
        if(!currentLocation?.warehouseLocationData){
            return (<></>)
        }

        return (<div>
            <div className={styles.whLocTool}>({currentLocation?.warehouseLocationData?.code}) {currentLocation?.warehouseLocationData?.name}</div>
            {currentLocation?.warehouseData && (
                  <div className={styles.whTool}>({currentLocation?.warehouseData?.code}) {currentLocation?.warehouseData?.name}</div>
            )}
        </div>)
    }
    const getContentSelfProductLotsExistance = () => {
        
        let lotsVisibilityMode = "FILTERED_BY_CUR_WH"; //if the purpose  is to show all and not using a "location selection filter", then we need to make this flag setting and implement it


        if(lotsVisibilityMode === "FILTERED_BY_CUR_WH"){
            //check if we are already in a warehouse
            let isInWarehouse = currentLocation?.Id_Warehouse ? true : false;
            if(!isInWarehouse){
                return (<>todo, implement whloc and wh selection</>)
            }else{
                //show lots
              
                let lotOptions = getExLotsFiltered({Id_Warehouse: currentLocation.Id_Warehouse});
             
                if(lotOptions.length === 0){
                    return (<div style={{display:'flex',justifyContent:'center'}}>
                        <div style={{marginTop:'30vh',marginLeft:10,marginRight:10}}>
                            <div style={{color:'gray',justifyContent:'center',display:'flex'}}><SearchOffIcon fontSize="large"/></div>
                            <div style={{color:'gray'}}>{t('no.lot.with.this.product.available')}</div>
                        </div>
                    </div>)
                }

                return (
                <div>
                    <div>
                        {getCurrentLocationToolbar()}
                    </div>
                    <div style={{display:'flex',justifyContent:'center',marginTop:10}}>{t('select.a.lot')}</div>
                    <div className={styles.lotsContainer}>
                        {lotOptions.map((lotObj:any, idxLot:any) => (
                            <div className={`${styles.lotElement} ${currentLocation?.Id_Lot === lotObj.Id ? styles.selectedLot : null}`} key={"idx_lot_" + idxLot} onClick={() => {
                                
                                if(currentLocation?.Id_Lot === lotObj.Id){

                                    let newObj = {...currentLocation, Id_Lot:null};
                                    delete newObj.lotData;
                                    setCurrentLocation(newObj);
                                }else{
                                    setCurrentLocation({...currentLocation, Id_Lot:lotObj.Id, lotData:{Id:lotObj.Id,name:lotObj.name, code:lotObj.code, expirationDate:lotObj?.expirationDate}});
                                }
                                
                            }}>
                            <div>{lotObj.code}</div>
                            {lotObj?.expirationDate && (
                                    <div>
                                        <div className={styles.qtyElem}><b>{t('expiration.date')}</b></div>
                                        <div className={`${styles.qtyLabel} ${isLotExpired(lotObj) ? styles.expiredLot : ""}`}>{DatesConversionService().formattedDateSimpleLabelDateOnly(new Date(lotObj.expirationDate))}</div>
                                    </div>
                            )}
                            <div>
                                    <div className={styles.qtyElem}><b>{t('quantity')}</b></div>
                                    <div className={styles.qtyLabel}>{lotObj.Quantity}</div>
                            </div>
                            
                            </div>
                        ))}
                    </div>
                </div>)
            }
        }
        return (<></>);
    }

    const getContent = () => {
        if(isLoadingComponent){
            return (
                <div style={{marginTop:'20vh'}}><LoadingSpinnerImg/></div>
            )
        }

        if(modeLocation === "SELF_PRODUCT_LOTS_EXISTANCE"){
            return ( <>{getContentSelfProductLotsExistance()}</>)
        }

        if(modeLocation === "SELF_PRODUCT_STOCK"){
            return (<>{getContentSelfProductStock()}</>)
        }
        return (
            <div>
                Hello world
            </div>
        )
    }
    return (
    
        <>
        <HxfIonPopup title={t('logistic.location')} content={getContent()} onClose={() => {
            props.callbackOnClose();
        }} modelSettings={{contentHeightPct:80,customPopupMinHeight:'80vh'}} bottomConfirmationContainer={(
            <div style={{marginTop:5,marginBottom:5}}>
                {currentActionScreenManager?.currentActionConfig?.allowSkipping === 1 && (
                <div style={{width:'100%'}}>
                <IonButton
                        disabled={currentLocation?.Id_Lot}
                        color="danger"
                        style={{width:'100%'}}
                    
                        className={styles.btnConfirm}
                        data-btn={"select-a-product-confirm-btn"}
                        size="large"
                        onClick={() => {
 
                            //---
                            //turn into a setting and set it "on" to entities using this action
                            dispatchActionScreenProduceQuantityProductStore({
                                type: "RESET_STORE", //important to clear in case of selecting a logistics location, confirming a location in the action ppq, canceling and skipping (it would have a location cached)
                              });
                              dispatchActionScreenAskBomConsumptionsStore({
                                type: "RESET_STORE"
                              })
                              //totcheck productionOrderSelectionStore
                             
                            //---
                            let resultObj:any = {
                                currentLocation:null
                            };
                            let selStatePop = {...productionOrderSelectionStore.productionOrderProduct};
                            selStatePop.latestActionLogisticsLocationDefined = null;

                            dispatchProductionOrderSelectionStore({
                                type: "SET_PRODUCTION_ORDER_PRODUCT",
                                payload: selStatePop,
                              });

                            props.callbackSuccessConfirm(resultObj);
                        }}
                        >
                        
                        {t("skip")}
                        </IonButton>

                </div>
                )}


                <div>
                <IonButton
                        disabled={!currentLocation?.Id_Lot}
                        className={styles.btnConfirm}
                        data-btn={"select-a-product-confirm-btn"}
                        size="large"
                        onClick={() => {
 
                            let resultObj:any = {
                                currentLocation:currentLocation
                            };
                            if(currentActionScreenManager?.currentActionConfig?.smPickSelfProduct === 1){
                                resultObj.smPickSelfProduct = currentActionScreenManager.currentActionConfig.smPickSelfProduct;
                            }
                            let setFinalLotAccordingToSelection = currentActionScreenManager?.currentActionConfig?.useSelLotAsFinalLot === 1;
                            let setFinalLotExpDateAccordingToSelection =  currentActionScreenManager?.currentActionConfig?.useSelLotExpDateAsFinalLotExpDate === 1;
                            let selStatePop = {...productionOrderSelectionStore.productionOrderProduct};

                            if((setFinalLotAccordingToSelection || setFinalLotExpDateAccordingToSelection) && currentLocation.lotData?.code){
                                
                                if(setFinalLotAccordingToSelection){
                                    selStatePop.prodFinalLotCode = currentLocation.lotData?.code;
                                }
                                if(setFinalLotExpDateAccordingToSelection && currentLocation.lotData?.expirationDate){
                                    selStatePop.prodFinalLotExpDate = currentLocation.lotData?.expirationDate;
                                }
                                
                          
                            }

                            
                            selStatePop.latestActionLogisticsLocationDefined = currentLocation;

                            dispatchProductionOrderSelectionStore({
                                type: "SET_PRODUCTION_ORDER_PRODUCT",
                                payload: selStatePop,
                              });

                            props.callbackSuccessConfirm(resultObj);
                        }}
                        >
                        
                        {t("confirm")}
                        </IonButton>

                </div> 
            </div>
        )}/>
        </>
    )
}

export default ActionScreenAskLogisticsLocation;